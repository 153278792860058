export const COUNTY_AND_COUNTY_EQUIVALENT_ENTITIES = {
  alabama: {
    autaugacounty: {stateFP: "01", countyFP: "001"},
    baldwincounty: {stateFP: "01", countyFP: "003"},
    barbourcounty: {stateFP: "01", countyFP: "005"},
    bibbcounty: {stateFP: "01", countyFP: "007"},
    blountcounty: {stateFP: "01", countyFP: "009"},
    bullockcounty: {stateFP: "01", countyFP: "011"},
    butlercounty: {stateFP: "01", countyFP: "013"},
    calhouncounty: {stateFP: "01", countyFP: "015"},
    chamberscounty: {stateFP: "01", countyFP: "017"},
    cherokeecounty: {stateFP: "01", countyFP: "019"},
    chiltoncounty: {stateFP: "01", countyFP: "021"},
    choctawcounty: {stateFP: "01", countyFP: "023"},
    clarkecounty: {stateFP: "01", countyFP: "025"},
    claycounty: {stateFP: "01", countyFP: "027"},
    cleburnecounty: {stateFP: "01", countyFP: "029"},
    coffeecounty: {stateFP: "01", countyFP: "031"},
    colbertcounty: {stateFP: "01", countyFP: "033"},
    conecuhcounty: {stateFP: "01", countyFP: "035"},
    coosacounty: {stateFP: "01", countyFP: "037"},
    covingtoncounty: {stateFP: "01", countyFP: "039"},
    crenshawcounty: {stateFP: "01", countyFP: "041"},
    cullmancounty: {stateFP: "01", countyFP: "043"},
    dalecounty: {stateFP: "01", countyFP: "045"},
    dallascounty: {stateFP: "01", countyFP: "047"},
    dekalbcounty: {stateFP: "01", countyFP: "049"},
    elmorecounty: {stateFP: "01", countyFP: "051"},
    escambiacounty: {stateFP: "01", countyFP: "053"},
    etowahcounty: {stateFP: "01", countyFP: "055"},
    fayettecounty: {stateFP: "01", countyFP: "057"},
    franklincounty: {stateFP: "01", countyFP: "059"},
    genevacounty: {stateFP: "01", countyFP: "061"},
    greenecounty: {stateFP: "01", countyFP: "063"},
    halecounty: {stateFP: "01", countyFP: "065"},
    henrycounty: {stateFP: "01", countyFP: "067"},
    houstoncounty: {stateFP: "01", countyFP: "069"},
    jacksoncounty: {stateFP: "01", countyFP: "071"},
    jeffersoncounty: {stateFP: "01", countyFP: "073"},
    lamarcounty: {stateFP: "01", countyFP: "075"},
    lauderdalecounty: {stateFP: "01", countyFP: "077"},
    lawrencecounty: {stateFP: "01", countyFP: "079"},
    leecounty: {stateFP: "01", countyFP: "081"},
    limestonecounty: {stateFP: "01", countyFP: "083"},
    lowndescounty: {stateFP: "01", countyFP: "085"},
    maconcounty: {stateFP: "01", countyFP: "087"},
    madisoncounty: {stateFP: "01", countyFP: "089"},
    marengocounty: {stateFP: "01", countyFP: "091"},
    marioncounty: {stateFP: "01", countyFP: "093"},
    marshallcounty: {stateFP: "01", countyFP: "095"},
    mobilecounty: {stateFP: "01", countyFP: "097"},
    monroecounty: {stateFP: "01", countyFP: "099"},
    montgomerycounty: {stateFP: "01", countyFP: "101"},
    morgancounty: {stateFP: "01", countyFP: "103"},
    perrycounty: {stateFP: "01", countyFP: "105"},
    pickenscounty: {stateFP: "01", countyFP: "107"},
    pikecounty: {stateFP: "01", countyFP: "109"},
    randolphcounty: {stateFP: "01", countyFP: "111"},
    russellcounty: {stateFP: "01", countyFP: "113"},
    stclaircounty: {stateFP: "01", countyFP: "115"},
    shelbycounty: {stateFP: "01", countyFP: "117"},
    sumtercounty: {stateFP: "01", countyFP: "119"},
    talladegacounty: {stateFP: "01", countyFP: "121"},
    tallapoosacounty: {stateFP: "01", countyFP: "123"},
    tuscaloosacounty: {stateFP: "01", countyFP: "125"},
    walkercounty: {stateFP: "01", countyFP: "127"},
    washingtoncounty: {stateFP: "01", countyFP: "129"},
    wilcoxcounty: {stateFP: "01", countyFP: "131"},
    winstoncounty: {stateFP: "01", countyFP: "133"},
  },
  alaska: {
    aleutianseastborough: {stateFP: "02", countyFP: "013"},
    aleutianswestcensusarea: {stateFP: "02", countyFP: "016"},
    anchoragemunicipality: {stateFP: "02", countyFP: "020"},
    bethelcensusarea: {stateFP: "02", countyFP: "050"},
    bristolbayborough: {stateFP: "02", countyFP: "060"},
    chugachcensusarea: {stateFP: "02", countyFP: "063"},
    copperrivercensusarea: {stateFP: "02", countyFP: "066"},
    denaliborough: {stateFP: "02", countyFP: "068"},
    dillinghamcensusarea: {stateFP: "02", countyFP: "070"},
    fairbanksnorthstarborough: {stateFP: "02", countyFP: "090"},
    hainesborough: {stateFP: "02", countyFP: "100"},
    hoonahangooncensusarea: {stateFP: "02", countyFP: "105"},
    juneaucityandborough: {stateFP: "02", countyFP: "110"},
    kenaipeninsulaborough: {stateFP: "02", countyFP: "122"},
    ketchikangatewayborough: {stateFP: "02", countyFP: "130"},
    kodiakislandborough: {stateFP: "02", countyFP: "150"},
    kusilvakcensusarea: {stateFP: "02", countyFP: "158"},
    lakeandpeninsulaborough: {stateFP: "02", countyFP: "164"},
    matanuskasusitnaborough: {stateFP: "02", countyFP: "170"},
    nomecensusarea: {stateFP: "02", countyFP: "180"},
    northslopeborough: {stateFP: "02", countyFP: "185"},
    northwestarcticborough: {stateFP: "02", countyFP: "188"},
    petersburgborough: {stateFP: "02", countyFP: "195"},
    princeofwaleshydercensusarea: {stateFP: "02", countyFP: "198"},
    sitkacityandborough: {stateFP: "02", countyFP: "220"},
    skagwaymunicipality: {stateFP: "02", countyFP: "230"},
    southeastfairbankscensusarea: {stateFP: "02", countyFP: "240"},
    wrangellcityandborough: {stateFP: "02", countyFP: "275"},
    yakutatcityandborough: {stateFP: "02", countyFP: "282"},
    yukonkoyukukcensusarea: {stateFP: "02", countyFP: "290"},
  },
  arizona: {
    apachecounty: {stateFP: "04", countyFP: "001"},
    cochisecounty: {stateFP: "04", countyFP: "003"},
    coconinocounty: {stateFP: "04", countyFP: "005"},
    gilacounty: {stateFP: "04", countyFP: "007"},
    grahamcounty: {stateFP: "04", countyFP: "009"},
    greenleecounty: {stateFP: "04", countyFP: "011"},
    lapazcounty: {stateFP: "04", countyFP: "012"},
    maricopacounty: {stateFP: "04", countyFP: "013"},
    mohavecounty: {stateFP: "04", countyFP: "015"},
    navajocounty: {stateFP: "04", countyFP: "017"},
    pimacounty: {stateFP: "04", countyFP: "019"},
    pinalcounty: {stateFP: "04", countyFP: "021"},
    santacruzcounty: {stateFP: "04", countyFP: "023"},
    yavapaicounty: {stateFP: "04", countyFP: "025"},
    yumacounty: {stateFP: "04", countyFP: "027"},
  },
  arkansas: {
    arkansascounty: {stateFP: "05", countyFP: "001"},
    ashleycounty: {stateFP: "05", countyFP: "003"},
    baxtercounty: {stateFP: "05", countyFP: "005"},
    bentoncounty: {stateFP: "05", countyFP: "007"},
    boonecounty: {stateFP: "05", countyFP: "009"},
    bradleycounty: {stateFP: "05", countyFP: "011"},
    calhouncounty: {stateFP: "05", countyFP: "013"},
    carrollcounty: {stateFP: "05", countyFP: "015"},
    chicotcounty: {stateFP: "05", countyFP: "017"},
    clarkcounty: {stateFP: "05", countyFP: "019"},
    claycounty: {stateFP: "05", countyFP: "021"},
    cleburnecounty: {stateFP: "05", countyFP: "023"},
    clevelandcounty: {stateFP: "05", countyFP: "025"},
    columbiacounty: {stateFP: "05", countyFP: "027"},
    conwaycounty: {stateFP: "05", countyFP: "029"},
    craigheadcounty: {stateFP: "05", countyFP: "031"},
    crawfordcounty: {stateFP: "05", countyFP: "033"},
    crittendencounty: {stateFP: "05", countyFP: "035"},
    crosscounty: {stateFP: "05", countyFP: "037"},
    dallascounty: {stateFP: "05", countyFP: "039"},
    deshacounty: {stateFP: "05", countyFP: "041"},
    drewcounty: {stateFP: "05", countyFP: "043"},
    faulknercounty: {stateFP: "05", countyFP: "045"},
    franklincounty: {stateFP: "05", countyFP: "047"},
    fultoncounty: {stateFP: "05", countyFP: "049"},
    garlandcounty: {stateFP: "05", countyFP: "051"},
    grantcounty: {stateFP: "05", countyFP: "053"},
    greenecounty: {stateFP: "05", countyFP: "055"},
    hempsteadcounty: {stateFP: "05", countyFP: "057"},
    hotspringcounty: {stateFP: "05", countyFP: "059"},
    howardcounty: {stateFP: "05", countyFP: "061"},
    independencecounty: {stateFP: "05", countyFP: "063"},
    izardcounty: {stateFP: "05", countyFP: "065"},
    jacksoncounty: {stateFP: "05", countyFP: "067"},
    jeffersoncounty: {stateFP: "05", countyFP: "069"},
    johnsoncounty: {stateFP: "05", countyFP: "071"},
    lafayettecounty: {stateFP: "05", countyFP: "073"},
    lawrencecounty: {stateFP: "05", countyFP: "075"},
    leecounty: {stateFP: "05", countyFP: "077"},
    lincolncounty: {stateFP: "05", countyFP: "079"},
    littlerivercounty: {stateFP: "05", countyFP: "081"},
    logancounty: {stateFP: "05", countyFP: "083"},
    lonokecounty: {stateFP: "05", countyFP: "085"},
    madisoncounty: {stateFP: "05", countyFP: "087"},
    marioncounty: {stateFP: "05", countyFP: "089"},
    millercounty: {stateFP: "05", countyFP: "091"},
    mississippicounty: {stateFP: "05", countyFP: "093"},
    monroecounty: {stateFP: "05", countyFP: "095"},
    montgomerycounty: {stateFP: "05", countyFP: "097"},
    nevadacounty: {stateFP: "05", countyFP: "099"},
    newtoncounty: {stateFP: "05", countyFP: "101"},
    ouachitacounty: {stateFP: "05", countyFP: "103"},
    perrycounty: {stateFP: "05", countyFP: "105"},
    phillipscounty: {stateFP: "05", countyFP: "107"},
    pikecounty: {stateFP: "05", countyFP: "109"},
    poinsettcounty: {stateFP: "05", countyFP: "111"},
    polkcounty: {stateFP: "05", countyFP: "113"},
    popecounty: {stateFP: "05", countyFP: "115"},
    prairiecounty: {stateFP: "05", countyFP: "117"},
    pulaskicounty: {stateFP: "05", countyFP: "119"},
    randolphcounty: {stateFP: "05", countyFP: "121"},
    stfranciscounty: {stateFP: "05", countyFP: "123"},
    salinecounty: {stateFP: "05", countyFP: "125"},
    scottcounty: {stateFP: "05", countyFP: "127"},
    searcycounty: {stateFP: "05", countyFP: "129"},
    sebastiancounty: {stateFP: "05", countyFP: "131"},
    seviercounty: {stateFP: "05", countyFP: "133"},
    sharpcounty: {stateFP: "05", countyFP: "135"},
    stonecounty: {stateFP: "05", countyFP: "137"},
    unioncounty: {stateFP: "05", countyFP: "139"},
    vanburencounty: {stateFP: "05", countyFP: "141"},
    washingtoncounty: {stateFP: "05", countyFP: "143"},
    whitecounty: {stateFP: "05", countyFP: "145"},
    woodruffcounty: {stateFP: "05", countyFP: "147"},
    yellcounty: {stateFP: "05", countyFP: "149"},
  },
  california: {
    alamedacounty: {stateFP: "06", countyFP: "001"},
    alpinecounty: {stateFP: "06", countyFP: "003"},
    amadorcounty: {stateFP: "06", countyFP: "005"},
    buttecounty: {stateFP: "06", countyFP: "007"},
    calaverascounty: {stateFP: "06", countyFP: "009"},
    colusacounty: {stateFP: "06", countyFP: "011"},
    contracostacounty: {stateFP: "06", countyFP: "013"},
    delnortecounty: {stateFP: "06", countyFP: "015"},
    eldoradocounty: {stateFP: "06", countyFP: "017"},
    fresnocounty: {stateFP: "06", countyFP: "019"},
    glenncounty: {stateFP: "06", countyFP: "021"},
    humboldtcounty: {stateFP: "06", countyFP: "023"},
    imperialcounty: {stateFP: "06", countyFP: "025"},
    inyocounty: {stateFP: "06", countyFP: "027"},
    kerncounty: {stateFP: "06", countyFP: "029"},
    kingscounty: {stateFP: "06", countyFP: "031"},
    lakecounty: {stateFP: "06", countyFP: "033"},
    lassencounty: {stateFP: "06", countyFP: "035"},
    losangelescounty: {stateFP: "06", countyFP: "037"},
    maderacounty: {stateFP: "06", countyFP: "039"},
    marincounty: {stateFP: "06", countyFP: "041"},
    mariposacounty: {stateFP: "06", countyFP: "043"},
    mendocinocounty: {stateFP: "06", countyFP: "045"},
    mercedcounty: {stateFP: "06", countyFP: "047"},
    modoccounty: {stateFP: "06", countyFP: "049"},
    monocounty: {stateFP: "06", countyFP: "051"},
    montereycounty: {stateFP: "06", countyFP: "053"},
    napacounty: {stateFP: "06", countyFP: "055"},
    nevadacounty: {stateFP: "06", countyFP: "057"},
    orangecounty: {stateFP: "06", countyFP: "059"},
    placercounty: {stateFP: "06", countyFP: "061"},
    plumascounty: {stateFP: "06", countyFP: "063"},
    riversidecounty: {stateFP: "06", countyFP: "065"},
    sacramentocounty: {stateFP: "06", countyFP: "067"},
    sanbenitocounty: {stateFP: "06", countyFP: "069"},
    sanbernardinocounty: {stateFP: "06", countyFP: "071"},
    sandiegocounty: {stateFP: "06", countyFP: "073"},
    sanfranciscocounty: {stateFP: "06", countyFP: "075"},
    sanjoaquincounty: {stateFP: "06", countyFP: "077"},
    sanluisobispocounty: {stateFP: "06", countyFP: "079"},
    sanmateocounty: {stateFP: "06", countyFP: "081"},
    santabarbaracounty: {stateFP: "06", countyFP: "083"},
    santaclaracounty: {stateFP: "06", countyFP: "085"},
    santacruzcounty: {stateFP: "06", countyFP: "087"},
    shastacounty: {stateFP: "06", countyFP: "089"},
    sierracounty: {stateFP: "06", countyFP: "091"},
    siskiyoucounty: {stateFP: "06", countyFP: "093"},
    solanocounty: {stateFP: "06", countyFP: "095"},
    sonomacounty: {stateFP: "06", countyFP: "097"},
    stanislauscounty: {stateFP: "06", countyFP: "099"},
    suttercounty: {stateFP: "06", countyFP: "101"},
    tehamacounty: {stateFP: "06", countyFP: "103"},
    trinitycounty: {stateFP: "06", countyFP: "105"},
    tularecounty: {stateFP: "06", countyFP: "107"},
    tuolumnecounty: {stateFP: "06", countyFP: "109"},
    venturacounty: {stateFP: "06", countyFP: "111"},
    yolocounty: {stateFP: "06", countyFP: "113"},
    yubacounty: {stateFP: "06", countyFP: "115"},
  },
  colorado: {
    adamscounty: {stateFP: "08", countyFP: "001"},
    alamosacounty: {stateFP: "08", countyFP: "003"},
    arapahoecounty: {stateFP: "08", countyFP: "005"},
    archuletacounty: {stateFP: "08", countyFP: "007"},
    bacacounty: {stateFP: "08", countyFP: "009"},
    bentcounty: {stateFP: "08", countyFP: "011"},
    bouldercounty: {stateFP: "08", countyFP: "013"},
    broomfieldcounty: {stateFP: "08", countyFP: "014"},
    chaffeecounty: {stateFP: "08", countyFP: "015"},
    cheyennecounty: {stateFP: "08", countyFP: "017"},
    clearcreekcounty: {stateFP: "08", countyFP: "019"},
    conejoscounty: {stateFP: "08", countyFP: "021"},
    costillacounty: {stateFP: "08", countyFP: "023"},
    crowleycounty: {stateFP: "08", countyFP: "025"},
    custercounty: {stateFP: "08", countyFP: "027"},
    deltacounty: {stateFP: "08", countyFP: "029"},
    denvercounty: {stateFP: "08", countyFP: "031"},
    dolorescounty: {stateFP: "08", countyFP: "033"},
    douglascounty: {stateFP: "08", countyFP: "035"},
    eaglecounty: {stateFP: "08", countyFP: "037"},
    elbertcounty: {stateFP: "08", countyFP: "039"},
    elpasocounty: {stateFP: "08", countyFP: "041"},
    fremontcounty: {stateFP: "08", countyFP: "043"},
    garfieldcounty: {stateFP: "08", countyFP: "045"},
    gilpincounty: {stateFP: "08", countyFP: "047"},
    grandcounty: {stateFP: "08", countyFP: "049"},
    gunnisoncounty: {stateFP: "08", countyFP: "051"},
    hinsdalecounty: {stateFP: "08", countyFP: "053"},
    huerfanocounty: {stateFP: "08", countyFP: "055"},
    jacksoncounty: {stateFP: "08", countyFP: "057"},
    jeffersoncounty: {stateFP: "08", countyFP: "059"},
    kiowacounty: {stateFP: "08", countyFP: "061"},
    kitcarsoncounty: {stateFP: "08", countyFP: "063"},
    lakecounty: {stateFP: "08", countyFP: "065"},
    laplatacounty: {stateFP: "08", countyFP: "067"},
    larimercounty: {stateFP: "08", countyFP: "069"},
    lasanimascounty: {stateFP: "08", countyFP: "071"},
    lincolncounty: {stateFP: "08", countyFP: "073"},
    logancounty: {stateFP: "08", countyFP: "075"},
    mesacounty: {stateFP: "08", countyFP: "077"},
    mineralcounty: {stateFP: "08", countyFP: "079"},
    moffatcounty: {stateFP: "08", countyFP: "081"},
    montezumacounty: {stateFP: "08", countyFP: "083"},
    montrosecounty: {stateFP: "08", countyFP: "085"},
    morgancounty: {stateFP: "08", countyFP: "087"},
    oterocounty: {stateFP: "08", countyFP: "089"},
    ouraycounty: {stateFP: "08", countyFP: "091"},
    parkcounty: {stateFP: "08", countyFP: "093"},
    phillipscounty: {stateFP: "08", countyFP: "095"},
    pitkincounty: {stateFP: "08", countyFP: "097"},
    prowerscounty: {stateFP: "08", countyFP: "099"},
    pueblocounty: {stateFP: "08", countyFP: "101"},
    rioblancocounty: {stateFP: "08", countyFP: "103"},
    riograndecounty: {stateFP: "08", countyFP: "105"},
    routtcounty: {stateFP: "08", countyFP: "107"},
    saguachecounty: {stateFP: "08", countyFP: "109"},
    sanjuancounty: {stateFP: "08", countyFP: "111"},
    sanmiguelcounty: {stateFP: "08", countyFP: "113"},
    sedgwickcounty: {stateFP: "08", countyFP: "115"},
    summitcounty: {stateFP: "08", countyFP: "117"},
    tellercounty: {stateFP: "08", countyFP: "119"},
    washingtoncounty: {stateFP: "08", countyFP: "121"},
    weldcounty: {stateFP: "08", countyFP: "123"},
    yumacounty: {stateFP: "08", countyFP: "125"},
  },
  connecticut: {
    fairfieldcounty: {stateFP: "09", countyFP: "001"},
    hartfordcounty: {stateFP: "09", countyFP: "003"},
    litchfieldcounty: {stateFP: "09", countyFP: "005"},
    middlesexcounty: {stateFP: "09", countyFP: "007"},
    newhavencounty: {stateFP: "09", countyFP: "009"},
    newlondoncounty: {stateFP: "09", countyFP: "011"},
    tollandcounty: {stateFP: "09", countyFP: "013"},
    windhamcounty: {stateFP: "09", countyFP: "015"},
  },
  delaware: {
    kentcounty: {stateFP: "10", countyFP: "001"},
    newcastlecounty: {stateFP: "10", countyFP: "003"},
    sussexcounty: {stateFP: "10", countyFP: "005"},
  },
  districtofcolumbia: {
    districtofcolumbia: {stateFP: "11", countyFP: "001"},
  },
  florida: {
    alachuacounty: {stateFP: "12", countyFP: "001"},
    bakercounty: {stateFP: "12", countyFP: "003"},
    baycounty: {stateFP: "12", countyFP: "005"},
    bradfordcounty: {stateFP: "12", countyFP: "007"},
    brevardcounty: {stateFP: "12", countyFP: "009"},
    browardcounty: {stateFP: "12", countyFP: "011"},
    calhouncounty: {stateFP: "12", countyFP: "013"},
    charlottecounty: {stateFP: "12", countyFP: "015"},
    citruscounty: {stateFP: "12", countyFP: "017"},
    claycounty: {stateFP: "12", countyFP: "019"},
    colliercounty: {stateFP: "12", countyFP: "021"},
    columbiacounty: {stateFP: "12", countyFP: "023"},
    desotocounty: {stateFP: "12", countyFP: "027"},
    dixiecounty: {stateFP: "12", countyFP: "029"},
    duvalcounty: {stateFP: "12", countyFP: "031"},
    escambiacounty: {stateFP: "12", countyFP: "033"},
    flaglercounty: {stateFP: "12", countyFP: "035"},
    franklincounty: {stateFP: "12", countyFP: "037"},
    gadsdencounty: {stateFP: "12", countyFP: "039"},
    gilchristcounty: {stateFP: "12", countyFP: "041"},
    gladescounty: {stateFP: "12", countyFP: "043"},
    gulfcounty: {stateFP: "12", countyFP: "045"},
    hamiltoncounty: {stateFP: "12", countyFP: "047"},
    hardeecounty: {stateFP: "12", countyFP: "049"},
    hendrycounty: {stateFP: "12", countyFP: "051"},
    hernandocounty: {stateFP: "12", countyFP: "053"},
    highlandscounty: {stateFP: "12", countyFP: "055"},
    hillsboroughcounty: {stateFP: "12", countyFP: "057"},
    holmescounty: {stateFP: "12", countyFP: "059"},
    indianrivercounty: {stateFP: "12", countyFP: "061"},
    jacksoncounty: {stateFP: "12", countyFP: "063"},
    jeffersoncounty: {stateFP: "12", countyFP: "065"},
    lafayettecounty: {stateFP: "12", countyFP: "067"},
    lakecounty: {stateFP: "12", countyFP: "069"},
    leecounty: {stateFP: "12", countyFP: "071"},
    leoncounty: {stateFP: "12", countyFP: "073"},
    levycounty: {stateFP: "12", countyFP: "075"},
    libertycounty: {stateFP: "12", countyFP: "077"},
    madisoncounty: {stateFP: "12", countyFP: "079"},
    manateecounty: {stateFP: "12", countyFP: "081"},
    marioncounty: {stateFP: "12", countyFP: "083"},
    martincounty: {stateFP: "12", countyFP: "085"},
    miamidadecounty: {stateFP: "12", countyFP: "086"},
    monroecounty: {stateFP: "12", countyFP: "087"},
    nassaucounty: {stateFP: "12", countyFP: "089"},
    okaloosacounty: {stateFP: "12", countyFP: "091"},
    okeechobeecounty: {stateFP: "12", countyFP: "093"},
    orangecounty: {stateFP: "12", countyFP: "095"},
    osceolacounty: {stateFP: "12", countyFP: "097"},
    palmbeachcounty: {stateFP: "12", countyFP: "099"},
    pascocounty: {stateFP: "12", countyFP: "101"},
    pinellascounty: {stateFP: "12", countyFP: "103"},
    polkcounty: {stateFP: "12", countyFP: "105"},
    putnamcounty: {stateFP: "12", countyFP: "107"},
    stjohnscounty: {stateFP: "12", countyFP: "109"},
    stluciecounty: {stateFP: "12", countyFP: "111"},
    santarosacounty: {stateFP: "12", countyFP: "113"},
    sarasotacounty: {stateFP: "12", countyFP: "115"},
    seminolecounty: {stateFP: "12", countyFP: "117"},
    sumtercounty: {stateFP: "12", countyFP: "119"},
    suwanneecounty: {stateFP: "12", countyFP: "121"},
    taylorcounty: {stateFP: "12", countyFP: "123"},
    unioncounty: {stateFP: "12", countyFP: "125"},
    volusiacounty: {stateFP: "12", countyFP: "127"},
    wakullacounty: {stateFP: "12", countyFP: "129"},
    waltoncounty: {stateFP: "12", countyFP: "131"},
    washingtoncounty: {stateFP: "12", countyFP: "133"},
  },
  georgia: {
    applingcounty: {stateFP: "13", countyFP: "001"},
    atkinsoncounty: {stateFP: "13", countyFP: "003"},
    baconcounty: {stateFP: "13", countyFP: "005"},
    bakercounty: {stateFP: "13", countyFP: "007"},
    baldwincounty: {stateFP: "13", countyFP: "009"},
    bankscounty: {stateFP: "13", countyFP: "011"},
    barrowcounty: {stateFP: "13", countyFP: "013"},
    bartowcounty: {stateFP: "13", countyFP: "015"},
    benhillcounty: {stateFP: "13", countyFP: "017"},
    berriencounty: {stateFP: "13", countyFP: "019"},
    bibbcounty: {stateFP: "13", countyFP: "021"},
    bleckleycounty: {stateFP: "13", countyFP: "023"},
    brantleycounty: {stateFP: "13", countyFP: "025"},
    brookscounty: {stateFP: "13", countyFP: "027"},
    bryancounty: {stateFP: "13", countyFP: "029"},
    bullochcounty: {stateFP: "13", countyFP: "031"},
    burkecounty: {stateFP: "13", countyFP: "033"},
    buttscounty: {stateFP: "13", countyFP: "035"},
    calhouncounty: {stateFP: "13", countyFP: "037"},
    camdencounty: {stateFP: "13", countyFP: "039"},
    candlercounty: {stateFP: "13", countyFP: "043"},
    carrollcounty: {stateFP: "13", countyFP: "045"},
    catoosacounty: {stateFP: "13", countyFP: "047"},
    charltoncounty: {stateFP: "13", countyFP: "049"},
    chathamcounty: {stateFP: "13", countyFP: "051"},
    chattahoocheecounty: {stateFP: "13", countyFP: "053"},
    chattoogacounty: {stateFP: "13", countyFP: "055"},
    cherokeecounty: {stateFP: "13", countyFP: "057"},
    clarkecounty: {stateFP: "13", countyFP: "059"},
    claycounty: {stateFP: "13", countyFP: "061"},
    claytoncounty: {stateFP: "13", countyFP: "063"},
    clinchcounty: {stateFP: "13", countyFP: "065"},
    cobbcounty: {stateFP: "13", countyFP: "067"},
    coffeecounty: {stateFP: "13", countyFP: "069"},
    colquittcounty: {stateFP: "13", countyFP: "071"},
    columbiacounty: {stateFP: "13", countyFP: "073"},
    cookcounty: {stateFP: "13", countyFP: "075"},
    cowetacounty: {stateFP: "13", countyFP: "077"},
    crawfordcounty: {stateFP: "13", countyFP: "079"},
    crispcounty: {stateFP: "13", countyFP: "081"},
    dadecounty: {stateFP: "13", countyFP: "083"},
    dawsoncounty: {stateFP: "13", countyFP: "085"},
    decaturcounty: {stateFP: "13", countyFP: "087"},
    dekalbcounty: {stateFP: "13", countyFP: "089"},
    dodgecounty: {stateFP: "13", countyFP: "091"},
    doolycounty: {stateFP: "13", countyFP: "093"},
    doughertycounty: {stateFP: "13", countyFP: "095"},
    douglascounty: {stateFP: "13", countyFP: "097"},
    earlycounty: {stateFP: "13", countyFP: "099"},
    echolscounty: {stateFP: "13", countyFP: "101"},
    effinghamcounty: {stateFP: "13", countyFP: "103"},
    elbertcounty: {stateFP: "13", countyFP: "105"},
    emanuelcounty: {stateFP: "13", countyFP: "107"},
    evanscounty: {stateFP: "13", countyFP: "109"},
    fannincounty: {stateFP: "13", countyFP: "111"},
    fayettecounty: {stateFP: "13", countyFP: "113"},
    floydcounty: {stateFP: "13", countyFP: "115"},
    forsythcounty: {stateFP: "13", countyFP: "117"},
    franklincounty: {stateFP: "13", countyFP: "119"},
    fultoncounty: {stateFP: "13", countyFP: "121"},
    gilmercounty: {stateFP: "13", countyFP: "123"},
    glascockcounty: {stateFP: "13", countyFP: "125"},
    glynncounty: {stateFP: "13", countyFP: "127"},
    gordoncounty: {stateFP: "13", countyFP: "129"},
    gradycounty: {stateFP: "13", countyFP: "131"},
    greenecounty: {stateFP: "13", countyFP: "133"},
    gwinnettcounty: {stateFP: "13", countyFP: "135"},
    habershamcounty: {stateFP: "13", countyFP: "137"},
    hallcounty: {stateFP: "13", countyFP: "139"},
    hancockcounty: {stateFP: "13", countyFP: "141"},
    haralsoncounty: {stateFP: "13", countyFP: "143"},
    harriscounty: {stateFP: "13", countyFP: "145"},
    hartcounty: {stateFP: "13", countyFP: "147"},
    heardcounty: {stateFP: "13", countyFP: "149"},
    henrycounty: {stateFP: "13", countyFP: "151"},
    houstoncounty: {stateFP: "13", countyFP: "153"},
    irwincounty: {stateFP: "13", countyFP: "155"},
    jacksoncounty: {stateFP: "13", countyFP: "157"},
    jaspercounty: {stateFP: "13", countyFP: "159"},
    jeffdaviscounty: {stateFP: "13", countyFP: "161"},
    jeffersoncounty: {stateFP: "13", countyFP: "163"},
    jenkinscounty: {stateFP: "13", countyFP: "165"},
    johnsoncounty: {stateFP: "13", countyFP: "167"},
    jonescounty: {stateFP: "13", countyFP: "169"},
    lamarcounty: {stateFP: "13", countyFP: "171"},
    laniercounty: {stateFP: "13", countyFP: "173"},
    laurenscounty: {stateFP: "13", countyFP: "175"},
    leecounty: {stateFP: "13", countyFP: "177"},
    libertycounty: {stateFP: "13", countyFP: "179"},
    lincolncounty: {stateFP: "13", countyFP: "181"},
    longcounty: {stateFP: "13", countyFP: "183"},
    lowndescounty: {stateFP: "13", countyFP: "185"},
    lumpkincounty: {stateFP: "13", countyFP: "187"},
    mcduffiecounty: {stateFP: "13", countyFP: "189"},
    mcintoshcounty: {stateFP: "13", countyFP: "191"},
    maconcounty: {stateFP: "13", countyFP: "193"},
    madisoncounty: {stateFP: "13", countyFP: "195"},
    marioncounty: {stateFP: "13", countyFP: "197"},
    meriwethercounty: {stateFP: "13", countyFP: "199"},
    millercounty: {stateFP: "13", countyFP: "201"},
    mitchellcounty: {stateFP: "13", countyFP: "205"},
    monroecounty: {stateFP: "13", countyFP: "207"},
    montgomerycounty: {stateFP: "13", countyFP: "209"},
    morgancounty: {stateFP: "13", countyFP: "211"},
    murraycounty: {stateFP: "13", countyFP: "213"},
    muscogeecounty: {stateFP: "13", countyFP: "215"},
    newtoncounty: {stateFP: "13", countyFP: "217"},
    oconeecounty: {stateFP: "13", countyFP: "219"},
    oglethorpecounty: {stateFP: "13", countyFP: "221"},
    pauldingcounty: {stateFP: "13", countyFP: "223"},
    peachcounty: {stateFP: "13", countyFP: "225"},
    pickenscounty: {stateFP: "13", countyFP: "227"},
    piercecounty: {stateFP: "13", countyFP: "229"},
    pikecounty: {stateFP: "13", countyFP: "231"},
    polkcounty: {stateFP: "13", countyFP: "233"},
    pulaskicounty: {stateFP: "13", countyFP: "235"},
    putnamcounty: {stateFP: "13", countyFP: "237"},
    quitmancounty: {stateFP: "13", countyFP: "239"},
    rabuncounty: {stateFP: "13", countyFP: "241"},
    randolphcounty: {stateFP: "13", countyFP: "243"},
    richmondcounty: {stateFP: "13", countyFP: "245"},
    rockdalecounty: {stateFP: "13", countyFP: "247"},
    schleycounty: {stateFP: "13", countyFP: "249"},
    screvencounty: {stateFP: "13", countyFP: "251"},
    seminolecounty: {stateFP: "13", countyFP: "253"},
    spaldingcounty: {stateFP: "13", countyFP: "255"},
    stephenscounty: {stateFP: "13", countyFP: "257"},
    stewartcounty: {stateFP: "13", countyFP: "259"},
    sumtercounty: {stateFP: "13", countyFP: "261"},
    talbotcounty: {stateFP: "13", countyFP: "263"},
    taliaferrocounty: {stateFP: "13", countyFP: "265"},
    tattnallcounty: {stateFP: "13", countyFP: "267"},
    taylorcounty: {stateFP: "13", countyFP: "269"},
    telfaircounty: {stateFP: "13", countyFP: "271"},
    terrellcounty: {stateFP: "13", countyFP: "273"},
    thomascounty: {stateFP: "13", countyFP: "275"},
    tiftcounty: {stateFP: "13", countyFP: "277"},
    toombscounty: {stateFP: "13", countyFP: "279"},
    townscounty: {stateFP: "13", countyFP: "281"},
    treutlencounty: {stateFP: "13", countyFP: "283"},
    troupcounty: {stateFP: "13", countyFP: "285"},
    turnercounty: {stateFP: "13", countyFP: "287"},
    twiggscounty: {stateFP: "13", countyFP: "289"},
    unioncounty: {stateFP: "13", countyFP: "291"},
    upsoncounty: {stateFP: "13", countyFP: "293"},
    walkercounty: {stateFP: "13", countyFP: "295"},
    waltoncounty: {stateFP: "13", countyFP: "297"},
    warecounty: {stateFP: "13", countyFP: "299"},
    warrencounty: {stateFP: "13", countyFP: "301"},
    washingtoncounty: {stateFP: "13", countyFP: "303"},
    waynecounty: {stateFP: "13", countyFP: "305"},
    webstercounty: {stateFP: "13", countyFP: "307"},
    wheelercounty: {stateFP: "13", countyFP: "309"},
    whitecounty: {stateFP: "13", countyFP: "311"},
    whitfieldcounty: {stateFP: "13", countyFP: "313"},
    wilcoxcounty: {stateFP: "13", countyFP: "315"},
    wilkescounty: {stateFP: "13", countyFP: "317"},
    wilkinsoncounty: {stateFP: "13", countyFP: "319"},
    worthcounty: {stateFP: "13", countyFP: "321"},
  },
  hawaii: {
    hawaiicounty: {stateFP: "15", countyFP: "001"},
    honolulucounty: {stateFP: "15", countyFP: "003"},
    kalawaocounty: {stateFP: "15", countyFP: "005"},
    kauaicounty: {stateFP: "15", countyFP: "007"},
    mauicounty: {stateFP: "15", countyFP: "009"},
  },
  idaho: {
    adacounty: {stateFP: "16", countyFP: "001"},
    adamscounty: {stateFP: "16", countyFP: "003"},
    bannockcounty: {stateFP: "16", countyFP: "005"},
    bearlakecounty: {stateFP: "16", countyFP: "007"},
    benewahcounty: {stateFP: "16", countyFP: "009"},
    binghamcounty: {stateFP: "16", countyFP: "011"},
    blainecounty: {stateFP: "16", countyFP: "013"},
    boisecounty: {stateFP: "16", countyFP: "015"},
    bonnercounty: {stateFP: "16", countyFP: "017"},
    bonnevillecounty: {stateFP: "16", countyFP: "019"},
    boundarycounty: {stateFP: "16", countyFP: "021"},
    buttecounty: {stateFP: "16", countyFP: "023"},
    camascounty: {stateFP: "16", countyFP: "025"},
    canyoncounty: {stateFP: "16", countyFP: "027"},
    cariboucounty: {stateFP: "16", countyFP: "029"},
    cassiacounty: {stateFP: "16", countyFP: "031"},
    clarkcounty: {stateFP: "16", countyFP: "033"},
    clearwatercounty: {stateFP: "16", countyFP: "035"},
    custercounty: {stateFP: "16", countyFP: "037"},
    elmorecounty: {stateFP: "16", countyFP: "039"},
    franklincounty: {stateFP: "16", countyFP: "041"},
    fremontcounty: {stateFP: "16", countyFP: "043"},
    gemcounty: {stateFP: "16", countyFP: "045"},
    goodingcounty: {stateFP: "16", countyFP: "047"},
    idahocounty: {stateFP: "16", countyFP: "049"},
    jeffersoncounty: {stateFP: "16", countyFP: "051"},
    jeromecounty: {stateFP: "16", countyFP: "053"},
    kootenaicounty: {stateFP: "16", countyFP: "055"},
    latahcounty: {stateFP: "16", countyFP: "057"},
    lemhicounty: {stateFP: "16", countyFP: "059"},
    lewiscounty: {stateFP: "16", countyFP: "061"},
    lincolncounty: {stateFP: "16", countyFP: "063"},
    madisoncounty: {stateFP: "16", countyFP: "065"},
    minidokacounty: {stateFP: "16", countyFP: "067"},
    nezpercecounty: {stateFP: "16", countyFP: "069"},
    oneidacounty: {stateFP: "16", countyFP: "071"},
    owyheecounty: {stateFP: "16", countyFP: "073"},
    payettecounty: {stateFP: "16", countyFP: "075"},
    powercounty: {stateFP: "16", countyFP: "077"},
    shoshonecounty: {stateFP: "16", countyFP: "079"},
    tetoncounty: {stateFP: "16", countyFP: "081"},
    twinfallscounty: {stateFP: "16", countyFP: "083"},
    valleycounty: {stateFP: "16", countyFP: "085"},
    washingtoncounty: {stateFP: "16", countyFP: "087"},
  },
  illinois: {
    adamscounty: {stateFP: "17", countyFP: "001"},
    alexandercounty: {stateFP: "17", countyFP: "003"},
    bondcounty: {stateFP: "17", countyFP: "005"},
    boonecounty: {stateFP: "17", countyFP: "007"},
    browncounty: {stateFP: "17", countyFP: "009"},
    bureaucounty: {stateFP: "17", countyFP: "011"},
    calhouncounty: {stateFP: "17", countyFP: "013"},
    carrollcounty: {stateFP: "17", countyFP: "015"},
    casscounty: {stateFP: "17", countyFP: "017"},
    champaigncounty: {stateFP: "17", countyFP: "019"},
    christiancounty: {stateFP: "17", countyFP: "021"},
    clarkcounty: {stateFP: "17", countyFP: "023"},
    claycounty: {stateFP: "17", countyFP: "025"},
    clintoncounty: {stateFP: "17", countyFP: "027"},
    colescounty: {stateFP: "17", countyFP: "029"},
    cookcounty: {stateFP: "17", countyFP: "031"},
    crawfordcounty: {stateFP: "17", countyFP: "033"},
    cumberlandcounty: {stateFP: "17", countyFP: "035"},
    dekalbcounty: {stateFP: "17", countyFP: "037"},
    dewittcounty: {stateFP: "17", countyFP: "039"},
    douglascounty: {stateFP: "17", countyFP: "041"},
    dupagecounty: {stateFP: "17", countyFP: "043"},
    edgarcounty: {stateFP: "17", countyFP: "045"},
    edwardscounty: {stateFP: "17", countyFP: "047"},
    effinghamcounty: {stateFP: "17", countyFP: "049"},
    fayettecounty: {stateFP: "17", countyFP: "051"},
    fordcounty: {stateFP: "17", countyFP: "053"},
    franklincounty: {stateFP: "17", countyFP: "055"},
    fultoncounty: {stateFP: "17", countyFP: "057"},
    gallatincounty: {stateFP: "17", countyFP: "059"},
    greenecounty: {stateFP: "17", countyFP: "061"},
    grundycounty: {stateFP: "17", countyFP: "063"},
    hamiltoncounty: {stateFP: "17", countyFP: "065"},
    hancockcounty: {stateFP: "17", countyFP: "067"},
    hardincounty: {stateFP: "17", countyFP: "069"},
    hendersoncounty: {stateFP: "17", countyFP: "071"},
    henrycounty: {stateFP: "17", countyFP: "073"},
    iroquoiscounty: {stateFP: "17", countyFP: "075"},
    jacksoncounty: {stateFP: "17", countyFP: "077"},
    jaspercounty: {stateFP: "17", countyFP: "079"},
    jeffersoncounty: {stateFP: "17", countyFP: "081"},
    jerseycounty: {stateFP: "17", countyFP: "083"},
    jodaviesscounty: {stateFP: "17", countyFP: "085"},
    johnsoncounty: {stateFP: "17", countyFP: "087"},
    kanecounty: {stateFP: "17", countyFP: "089"},
    kankakeecounty: {stateFP: "17", countyFP: "091"},
    kendallcounty: {stateFP: "17", countyFP: "093"},
    knoxcounty: {stateFP: "17", countyFP: "095"},
    lakecounty: {stateFP: "17", countyFP: "097"},
    lasallecounty: {stateFP: "17", countyFP: "099"},
    lawrencecounty: {stateFP: "17", countyFP: "101"},
    leecounty: {stateFP: "17", countyFP: "103"},
    livingstoncounty: {stateFP: "17", countyFP: "105"},
    logancounty: {stateFP: "17", countyFP: "107"},
    mcdonoughcounty: {stateFP: "17", countyFP: "109"},
    mchenrycounty: {stateFP: "17", countyFP: "111"},
    mcleancounty: {stateFP: "17", countyFP: "113"},
    maconcounty: {stateFP: "17", countyFP: "115"},
    macoupincounty: {stateFP: "17", countyFP: "117"},
    madisoncounty: {stateFP: "17", countyFP: "119"},
    marioncounty: {stateFP: "17", countyFP: "121"},
    marshallcounty: {stateFP: "17", countyFP: "123"},
    masoncounty: {stateFP: "17", countyFP: "125"},
    massaccounty: {stateFP: "17", countyFP: "127"},
    menardcounty: {stateFP: "17", countyFP: "129"},
    mercercounty: {stateFP: "17", countyFP: "131"},
    monroecounty: {stateFP: "17", countyFP: "133"},
    montgomerycounty: {stateFP: "17", countyFP: "135"},
    morgancounty: {stateFP: "17", countyFP: "137"},
    moultriecounty: {stateFP: "17", countyFP: "139"},
    oglecounty: {stateFP: "17", countyFP: "141"},
    peoriacounty: {stateFP: "17", countyFP: "143"},
    perrycounty: {stateFP: "17", countyFP: "145"},
    piattcounty: {stateFP: "17", countyFP: "147"},
    pikecounty: {stateFP: "17", countyFP: "149"},
    popecounty: {stateFP: "17", countyFP: "151"},
    pulaskicounty: {stateFP: "17", countyFP: "153"},
    putnamcounty: {stateFP: "17", countyFP: "155"},
    randolphcounty: {stateFP: "17", countyFP: "157"},
    richlandcounty: {stateFP: "17", countyFP: "159"},
    rockislandcounty: {stateFP: "17", countyFP: "161"},
    stclaircounty: {stateFP: "17", countyFP: "163"},
    salinecounty: {stateFP: "17", countyFP: "165"},
    sangamoncounty: {stateFP: "17", countyFP: "167"},
    schuylercounty: {stateFP: "17", countyFP: "169"},
    scottcounty: {stateFP: "17", countyFP: "171"},
    shelbycounty: {stateFP: "17", countyFP: "173"},
    starkcounty: {stateFP: "17", countyFP: "175"},
    stephensoncounty: {stateFP: "17", countyFP: "177"},
    tazewellcounty: {stateFP: "17", countyFP: "179"},
    unioncounty: {stateFP: "17", countyFP: "181"},
    vermilioncounty: {stateFP: "17", countyFP: "183"},
    wabashcounty: {stateFP: "17", countyFP: "185"},
    warrencounty: {stateFP: "17", countyFP: "187"},
    washingtoncounty: {stateFP: "17", countyFP: "189"},
    waynecounty: {stateFP: "17", countyFP: "191"},
    whitecounty: {stateFP: "17", countyFP: "193"},
    whitesidecounty: {stateFP: "17", countyFP: "195"},
    willcounty: {stateFP: "17", countyFP: "197"},
    williamsoncounty: {stateFP: "17", countyFP: "199"},
    winnebagocounty: {stateFP: "17", countyFP: "201"},
    woodfordcounty: {stateFP: "17", countyFP: "203"},
  },
  indiana: {
    adamscounty: {stateFP: "18", countyFP: "001"},
    allencounty: {stateFP: "18", countyFP: "003"},
    bartholomewcounty: {stateFP: "18", countyFP: "005"},
    bentoncounty: {stateFP: "18", countyFP: "007"},
    blackfordcounty: {stateFP: "18", countyFP: "009"},
    boonecounty: {stateFP: "18", countyFP: "011"},
    browncounty: {stateFP: "18", countyFP: "013"},
    carrollcounty: {stateFP: "18", countyFP: "015"},
    casscounty: {stateFP: "18", countyFP: "017"},
    clarkcounty: {stateFP: "18", countyFP: "019"},
    claycounty: {stateFP: "18", countyFP: "021"},
    clintoncounty: {stateFP: "18", countyFP: "023"},
    crawfordcounty: {stateFP: "18", countyFP: "025"},
    daviesscounty: {stateFP: "18", countyFP: "027"},
    dearborncounty: {stateFP: "18", countyFP: "029"},
    decaturcounty: {stateFP: "18", countyFP: "031"},
    dekalbcounty: {stateFP: "18", countyFP: "033"},
    delawarecounty: {stateFP: "18", countyFP: "035"},
    duboiscounty: {stateFP: "18", countyFP: "037"},
    elkhartcounty: {stateFP: "18", countyFP: "039"},
    fayettecounty: {stateFP: "18", countyFP: "041"},
    floydcounty: {stateFP: "18", countyFP: "043"},
    fountaincounty: {stateFP: "18", countyFP: "045"},
    franklincounty: {stateFP: "18", countyFP: "047"},
    fultoncounty: {stateFP: "18", countyFP: "049"},
    gibsoncounty: {stateFP: "18", countyFP: "051"},
    grantcounty: {stateFP: "18", countyFP: "053"},
    greenecounty: {stateFP: "18", countyFP: "055"},
    hamiltoncounty: {stateFP: "18", countyFP: "057"},
    hancockcounty: {stateFP: "18", countyFP: "059"},
    harrisoncounty: {stateFP: "18", countyFP: "061"},
    hendrickscounty: {stateFP: "18", countyFP: "063"},
    henrycounty: {stateFP: "18", countyFP: "065"},
    howardcounty: {stateFP: "18", countyFP: "067"},
    huntingtoncounty: {stateFP: "18", countyFP: "069"},
    jacksoncounty: {stateFP: "18", countyFP: "071"},
    jaspercounty: {stateFP: "18", countyFP: "073"},
    jaycounty: {stateFP: "18", countyFP: "075"},
    jeffersoncounty: {stateFP: "18", countyFP: "077"},
    jenningscounty: {stateFP: "18", countyFP: "079"},
    johnsoncounty: {stateFP: "18", countyFP: "081"},
    knoxcounty: {stateFP: "18", countyFP: "083"},
    kosciuskocounty: {stateFP: "18", countyFP: "085"},
    lagrangecounty: {stateFP: "18", countyFP: "087"},
    lakecounty: {stateFP: "18", countyFP: "089"},
    laportecounty: {stateFP: "18", countyFP: "091"},
    lawrencecounty: {stateFP: "18", countyFP: "093"},
    madisoncounty: {stateFP: "18", countyFP: "095"},
    marioncounty: {stateFP: "18", countyFP: "097"},
    marshallcounty: {stateFP: "18", countyFP: "099"},
    martincounty: {stateFP: "18", countyFP: "101"},
    miamicounty: {stateFP: "18", countyFP: "103"},
    monroecounty: {stateFP: "18", countyFP: "105"},
    montgomerycounty: {stateFP: "18", countyFP: "107"},
    morgancounty: {stateFP: "18", countyFP: "109"},
    newtoncounty: {stateFP: "18", countyFP: "111"},
    noblecounty: {stateFP: "18", countyFP: "113"},
    ohiocounty: {stateFP: "18", countyFP: "115"},
    orangecounty: {stateFP: "18", countyFP: "117"},
    owencounty: {stateFP: "18", countyFP: "119"},
    parkecounty: {stateFP: "18", countyFP: "121"},
    perrycounty: {stateFP: "18", countyFP: "123"},
    pikecounty: {stateFP: "18", countyFP: "125"},
    portercounty: {stateFP: "18", countyFP: "127"},
    poseycounty: {stateFP: "18", countyFP: "129"},
    pulaskicounty: {stateFP: "18", countyFP: "131"},
    putnamcounty: {stateFP: "18", countyFP: "133"},
    randolphcounty: {stateFP: "18", countyFP: "135"},
    ripleycounty: {stateFP: "18", countyFP: "137"},
    rushcounty: {stateFP: "18", countyFP: "139"},
    stjosephcounty: {stateFP: "18", countyFP: "141"},
    scottcounty: {stateFP: "18", countyFP: "143"},
    shelbycounty: {stateFP: "18", countyFP: "145"},
    spencercounty: {stateFP: "18", countyFP: "147"},
    starkecounty: {stateFP: "18", countyFP: "149"},
    steubencounty: {stateFP: "18", countyFP: "151"},
    sullivancounty: {stateFP: "18", countyFP: "153"},
    switzerlandcounty: {stateFP: "18", countyFP: "155"},
    tippecanoecounty: {stateFP: "18", countyFP: "157"},
    tiptoncounty: {stateFP: "18", countyFP: "159"},
    unioncounty: {stateFP: "18", countyFP: "161"},
    vanderburghcounty: {stateFP: "18", countyFP: "163"},
    vermillioncounty: {stateFP: "18", countyFP: "165"},
    vigocounty: {stateFP: "18", countyFP: "167"},
    wabashcounty: {stateFP: "18", countyFP: "169"},
    warrencounty: {stateFP: "18", countyFP: "171"},
    warrickcounty: {stateFP: "18", countyFP: "173"},
    washingtoncounty: {stateFP: "18", countyFP: "175"},
    waynecounty: {stateFP: "18", countyFP: "177"},
    wellscounty: {stateFP: "18", countyFP: "179"},
    whitecounty: {stateFP: "18", countyFP: "181"},
    whitleycounty: {stateFP: "18", countyFP: "183"},
  },
  iowa: {
    adaircounty: {stateFP: "19", countyFP: "001"},
    adamscounty: {stateFP: "19", countyFP: "003"},
    allamakeecounty: {stateFP: "19", countyFP: "005"},
    appanoosecounty: {stateFP: "19", countyFP: "007"},
    auduboncounty: {stateFP: "19", countyFP: "009"},
    bentoncounty: {stateFP: "19", countyFP: "011"},
    blackhawkcounty: {stateFP: "19", countyFP: "013"},
    boonecounty: {stateFP: "19", countyFP: "015"},
    bremercounty: {stateFP: "19", countyFP: "017"},
    buchanancounty: {stateFP: "19", countyFP: "019"},
    buenavistacounty: {stateFP: "19", countyFP: "021"},
    butlercounty: {stateFP: "19", countyFP: "023"},
    calhouncounty: {stateFP: "19", countyFP: "025"},
    carrollcounty: {stateFP: "19", countyFP: "027"},
    casscounty: {stateFP: "19", countyFP: "029"},
    cedarcounty: {stateFP: "19", countyFP: "031"},
    cerrogordocounty: {stateFP: "19", countyFP: "033"},
    cherokeecounty: {stateFP: "19", countyFP: "035"},
    chickasawcounty: {stateFP: "19", countyFP: "037"},
    clarkecounty: {stateFP: "19", countyFP: "039"},
    claycounty: {stateFP: "19", countyFP: "041"},
    claytoncounty: {stateFP: "19", countyFP: "043"},
    clintoncounty: {stateFP: "19", countyFP: "045"},
    crawfordcounty: {stateFP: "19", countyFP: "047"},
    dallascounty: {stateFP: "19", countyFP: "049"},
    daviscounty: {stateFP: "19", countyFP: "051"},
    decaturcounty: {stateFP: "19", countyFP: "053"},
    delawarecounty: {stateFP: "19", countyFP: "055"},
    desmoinescounty: {stateFP: "19", countyFP: "057"},
    dickinsoncounty: {stateFP: "19", countyFP: "059"},
    dubuquecounty: {stateFP: "19", countyFP: "061"},
    emmetcounty: {stateFP: "19", countyFP: "063"},
    fayettecounty: {stateFP: "19", countyFP: "065"},
    floydcounty: {stateFP: "19", countyFP: "067"},
    franklincounty: {stateFP: "19", countyFP: "069"},
    fremontcounty: {stateFP: "19", countyFP: "071"},
    greenecounty: {stateFP: "19", countyFP: "073"},
    grundycounty: {stateFP: "19", countyFP: "075"},
    guthriecounty: {stateFP: "19", countyFP: "077"},
    hamiltoncounty: {stateFP: "19", countyFP: "079"},
    hancockcounty: {stateFP: "19", countyFP: "081"},
    hardincounty: {stateFP: "19", countyFP: "083"},
    harrisoncounty: {stateFP: "19", countyFP: "085"},
    henrycounty: {stateFP: "19", countyFP: "087"},
    howardcounty: {stateFP: "19", countyFP: "089"},
    humboldtcounty: {stateFP: "19", countyFP: "091"},
    idacounty: {stateFP: "19", countyFP: "093"},
    iowacounty: {stateFP: "19", countyFP: "095"},
    jacksoncounty: {stateFP: "19", countyFP: "097"},
    jaspercounty: {stateFP: "19", countyFP: "099"},
    jeffersoncounty: {stateFP: "19", countyFP: "101"},
    johnsoncounty: {stateFP: "19", countyFP: "103"},
    jonescounty: {stateFP: "19", countyFP: "105"},
    keokukcounty: {stateFP: "19", countyFP: "107"},
    kossuthcounty: {stateFP: "19", countyFP: "109"},
    leecounty: {stateFP: "19", countyFP: "111"},
    linncounty: {stateFP: "19", countyFP: "113"},
    louisacounty: {stateFP: "19", countyFP: "115"},
    lucascounty: {stateFP: "19", countyFP: "117"},
    lyoncounty: {stateFP: "19", countyFP: "119"},
    madisoncounty: {stateFP: "19", countyFP: "121"},
    mahaskacounty: {stateFP: "19", countyFP: "123"},
    marioncounty: {stateFP: "19", countyFP: "125"},
    marshallcounty: {stateFP: "19", countyFP: "127"},
    millscounty: {stateFP: "19", countyFP: "129"},
    mitchellcounty: {stateFP: "19", countyFP: "131"},
    mononacounty: {stateFP: "19", countyFP: "133"},
    monroecounty: {stateFP: "19", countyFP: "135"},
    montgomerycounty: {stateFP: "19", countyFP: "137"},
    muscatinecounty: {stateFP: "19", countyFP: "139"},
    obriencounty: {stateFP: "19", countyFP: "141"},
    osceolacounty: {stateFP: "19", countyFP: "143"},
    pagecounty: {stateFP: "19", countyFP: "145"},
    paloaltocounty: {stateFP: "19", countyFP: "147"},
    plymouthcounty: {stateFP: "19", countyFP: "149"},
    pocahontascounty: {stateFP: "19", countyFP: "151"},
    polkcounty: {stateFP: "19", countyFP: "153"},
    pottawattamiecounty: {stateFP: "19", countyFP: "155"},
    poweshiekcounty: {stateFP: "19", countyFP: "157"},
    ringgoldcounty: {stateFP: "19", countyFP: "159"},
    saccounty: {stateFP: "19", countyFP: "161"},
    scottcounty: {stateFP: "19", countyFP: "163"},
    shelbycounty: {stateFP: "19", countyFP: "165"},
    siouxcounty: {stateFP: "19", countyFP: "167"},
    storycounty: {stateFP: "19", countyFP: "169"},
    tamacounty: {stateFP: "19", countyFP: "171"},
    taylorcounty: {stateFP: "19", countyFP: "173"},
    unioncounty: {stateFP: "19", countyFP: "175"},
    vanburencounty: {stateFP: "19", countyFP: "177"},
    wapellocounty: {stateFP: "19", countyFP: "179"},
    warrencounty: {stateFP: "19", countyFP: "181"},
    washingtoncounty: {stateFP: "19", countyFP: "183"},
    waynecounty: {stateFP: "19", countyFP: "185"},
    webstercounty: {stateFP: "19", countyFP: "187"},
    winnebagocounty: {stateFP: "19", countyFP: "189"},
    winneshiekcounty: {stateFP: "19", countyFP: "191"},
    woodburycounty: {stateFP: "19", countyFP: "193"},
    worthcounty: {stateFP: "19", countyFP: "195"},
    wrightcounty: {stateFP: "19", countyFP: "197"},
  },
  kansas: {
    allencounty: {stateFP: "20", countyFP: "001"},
    andersoncounty: {stateFP: "20", countyFP: "003"},
    atchisoncounty: {stateFP: "20", countyFP: "005"},
    barbercounty: {stateFP: "20", countyFP: "007"},
    bartoncounty: {stateFP: "20", countyFP: "009"},
    bourboncounty: {stateFP: "20", countyFP: "011"},
    browncounty: {stateFP: "20", countyFP: "013"},
    butlercounty: {stateFP: "20", countyFP: "015"},
    chasecounty: {stateFP: "20", countyFP: "017"},
    chautauquacounty: {stateFP: "20", countyFP: "019"},
    cherokeecounty: {stateFP: "20", countyFP: "021"},
    cheyennecounty: {stateFP: "20", countyFP: "023"},
    clarkcounty: {stateFP: "20", countyFP: "025"},
    claycounty: {stateFP: "20", countyFP: "027"},
    cloudcounty: {stateFP: "20", countyFP: "029"},
    coffeycounty: {stateFP: "20", countyFP: "031"},
    comanchecounty: {stateFP: "20", countyFP: "033"},
    cowleycounty: {stateFP: "20", countyFP: "035"},
    crawfordcounty: {stateFP: "20", countyFP: "037"},
    decaturcounty: {stateFP: "20", countyFP: "039"},
    dickinsoncounty: {stateFP: "20", countyFP: "041"},
    doniphancounty: {stateFP: "20", countyFP: "043"},
    douglascounty: {stateFP: "20", countyFP: "045"},
    edwardscounty: {stateFP: "20", countyFP: "047"},
    elkcounty: {stateFP: "20", countyFP: "049"},
    elliscounty: {stateFP: "20", countyFP: "051"},
    ellsworthcounty: {stateFP: "20", countyFP: "053"},
    finneycounty: {stateFP: "20", countyFP: "055"},
    fordcounty: {stateFP: "20", countyFP: "057"},
    franklincounty: {stateFP: "20", countyFP: "059"},
    gearycounty: {stateFP: "20", countyFP: "061"},
    govecounty: {stateFP: "20", countyFP: "063"},
    grahamcounty: {stateFP: "20", countyFP: "065"},
    grantcounty: {stateFP: "20", countyFP: "067"},
    graycounty: {stateFP: "20", countyFP: "069"},
    greeleycounty: {stateFP: "20", countyFP: "071"},
    greenwoodcounty: {stateFP: "20", countyFP: "073"},
    hamiltoncounty: {stateFP: "20", countyFP: "075"},
    harpercounty: {stateFP: "20", countyFP: "077"},
    harveycounty: {stateFP: "20", countyFP: "079"},
    haskellcounty: {stateFP: "20", countyFP: "081"},
    hodgemancounty: {stateFP: "20", countyFP: "083"},
    jacksoncounty: {stateFP: "20", countyFP: "085"},
    jeffersoncounty: {stateFP: "20", countyFP: "087"},
    jewellcounty: {stateFP: "20", countyFP: "089"},
    johnsoncounty: {stateFP: "20", countyFP: "091"},
    kearnycounty: {stateFP: "20", countyFP: "093"},
    kingmancounty: {stateFP: "20", countyFP: "095"},
    kiowacounty: {stateFP: "20", countyFP: "097"},
    labettecounty: {stateFP: "20", countyFP: "099"},
    lanecounty: {stateFP: "20", countyFP: "101"},
    leavenworthcounty: {stateFP: "20", countyFP: "103"},
    lincolncounty: {stateFP: "20", countyFP: "105"},
    linncounty: {stateFP: "20", countyFP: "107"},
    logancounty: {stateFP: "20", countyFP: "109"},
    lyoncounty: {stateFP: "20", countyFP: "111"},
    mcphersoncounty: {stateFP: "20", countyFP: "113"},
    marioncounty: {stateFP: "20", countyFP: "115"},
    marshallcounty: {stateFP: "20", countyFP: "117"},
    meadecounty: {stateFP: "20", countyFP: "119"},
    miamicounty: {stateFP: "20", countyFP: "121"},
    mitchellcounty: {stateFP: "20", countyFP: "123"},
    montgomerycounty: {stateFP: "20", countyFP: "125"},
    morriscounty: {stateFP: "20", countyFP: "127"},
    mortoncounty: {stateFP: "20", countyFP: "129"},
    nemahacounty: {stateFP: "20", countyFP: "131"},
    neoshocounty: {stateFP: "20", countyFP: "133"},
    nesscounty: {stateFP: "20", countyFP: "135"},
    nortoncounty: {stateFP: "20", countyFP: "137"},
    osagecounty: {stateFP: "20", countyFP: "139"},
    osbornecounty: {stateFP: "20", countyFP: "141"},
    ottawacounty: {stateFP: "20", countyFP: "143"},
    pawneecounty: {stateFP: "20", countyFP: "145"},
    phillipscounty: {stateFP: "20", countyFP: "147"},
    pottawatomiecounty: {stateFP: "20", countyFP: "149"},
    prattcounty: {stateFP: "20", countyFP: "151"},
    rawlinscounty: {stateFP: "20", countyFP: "153"},
    renocounty: {stateFP: "20", countyFP: "155"},
    republiccounty: {stateFP: "20", countyFP: "157"},
    ricecounty: {stateFP: "20", countyFP: "159"},
    rileycounty: {stateFP: "20", countyFP: "161"},
    rookscounty: {stateFP: "20", countyFP: "163"},
    rushcounty: {stateFP: "20", countyFP: "165"},
    russellcounty: {stateFP: "20", countyFP: "167"},
    salinecounty: {stateFP: "20", countyFP: "169"},
    scottcounty: {stateFP: "20", countyFP: "171"},
    sedgwickcounty: {stateFP: "20", countyFP: "173"},
    sewardcounty: {stateFP: "20", countyFP: "175"},
    shawneecounty: {stateFP: "20", countyFP: "177"},
    sheridancounty: {stateFP: "20", countyFP: "179"},
    shermancounty: {stateFP: "20", countyFP: "181"},
    smithcounty: {stateFP: "20", countyFP: "183"},
    staffordcounty: {stateFP: "20", countyFP: "185"},
    stantoncounty: {stateFP: "20", countyFP: "187"},
    stevenscounty: {stateFP: "20", countyFP: "189"},
    sumnercounty: {stateFP: "20", countyFP: "191"},
    thomascounty: {stateFP: "20", countyFP: "193"},
    tregocounty: {stateFP: "20", countyFP: "195"},
    wabaunseecounty: {stateFP: "20", countyFP: "197"},
    wallacecounty: {stateFP: "20", countyFP: "199"},
    washingtoncounty: {stateFP: "20", countyFP: "201"},
    wichitacounty: {stateFP: "20", countyFP: "203"},
    wilsoncounty: {stateFP: "20", countyFP: "205"},
    woodsoncounty: {stateFP: "20", countyFP: "207"},
    wyandottecounty: {stateFP: "20", countyFP: "209"},
  },
  kentucky: {
    adaircounty: {stateFP: "21", countyFP: "001"},
    allencounty: {stateFP: "21", countyFP: "003"},
    andersoncounty: {stateFP: "21", countyFP: "005"},
    ballardcounty: {stateFP: "21", countyFP: "007"},
    barrencounty: {stateFP: "21", countyFP: "009"},
    bathcounty: {stateFP: "21", countyFP: "011"},
    bellcounty: {stateFP: "21", countyFP: "013"},
    boonecounty: {stateFP: "21", countyFP: "015"},
    bourboncounty: {stateFP: "21", countyFP: "017"},
    boydcounty: {stateFP: "21", countyFP: "019"},
    boylecounty: {stateFP: "21", countyFP: "021"},
    brackencounty: {stateFP: "21", countyFP: "023"},
    breathittcounty: {stateFP: "21", countyFP: "025"},
    breckinridgecounty: {stateFP: "21", countyFP: "027"},
    bullittcounty: {stateFP: "21", countyFP: "029"},
    butlercounty: {stateFP: "21", countyFP: "031"},
    caldwellcounty: {stateFP: "21", countyFP: "033"},
    callowaycounty: {stateFP: "21", countyFP: "035"},
    campbellcounty: {stateFP: "21", countyFP: "037"},
    carlislecounty: {stateFP: "21", countyFP: "039"},
    carrollcounty: {stateFP: "21", countyFP: "041"},
    cartercounty: {stateFP: "21", countyFP: "043"},
    caseycounty: {stateFP: "21", countyFP: "045"},
    christiancounty: {stateFP: "21", countyFP: "047"},
    clarkcounty: {stateFP: "21", countyFP: "049"},
    claycounty: {stateFP: "21", countyFP: "051"},
    clintoncounty: {stateFP: "21", countyFP: "053"},
    crittendencounty: {stateFP: "21", countyFP: "055"},
    cumberlandcounty: {stateFP: "21", countyFP: "057"},
    daviesscounty: {stateFP: "21", countyFP: "059"},
    edmonsoncounty: {stateFP: "21", countyFP: "061"},
    elliottcounty: {stateFP: "21", countyFP: "063"},
    estillcounty: {stateFP: "21", countyFP: "065"},
    fayettecounty: {stateFP: "21", countyFP: "067"},
    flemingcounty: {stateFP: "21", countyFP: "069"},
    floydcounty: {stateFP: "21", countyFP: "071"},
    franklincounty: {stateFP: "21", countyFP: "073"},
    fultoncounty: {stateFP: "21", countyFP: "075"},
    gallatincounty: {stateFP: "21", countyFP: "077"},
    garrardcounty: {stateFP: "21", countyFP: "079"},
    grantcounty: {stateFP: "21", countyFP: "081"},
    gravescounty: {stateFP: "21", countyFP: "083"},
    graysoncounty: {stateFP: "21", countyFP: "085"},
    greencounty: {stateFP: "21", countyFP: "087"},
    greenupcounty: {stateFP: "21", countyFP: "089"},
    hancockcounty: {stateFP: "21", countyFP: "091"},
    hardincounty: {stateFP: "21", countyFP: "093"},
    harlancounty: {stateFP: "21", countyFP: "095"},
    harrisoncounty: {stateFP: "21", countyFP: "097"},
    hartcounty: {stateFP: "21", countyFP: "099"},
    hendersoncounty: {stateFP: "21", countyFP: "101"},
    henrycounty: {stateFP: "21", countyFP: "103"},
    hickmancounty: {stateFP: "21", countyFP: "105"},
    hopkinscounty: {stateFP: "21", countyFP: "107"},
    jacksoncounty: {stateFP: "21", countyFP: "109"},
    jeffersoncounty: {stateFP: "21", countyFP: "111"},
    jessaminecounty: {stateFP: "21", countyFP: "113"},
    johnsoncounty: {stateFP: "21", countyFP: "115"},
    kentoncounty: {stateFP: "21", countyFP: "117"},
    knottcounty: {stateFP: "21", countyFP: "119"},
    knoxcounty: {stateFP: "21", countyFP: "121"},
    laruecounty: {stateFP: "21", countyFP: "123"},
    laurelcounty: {stateFP: "21", countyFP: "125"},
    lawrencecounty: {stateFP: "21", countyFP: "127"},
    leecounty: {stateFP: "21", countyFP: "129"},
    lesliecounty: {stateFP: "21", countyFP: "131"},
    letchercounty: {stateFP: "21", countyFP: "133"},
    lewiscounty: {stateFP: "21", countyFP: "135"},
    lincolncounty: {stateFP: "21", countyFP: "137"},
    livingstoncounty: {stateFP: "21", countyFP: "139"},
    logancounty: {stateFP: "21", countyFP: "141"},
    lyoncounty: {stateFP: "21", countyFP: "143"},
    mccrackencounty: {stateFP: "21", countyFP: "145"},
    mccrearycounty: {stateFP: "21", countyFP: "147"},
    mcleancounty: {stateFP: "21", countyFP: "149"},
    madisoncounty: {stateFP: "21", countyFP: "151"},
    magoffincounty: {stateFP: "21", countyFP: "153"},
    marioncounty: {stateFP: "21", countyFP: "155"},
    marshallcounty: {stateFP: "21", countyFP: "157"},
    martincounty: {stateFP: "21", countyFP: "159"},
    masoncounty: {stateFP: "21", countyFP: "161"},
    meadecounty: {stateFP: "21", countyFP: "163"},
    menifeecounty: {stateFP: "21", countyFP: "165"},
    mercercounty: {stateFP: "21", countyFP: "167"},
    metcalfecounty: {stateFP: "21", countyFP: "169"},
    monroecounty: {stateFP: "21", countyFP: "171"},
    montgomerycounty: {stateFP: "21", countyFP: "173"},
    morgancounty: {stateFP: "21", countyFP: "175"},
    muhlenbergcounty: {stateFP: "21", countyFP: "177"},
    nelsoncounty: {stateFP: "21", countyFP: "179"},
    nicholascounty: {stateFP: "21", countyFP: "181"},
    ohiocounty: {stateFP: "21", countyFP: "183"},
    oldhamcounty: {stateFP: "21", countyFP: "185"},
    owencounty: {stateFP: "21", countyFP: "187"},
    owsleycounty: {stateFP: "21", countyFP: "189"},
    pendletoncounty: {stateFP: "21", countyFP: "191"},
    perrycounty: {stateFP: "21", countyFP: "193"},
    pikecounty: {stateFP: "21", countyFP: "195"},
    powellcounty: {stateFP: "21", countyFP: "197"},
    pulaskicounty: {stateFP: "21", countyFP: "199"},
    robertsoncounty: {stateFP: "21", countyFP: "201"},
    rockcastlecounty: {stateFP: "21", countyFP: "203"},
    rowancounty: {stateFP: "21", countyFP: "205"},
    russellcounty: {stateFP: "21", countyFP: "207"},
    scottcounty: {stateFP: "21", countyFP: "209"},
    shelbycounty: {stateFP: "21", countyFP: "211"},
    simpsoncounty: {stateFP: "21", countyFP: "213"},
    spencercounty: {stateFP: "21", countyFP: "215"},
    taylorcounty: {stateFP: "21", countyFP: "217"},
    toddcounty: {stateFP: "21", countyFP: "219"},
    triggcounty: {stateFP: "21", countyFP: "221"},
    trimblecounty: {stateFP: "21", countyFP: "223"},
    unioncounty: {stateFP: "21", countyFP: "225"},
    warrencounty: {stateFP: "21", countyFP: "227"},
    washingtoncounty: {stateFP: "21", countyFP: "229"},
    waynecounty: {stateFP: "21", countyFP: "231"},
    webstercounty: {stateFP: "21", countyFP: "233"},
    whitleycounty: {stateFP: "21", countyFP: "235"},
    wolfecounty: {stateFP: "21", countyFP: "237"},
    woodfordcounty: {stateFP: "21", countyFP: "239"},
  },
  louisiana: {
    acadiaparish: {stateFP: "22", countyFP: "001"},
    allenparish: {stateFP: "22", countyFP: "003"},
    ascensionparish: {stateFP: "22", countyFP: "005"},
    assumptionparish: {stateFP: "22", countyFP: "007"},
    avoyellesparish: {stateFP: "22", countyFP: "009"},
    beauregardparish: {stateFP: "22", countyFP: "011"},
    bienvilleparish: {stateFP: "22", countyFP: "013"},
    bossierparish: {stateFP: "22", countyFP: "015"},
    caddoparish: {stateFP: "22", countyFP: "017"},
    calcasieuparish: {stateFP: "22", countyFP: "019"},
    caldwellparish: {stateFP: "22", countyFP: "021"},
    cameronparish: {stateFP: "22", countyFP: "023"},
    catahoulaparish: {stateFP: "22", countyFP: "025"},
    claiborneparish: {stateFP: "22", countyFP: "027"},
    concordiaparish: {stateFP: "22", countyFP: "029"},
    desotoparish: {stateFP: "22", countyFP: "031"},
    eastbatonrougeparish: {stateFP: "22", countyFP: "033"},
    eastcarrollparish: {stateFP: "22", countyFP: "035"},
    eastfelicianaparish: {stateFP: "22", countyFP: "037"},
    evangelineparish: {stateFP: "22", countyFP: "039"},
    franklinparish: {stateFP: "22", countyFP: "041"},
    grantparish: {stateFP: "22", countyFP: "043"},
    iberiaparish: {stateFP: "22", countyFP: "045"},
    ibervilleparish: {stateFP: "22", countyFP: "047"},
    jacksonparish: {stateFP: "22", countyFP: "049"},
    jeffersonparish: {stateFP: "22", countyFP: "051"},
    jeffersondavisparish: {stateFP: "22", countyFP: "053"},
    lafayetteparish: {stateFP: "22", countyFP: "055"},
    lafourcheparish: {stateFP: "22", countyFP: "057"},
    lasalleparish: {stateFP: "22", countyFP: "059"},
    lincolnparish: {stateFP: "22", countyFP: "061"},
    livingstonparish: {stateFP: "22", countyFP: "063"},
    madisonparish: {stateFP: "22", countyFP: "065"},
    morehouseparish: {stateFP: "22", countyFP: "067"},
    natchitochesparish: {stateFP: "22", countyFP: "069"},
    orleansparish: {stateFP: "22", countyFP: "071"},
    ouachitaparish: {stateFP: "22", countyFP: "073"},
    plaqueminesparish: {stateFP: "22", countyFP: "075"},
    pointecoupeeparish: {stateFP: "22", countyFP: "077"},
    rapidesparish: {stateFP: "22", countyFP: "079"},
    redriverparish: {stateFP: "22", countyFP: "081"},
    richlandparish: {stateFP: "22", countyFP: "083"},
    sabineparish: {stateFP: "22", countyFP: "085"},
    stbernardparish: {stateFP: "22", countyFP: "087"},
    stcharlesparish: {stateFP: "22", countyFP: "089"},
    sthelenaparish: {stateFP: "22", countyFP: "091"},
    stjamesparish: {stateFP: "22", countyFP: "093"},
    stjohnthebaptistparish: {stateFP: "22", countyFP: "095"},
    stlandryparish: {stateFP: "22", countyFP: "097"},
    stmartinparish: {stateFP: "22", countyFP: "099"},
    stmaryparish: {stateFP: "22", countyFP: "101"},
    sttammanyparish: {stateFP: "22", countyFP: "103"},
    tangipahoaparish: {stateFP: "22", countyFP: "105"},
    tensasparish: {stateFP: "22", countyFP: "107"},
    terrebonneparish: {stateFP: "22", countyFP: "109"},
    unionparish: {stateFP: "22", countyFP: "111"},
    vermilionparish: {stateFP: "22", countyFP: "113"},
    vernonparish: {stateFP: "22", countyFP: "115"},
    washingtonparish: {stateFP: "22", countyFP: "117"},
    websterparish: {stateFP: "22", countyFP: "119"},
    westbatonrougeparish: {stateFP: "22", countyFP: "121"},
    westcarrollparish: {stateFP: "22", countyFP: "123"},
    westfelicianaparish: {stateFP: "22", countyFP: "125"},
    winnparish: {stateFP: "22", countyFP: "127"},
  },
  maine: {
    androscoggincounty: {stateFP: "23", countyFP: "001"},
    aroostookcounty: {stateFP: "23", countyFP: "003"},
    cumberlandcounty: {stateFP: "23", countyFP: "005"},
    franklincounty: {stateFP: "23", countyFP: "007"},
    hancockcounty: {stateFP: "23", countyFP: "009"},
    kennebeccounty: {stateFP: "23", countyFP: "011"},
    knoxcounty: {stateFP: "23", countyFP: "013"},
    lincolncounty: {stateFP: "23", countyFP: "015"},
    oxfordcounty: {stateFP: "23", countyFP: "017"},
    penobscotcounty: {stateFP: "23", countyFP: "019"},
    piscataquiscounty: {stateFP: "23", countyFP: "021"},
    sagadahoccounty: {stateFP: "23", countyFP: "023"},
    somersetcounty: {stateFP: "23", countyFP: "025"},
    waldocounty: {stateFP: "23", countyFP: "027"},
    washingtoncounty: {stateFP: "23", countyFP: "029"},
    yorkcounty: {stateFP: "23", countyFP: "031"},
  },
  maryland: {
    alleganycounty: {stateFP: "24", countyFP: "001"},
    annearundelcounty: {stateFP: "24", countyFP: "003"},
    baltimorecounty: {stateFP: "24", countyFP: "005"},
    calvertcounty: {stateFP: "24", countyFP: "009"},
    carolinecounty: {stateFP: "24", countyFP: "011"},
    carrollcounty: {stateFP: "24", countyFP: "013"},
    cecilcounty: {stateFP: "24", countyFP: "015"},
    charlescounty: {stateFP: "24", countyFP: "017"},
    dorchestercounty: {stateFP: "24", countyFP: "019"},
    frederickcounty: {stateFP: "24", countyFP: "021"},
    garrettcounty: {stateFP: "24", countyFP: "023"},
    harfordcounty: {stateFP: "24", countyFP: "025"},
    howardcounty: {stateFP: "24", countyFP: "027"},
    kentcounty: {stateFP: "24", countyFP: "029"},
    montgomerycounty: {stateFP: "24", countyFP: "031"},
    princegeorgescounty: {stateFP: "24", countyFP: "033"},
    queenannescounty: {stateFP: "24", countyFP: "035"},
    stmaryscounty: {stateFP: "24", countyFP: "037"},
    somersetcounty: {stateFP: "24", countyFP: "039"},
    talbotcounty: {stateFP: "24", countyFP: "041"},
    washingtoncounty: {stateFP: "24", countyFP: "043"},
    wicomicocounty: {stateFP: "24", countyFP: "045"},
    worcestercounty: {stateFP: "24", countyFP: "047"},
    baltimorecity: {stateFP: "24", countyFP: "510"},
  },
  massachusetts: {
    barnstablecounty: {stateFP: "25", countyFP: "001"},
    berkshirecounty: {stateFP: "25", countyFP: "003"},
    bristolcounty: {stateFP: "25", countyFP: "005"},
    dukescounty: {stateFP: "25", countyFP: "007"},
    essexcounty: {stateFP: "25", countyFP: "009"},
    franklincounty: {stateFP: "25", countyFP: "011"},
    hampdencounty: {stateFP: "25", countyFP: "013"},
    hampshirecounty: {stateFP: "25", countyFP: "015"},
    middlesexcounty: {stateFP: "25", countyFP: "017"},
    nantucketcounty: {stateFP: "25", countyFP: "019"},
    norfolkcounty: {stateFP: "25", countyFP: "021"},
    plymouthcounty: {stateFP: "25", countyFP: "023"},
    suffolkcounty: {stateFP: "25", countyFP: "025"},
    worcestercounty: {stateFP: "25", countyFP: "027"},
  },
  michigan: {
    alconacounty: {stateFP: "26", countyFP: "001"},
    algercounty: {stateFP: "26", countyFP: "003"},
    allegancounty: {stateFP: "26", countyFP: "005"},
    alpenacounty: {stateFP: "26", countyFP: "007"},
    antrimcounty: {stateFP: "26", countyFP: "009"},
    arenaccounty: {stateFP: "26", countyFP: "011"},
    baragacounty: {stateFP: "26", countyFP: "013"},
    barrycounty: {stateFP: "26", countyFP: "015"},
    baycounty: {stateFP: "26", countyFP: "017"},
    benziecounty: {stateFP: "26", countyFP: "019"},
    berriencounty: {stateFP: "26", countyFP: "021"},
    branchcounty: {stateFP: "26", countyFP: "023"},
    calhouncounty: {stateFP: "26", countyFP: "025"},
    casscounty: {stateFP: "26", countyFP: "027"},
    charlevoixcounty: {stateFP: "26", countyFP: "029"},
    cheboygancounty: {stateFP: "26", countyFP: "031"},
    chippewacounty: {stateFP: "26", countyFP: "033"},
    clarecounty: {stateFP: "26", countyFP: "035"},
    clintoncounty: {stateFP: "26", countyFP: "037"},
    crawfordcounty: {stateFP: "26", countyFP: "039"},
    deltacounty: {stateFP: "26", countyFP: "041"},
    dickinsoncounty: {stateFP: "26", countyFP: "043"},
    eatoncounty: {stateFP: "26", countyFP: "045"},
    emmetcounty: {stateFP: "26", countyFP: "047"},
    geneseecounty: {stateFP: "26", countyFP: "049"},
    gladwincounty: {stateFP: "26", countyFP: "051"},
    gogebiccounty: {stateFP: "26", countyFP: "053"},
    grandtraversecounty: {stateFP: "26", countyFP: "055"},
    gratiotcounty: {stateFP: "26", countyFP: "057"},
    hillsdalecounty: {stateFP: "26", countyFP: "059"},
    houghtoncounty: {stateFP: "26", countyFP: "061"},
    huroncounty: {stateFP: "26", countyFP: "063"},
    inghamcounty: {stateFP: "26", countyFP: "065"},
    ioniacounty: {stateFP: "26", countyFP: "067"},
    ioscocounty: {stateFP: "26", countyFP: "069"},
    ironcounty: {stateFP: "26", countyFP: "071"},
    isabellacounty: {stateFP: "26", countyFP: "073"},
    jacksoncounty: {stateFP: "26", countyFP: "075"},
    kalamazoocounty: {stateFP: "26", countyFP: "077"},
    kalkaskacounty: {stateFP: "26", countyFP: "079"},
    kentcounty: {stateFP: "26", countyFP: "081"},
    keweenawcounty: {stateFP: "26", countyFP: "083"},
    lakecounty: {stateFP: "26", countyFP: "085"},
    lapeercounty: {stateFP: "26", countyFP: "087"},
    leelanaucounty: {stateFP: "26", countyFP: "089"},
    lenaweecounty: {stateFP: "26", countyFP: "091"},
    livingstoncounty: {stateFP: "26", countyFP: "093"},
    lucecounty: {stateFP: "26", countyFP: "095"},
    mackinaccounty: {stateFP: "26", countyFP: "097"},
    macombcounty: {stateFP: "26", countyFP: "099"},
    manisteecounty: {stateFP: "26", countyFP: "101"},
    marquettecounty: {stateFP: "26", countyFP: "103"},
    masoncounty: {stateFP: "26", countyFP: "105"},
    mecostacounty: {stateFP: "26", countyFP: "107"},
    menomineecounty: {stateFP: "26", countyFP: "109"},
    midlandcounty: {stateFP: "26", countyFP: "111"},
    missaukeecounty: {stateFP: "26", countyFP: "113"},
    monroecounty: {stateFP: "26", countyFP: "115"},
    montcalmcounty: {stateFP: "26", countyFP: "117"},
    montmorencycounty: {stateFP: "26", countyFP: "119"},
    muskegoncounty: {stateFP: "26", countyFP: "121"},
    newaygocounty: {stateFP: "26", countyFP: "123"},
    oaklandcounty: {stateFP: "26", countyFP: "125"},
    oceanacounty: {stateFP: "26", countyFP: "127"},
    ogemawcounty: {stateFP: "26", countyFP: "129"},
    ontonagoncounty: {stateFP: "26", countyFP: "131"},
    osceolacounty: {stateFP: "26", countyFP: "133"},
    oscodacounty: {stateFP: "26", countyFP: "135"},
    otsegocounty: {stateFP: "26", countyFP: "137"},
    ottawacounty: {stateFP: "26", countyFP: "139"},
    presqueislecounty: {stateFP: "26", countyFP: "141"},
    roscommoncounty: {stateFP: "26", countyFP: "143"},
    saginawcounty: {stateFP: "26", countyFP: "145"},
    stclaircounty: {stateFP: "26", countyFP: "147"},
    stjosephcounty: {stateFP: "26", countyFP: "149"},
    sanilaccounty: {stateFP: "26", countyFP: "151"},
    schoolcraftcounty: {stateFP: "26", countyFP: "153"},
    shiawasseecounty: {stateFP: "26", countyFP: "155"},
    tuscolacounty: {stateFP: "26", countyFP: "157"},
    vanburencounty: {stateFP: "26", countyFP: "159"},
    washtenawcounty: {stateFP: "26", countyFP: "161"},
    waynecounty: {stateFP: "26", countyFP: "163"},
    wexfordcounty: {stateFP: "26", countyFP: "165"},
  },
  minnesota: {
    aitkincounty: {stateFP: "27", countyFP: "001"},
    anokacounty: {stateFP: "27", countyFP: "003"},
    beckercounty: {stateFP: "27", countyFP: "005"},
    beltramicounty: {stateFP: "27", countyFP: "007"},
    bentoncounty: {stateFP: "27", countyFP: "009"},
    bigstonecounty: {stateFP: "27", countyFP: "011"},
    blueearthcounty: {stateFP: "27", countyFP: "013"},
    browncounty: {stateFP: "27", countyFP: "015"},
    carltoncounty: {stateFP: "27", countyFP: "017"},
    carvercounty: {stateFP: "27", countyFP: "019"},
    casscounty: {stateFP: "27", countyFP: "021"},
    chippewacounty: {stateFP: "27", countyFP: "023"},
    chisagocounty: {stateFP: "27", countyFP: "025"},
    claycounty: {stateFP: "27", countyFP: "027"},
    clearwatercounty: {stateFP: "27", countyFP: "029"},
    cookcounty: {stateFP: "27", countyFP: "031"},
    cottonwoodcounty: {stateFP: "27", countyFP: "033"},
    crowwingcounty: {stateFP: "27", countyFP: "035"},
    dakotacounty: {stateFP: "27", countyFP: "037"},
    dodgecounty: {stateFP: "27", countyFP: "039"},
    douglascounty: {stateFP: "27", countyFP: "041"},
    faribaultcounty: {stateFP: "27", countyFP: "043"},
    fillmorecounty: {stateFP: "27", countyFP: "045"},
    freeborncounty: {stateFP: "27", countyFP: "047"},
    goodhuecounty: {stateFP: "27", countyFP: "049"},
    grantcounty: {stateFP: "27", countyFP: "051"},
    hennepincounty: {stateFP: "27", countyFP: "053"},
    houstoncounty: {stateFP: "27", countyFP: "055"},
    hubbardcounty: {stateFP: "27", countyFP: "057"},
    isanticounty: {stateFP: "27", countyFP: "059"},
    itascacounty: {stateFP: "27", countyFP: "061"},
    jacksoncounty: {stateFP: "27", countyFP: "063"},
    kanabeccounty: {stateFP: "27", countyFP: "065"},
    kandiyohicounty: {stateFP: "27", countyFP: "067"},
    kittsoncounty: {stateFP: "27", countyFP: "069"},
    koochichingcounty: {stateFP: "27", countyFP: "071"},
    lacquiparlecounty: {stateFP: "27", countyFP: "073"},
    lakecounty: {stateFP: "27", countyFP: "075"},
    lakeofthewoodscounty: {stateFP: "27", countyFP: "077"},
    lesueurcounty: {stateFP: "27", countyFP: "079"},
    lincolncounty: {stateFP: "27", countyFP: "081"},
    lyoncounty: {stateFP: "27", countyFP: "083"},
    mcleodcounty: {stateFP: "27", countyFP: "085"},
    mahnomencounty: {stateFP: "27", countyFP: "087"},
    marshallcounty: {stateFP: "27", countyFP: "089"},
    martincounty: {stateFP: "27", countyFP: "091"},
    meekercounty: {stateFP: "27", countyFP: "093"},
    millelacscounty: {stateFP: "27", countyFP: "095"},
    morrisoncounty: {stateFP: "27", countyFP: "097"},
    mowercounty: {stateFP: "27", countyFP: "099"},
    murraycounty: {stateFP: "27", countyFP: "101"},
    nicolletcounty: {stateFP: "27", countyFP: "103"},
    noblescounty: {stateFP: "27", countyFP: "105"},
    normancounty: {stateFP: "27", countyFP: "107"},
    olmstedcounty: {stateFP: "27", countyFP: "109"},
    ottertailcounty: {stateFP: "27", countyFP: "111"},
    penningtoncounty: {stateFP: "27", countyFP: "113"},
    pinecounty: {stateFP: "27", countyFP: "115"},
    pipestonecounty: {stateFP: "27", countyFP: "117"},
    polkcounty: {stateFP: "27", countyFP: "119"},
    popecounty: {stateFP: "27", countyFP: "121"},
    ramseycounty: {stateFP: "27", countyFP: "123"},
    redlakecounty: {stateFP: "27", countyFP: "125"},
    redwoodcounty: {stateFP: "27", countyFP: "127"},
    renvillecounty: {stateFP: "27", countyFP: "129"},
    ricecounty: {stateFP: "27", countyFP: "131"},
    rockcounty: {stateFP: "27", countyFP: "133"},
    roseaucounty: {stateFP: "27", countyFP: "135"},
    stlouiscounty: {stateFP: "27", countyFP: "137"},
    scottcounty: {stateFP: "27", countyFP: "139"},
    sherburnecounty: {stateFP: "27", countyFP: "141"},
    sibleycounty: {stateFP: "27", countyFP: "143"},
    stearnscounty: {stateFP: "27", countyFP: "145"},
    steelecounty: {stateFP: "27", countyFP: "147"},
    stevenscounty: {stateFP: "27", countyFP: "149"},
    swiftcounty: {stateFP: "27", countyFP: "151"},
    toddcounty: {stateFP: "27", countyFP: "153"},
    traversecounty: {stateFP: "27", countyFP: "155"},
    wabashacounty: {stateFP: "27", countyFP: "157"},
    wadenacounty: {stateFP: "27", countyFP: "159"},
    wasecacounty: {stateFP: "27", countyFP: "161"},
    washingtoncounty: {stateFP: "27", countyFP: "163"},
    watonwancounty: {stateFP: "27", countyFP: "165"},
    wilkincounty: {stateFP: "27", countyFP: "167"},
    winonacounty: {stateFP: "27", countyFP: "169"},
    wrightcounty: {stateFP: "27", countyFP: "171"},
    yellowmedicinecounty: {stateFP: "27", countyFP: "173"},
  },
  mississippi: {
    adamscounty: {stateFP: "28", countyFP: "001"},
    alcorncounty: {stateFP: "28", countyFP: "003"},
    amitecounty: {stateFP: "28", countyFP: "005"},
    attalacounty: {stateFP: "28", countyFP: "007"},
    bentoncounty: {stateFP: "28", countyFP: "009"},
    bolivarcounty: {stateFP: "28", countyFP: "011"},
    calhouncounty: {stateFP: "28", countyFP: "013"},
    carrollcounty: {stateFP: "28", countyFP: "015"},
    chickasawcounty: {stateFP: "28", countyFP: "017"},
    choctawcounty: {stateFP: "28", countyFP: "019"},
    claibornecounty: {stateFP: "28", countyFP: "021"},
    clarkecounty: {stateFP: "28", countyFP: "023"},
    claycounty: {stateFP: "28", countyFP: "025"},
    coahomacounty: {stateFP: "28", countyFP: "027"},
    copiahcounty: {stateFP: "28", countyFP: "029"},
    covingtoncounty: {stateFP: "28", countyFP: "031"},
    desotocounty: {stateFP: "28", countyFP: "033"},
    forrestcounty: {stateFP: "28", countyFP: "035"},
    franklincounty: {stateFP: "28", countyFP: "037"},
    georgecounty: {stateFP: "28", countyFP: "039"},
    greenecounty: {stateFP: "28", countyFP: "041"},
    grenadacounty: {stateFP: "28", countyFP: "043"},
    hancockcounty: {stateFP: "28", countyFP: "045"},
    harrisoncounty: {stateFP: "28", countyFP: "047"},
    hindscounty: {stateFP: "28", countyFP: "049"},
    holmescounty: {stateFP: "28", countyFP: "051"},
    humphreyscounty: {stateFP: "28", countyFP: "053"},
    issaquenacounty: {stateFP: "28", countyFP: "055"},
    itawambacounty: {stateFP: "28", countyFP: "057"},
    jacksoncounty: {stateFP: "28", countyFP: "059"},
    jaspercounty: {stateFP: "28", countyFP: "061"},
    jeffersoncounty: {stateFP: "28", countyFP: "063"},
    jeffersondaviscounty: {stateFP: "28", countyFP: "065"},
    jonescounty: {stateFP: "28", countyFP: "067"},
    kempercounty: {stateFP: "28", countyFP: "069"},
    lafayettecounty: {stateFP: "28", countyFP: "071"},
    lamarcounty: {stateFP: "28", countyFP: "073"},
    lauderdalecounty: {stateFP: "28", countyFP: "075"},
    lawrencecounty: {stateFP: "28", countyFP: "077"},
    leakecounty: {stateFP: "28", countyFP: "079"},
    leecounty: {stateFP: "28", countyFP: "081"},
    leflorecounty: {stateFP: "28", countyFP: "083"},
    lincolncounty: {stateFP: "28", countyFP: "085"},
    lowndescounty: {stateFP: "28", countyFP: "087"},
    madisoncounty: {stateFP: "28", countyFP: "089"},
    marioncounty: {stateFP: "28", countyFP: "091"},
    marshallcounty: {stateFP: "28", countyFP: "093"},
    monroecounty: {stateFP: "28", countyFP: "095"},
    montgomerycounty: {stateFP: "28", countyFP: "097"},
    neshobacounty: {stateFP: "28", countyFP: "099"},
    newtoncounty: {stateFP: "28", countyFP: "101"},
    noxubeecounty: {stateFP: "28", countyFP: "103"},
    oktibbehacounty: {stateFP: "28", countyFP: "105"},
    panolacounty: {stateFP: "28", countyFP: "107"},
    pearlrivercounty: {stateFP: "28", countyFP: "109"},
    perrycounty: {stateFP: "28", countyFP: "111"},
    pikecounty: {stateFP: "28", countyFP: "113"},
    pontotoccounty: {stateFP: "28", countyFP: "115"},
    prentisscounty: {stateFP: "28", countyFP: "117"},
    quitmancounty: {stateFP: "28", countyFP: "119"},
    rankincounty: {stateFP: "28", countyFP: "121"},
    scottcounty: {stateFP: "28", countyFP: "123"},
    sharkeycounty: {stateFP: "28", countyFP: "125"},
    simpsoncounty: {stateFP: "28", countyFP: "127"},
    smithcounty: {stateFP: "28", countyFP: "129"},
    stonecounty: {stateFP: "28", countyFP: "131"},
    sunflowercounty: {stateFP: "28", countyFP: "133"},
    tallahatchiecounty: {stateFP: "28", countyFP: "135"},
    tatecounty: {stateFP: "28", countyFP: "137"},
    tippahcounty: {stateFP: "28", countyFP: "139"},
    tishomingocounty: {stateFP: "28", countyFP: "141"},
    tunicacounty: {stateFP: "28", countyFP: "143"},
    unioncounty: {stateFP: "28", countyFP: "145"},
    walthallcounty: {stateFP: "28", countyFP: "147"},
    warrencounty: {stateFP: "28", countyFP: "149"},
    washingtoncounty: {stateFP: "28", countyFP: "151"},
    waynecounty: {stateFP: "28", countyFP: "153"},
    webstercounty: {stateFP: "28", countyFP: "155"},
    wilkinsoncounty: {stateFP: "28", countyFP: "157"},
    winstoncounty: {stateFP: "28", countyFP: "159"},
    yalobushacounty: {stateFP: "28", countyFP: "161"},
    yazoocounty: {stateFP: "28", countyFP: "163"},
  },
  missouri: {
    adaircounty: {stateFP: "29", countyFP: "001"},
    andrewcounty: {stateFP: "29", countyFP: "003"},
    atchisoncounty: {stateFP: "29", countyFP: "005"},
    audraincounty: {stateFP: "29", countyFP: "007"},
    barrycounty: {stateFP: "29", countyFP: "009"},
    bartoncounty: {stateFP: "29", countyFP: "011"},
    batescounty: {stateFP: "29", countyFP: "013"},
    bentoncounty: {stateFP: "29", countyFP: "015"},
    bollingercounty: {stateFP: "29", countyFP: "017"},
    boonecounty: {stateFP: "29", countyFP: "019"},
    buchanancounty: {stateFP: "29", countyFP: "021"},
    butlercounty: {stateFP: "29", countyFP: "023"},
    caldwellcounty: {stateFP: "29", countyFP: "025"},
    callawaycounty: {stateFP: "29", countyFP: "027"},
    camdencounty: {stateFP: "29", countyFP: "029"},
    capegirardeaucounty: {stateFP: "29", countyFP: "031"},
    carrollcounty: {stateFP: "29", countyFP: "033"},
    cartercounty: {stateFP: "29", countyFP: "035"},
    casscounty: {stateFP: "29", countyFP: "037"},
    cedarcounty: {stateFP: "29", countyFP: "039"},
    charitoncounty: {stateFP: "29", countyFP: "041"},
    christiancounty: {stateFP: "29", countyFP: "043"},
    clarkcounty: {stateFP: "29", countyFP: "045"},
    claycounty: {stateFP: "29", countyFP: "047"},
    clintoncounty: {stateFP: "29", countyFP: "049"},
    colecounty: {stateFP: "29", countyFP: "051"},
    coopercounty: {stateFP: "29", countyFP: "053"},
    crawfordcounty: {stateFP: "29", countyFP: "055"},
    dadecounty: {stateFP: "29", countyFP: "057"},
    dallascounty: {stateFP: "29", countyFP: "059"},
    daviesscounty: {stateFP: "29", countyFP: "061"},
    dekalbcounty: {stateFP: "29", countyFP: "063"},
    dentcounty: {stateFP: "29", countyFP: "065"},
    douglascounty: {stateFP: "29", countyFP: "067"},
    dunklincounty: {stateFP: "29", countyFP: "069"},
    franklincounty: {stateFP: "29", countyFP: "071"},
    gasconadecounty: {stateFP: "29", countyFP: "073"},
    gentrycounty: {stateFP: "29", countyFP: "075"},
    greenecounty: {stateFP: "29", countyFP: "077"},
    grundycounty: {stateFP: "29", countyFP: "079"},
    harrisoncounty: {stateFP: "29", countyFP: "081"},
    henrycounty: {stateFP: "29", countyFP: "083"},
    hickorycounty: {stateFP: "29", countyFP: "085"},
    holtcounty: {stateFP: "29", countyFP: "087"},
    howardcounty: {stateFP: "29", countyFP: "089"},
    howellcounty: {stateFP: "29", countyFP: "091"},
    ironcounty: {stateFP: "29", countyFP: "093"},
    jacksoncounty: {stateFP: "29", countyFP: "095"},
    jaspercounty: {stateFP: "29", countyFP: "097"},
    jeffersoncounty: {stateFP: "29", countyFP: "099"},
    johnsoncounty: {stateFP: "29", countyFP: "101"},
    knoxcounty: {stateFP: "29", countyFP: "103"},
    lacledecounty: {stateFP: "29", countyFP: "105"},
    lafayettecounty: {stateFP: "29", countyFP: "107"},
    lawrencecounty: {stateFP: "29", countyFP: "109"},
    lewiscounty: {stateFP: "29", countyFP: "111"},
    lincolncounty: {stateFP: "29", countyFP: "113"},
    linncounty: {stateFP: "29", countyFP: "115"},
    livingstoncounty: {stateFP: "29", countyFP: "117"},
    mcdonaldcounty: {stateFP: "29", countyFP: "119"},
    maconcounty: {stateFP: "29", countyFP: "121"},
    madisoncounty: {stateFP: "29", countyFP: "123"},
    mariescounty: {stateFP: "29", countyFP: "125"},
    marioncounty: {stateFP: "29", countyFP: "127"},
    mercercounty: {stateFP: "29", countyFP: "129"},
    millercounty: {stateFP: "29", countyFP: "131"},
    mississippicounty: {stateFP: "29", countyFP: "133"},
    moniteaucounty: {stateFP: "29", countyFP: "135"},
    monroecounty: {stateFP: "29", countyFP: "137"},
    montgomerycounty: {stateFP: "29", countyFP: "139"},
    morgancounty: {stateFP: "29", countyFP: "141"},
    newmadridcounty: {stateFP: "29", countyFP: "143"},
    newtoncounty: {stateFP: "29", countyFP: "145"},
    nodawaycounty: {stateFP: "29", countyFP: "147"},
    oregoncounty: {stateFP: "29", countyFP: "149"},
    osagecounty: {stateFP: "29", countyFP: "151"},
    ozarkcounty: {stateFP: "29", countyFP: "153"},
    pemiscotcounty: {stateFP: "29", countyFP: "155"},
    perrycounty: {stateFP: "29", countyFP: "157"},
    pettiscounty: {stateFP: "29", countyFP: "159"},
    phelpscounty: {stateFP: "29", countyFP: "161"},
    pikecounty: {stateFP: "29", countyFP: "163"},
    plattecounty: {stateFP: "29", countyFP: "165"},
    polkcounty: {stateFP: "29", countyFP: "167"},
    pulaskicounty: {stateFP: "29", countyFP: "169"},
    putnamcounty: {stateFP: "29", countyFP: "171"},
    rallscounty: {stateFP: "29", countyFP: "173"},
    randolphcounty: {stateFP: "29", countyFP: "175"},
    raycounty: {stateFP: "29", countyFP: "177"},
    reynoldscounty: {stateFP: "29", countyFP: "179"},
    ripleycounty: {stateFP: "29", countyFP: "181"},
    stcharlescounty: {stateFP: "29", countyFP: "183"},
    stclaircounty: {stateFP: "29", countyFP: "185"},
    stegenevievecounty: {stateFP: "29", countyFP: "186"},
    stfrancoiscounty: {stateFP: "29", countyFP: "187"},
    stlouiscounty: {stateFP: "29", countyFP: "189"},
    salinecounty: {stateFP: "29", countyFP: "195"},
    schuylercounty: {stateFP: "29", countyFP: "197"},
    scotlandcounty: {stateFP: "29", countyFP: "199"},
    scottcounty: {stateFP: "29", countyFP: "201"},
    shannoncounty: {stateFP: "29", countyFP: "203"},
    shelbycounty: {stateFP: "29", countyFP: "205"},
    stoddardcounty: {stateFP: "29", countyFP: "207"},
    stonecounty: {stateFP: "29", countyFP: "209"},
    sullivancounty: {stateFP: "29", countyFP: "211"},
    taneycounty: {stateFP: "29", countyFP: "213"},
    texascounty: {stateFP: "29", countyFP: "215"},
    vernoncounty: {stateFP: "29", countyFP: "217"},
    warrencounty: {stateFP: "29", countyFP: "219"},
    washingtoncounty: {stateFP: "29", countyFP: "221"},
    waynecounty: {stateFP: "29", countyFP: "223"},
    webstercounty: {stateFP: "29", countyFP: "225"},
    worthcounty: {stateFP: "29", countyFP: "227"},
    wrightcounty: {stateFP: "29", countyFP: "229"},
    stlouiscity: {stateFP: "29", countyFP: "510"},
  },
  montana: {
    beaverheadcounty: {stateFP: "30", countyFP: "001"},
    bighorncounty: {stateFP: "30", countyFP: "003"},
    blainecounty: {stateFP: "30", countyFP: "005"},
    broadwatercounty: {stateFP: "30", countyFP: "007"},
    carboncounty: {stateFP: "30", countyFP: "009"},
    cartercounty: {stateFP: "30", countyFP: "011"},
    cascadecounty: {stateFP: "30", countyFP: "013"},
    chouteaucounty: {stateFP: "30", countyFP: "015"},
    custercounty: {stateFP: "30", countyFP: "017"},
    danielscounty: {stateFP: "30", countyFP: "019"},
    dawsoncounty: {stateFP: "30", countyFP: "021"},
    deerlodgecounty: {stateFP: "30", countyFP: "023"},
    falloncounty: {stateFP: "30", countyFP: "025"},
    ferguscounty: {stateFP: "30", countyFP: "027"},
    flatheadcounty: {stateFP: "30", countyFP: "029"},
    gallatincounty: {stateFP: "30", countyFP: "031"},
    garfieldcounty: {stateFP: "30", countyFP: "033"},
    glaciercounty: {stateFP: "30", countyFP: "035"},
    goldenvalleycounty: {stateFP: "30", countyFP: "037"},
    granitecounty: {stateFP: "30", countyFP: "039"},
    hillcounty: {stateFP: "30", countyFP: "041"},
    jeffersoncounty: {stateFP: "30", countyFP: "043"},
    judithbasincounty: {stateFP: "30", countyFP: "045"},
    lakecounty: {stateFP: "30", countyFP: "047"},
    lewisandclarkcounty: {stateFP: "30", countyFP: "049"},
    libertycounty: {stateFP: "30", countyFP: "051"},
    lincolncounty: {stateFP: "30", countyFP: "053"},
    mcconecounty: {stateFP: "30", countyFP: "055"},
    madisoncounty: {stateFP: "30", countyFP: "057"},
    meaghercounty: {stateFP: "30", countyFP: "059"},
    mineralcounty: {stateFP: "30", countyFP: "061"},
    missoulacounty: {stateFP: "30", countyFP: "063"},
    musselshellcounty: {stateFP: "30", countyFP: "065"},
    parkcounty: {stateFP: "30", countyFP: "067"},
    petroleumcounty: {stateFP: "30", countyFP: "069"},
    phillipscounty: {stateFP: "30", countyFP: "071"},
    ponderacounty: {stateFP: "30", countyFP: "073"},
    powderrivercounty: {stateFP: "30", countyFP: "075"},
    powellcounty: {stateFP: "30", countyFP: "077"},
    prairiecounty: {stateFP: "30", countyFP: "079"},
    ravallicounty: {stateFP: "30", countyFP: "081"},
    richlandcounty: {stateFP: "30", countyFP: "083"},
    rooseveltcounty: {stateFP: "30", countyFP: "085"},
    rosebudcounty: {stateFP: "30", countyFP: "087"},
    sanderscounty: {stateFP: "30", countyFP: "089"},
    sheridancounty: {stateFP: "30", countyFP: "091"},
    silverbowcounty: {stateFP: "30", countyFP: "093"},
    stillwatercounty: {stateFP: "30", countyFP: "095"},
    sweetgrasscounty: {stateFP: "30", countyFP: "097"},
    tetoncounty: {stateFP: "30", countyFP: "099"},
    toolecounty: {stateFP: "30", countyFP: "101"},
    treasurecounty: {stateFP: "30", countyFP: "103"},
    valleycounty: {stateFP: "30", countyFP: "105"},
    wheatlandcounty: {stateFP: "30", countyFP: "107"},
    wibauxcounty: {stateFP: "30", countyFP: "109"},
    yellowstonecounty: {stateFP: "30", countyFP: "111"},
  },
  nebraska: {
    adamscounty: {stateFP: "31", countyFP: "001"},
    antelopecounty: {stateFP: "31", countyFP: "003"},
    arthurcounty: {stateFP: "31", countyFP: "005"},
    bannercounty: {stateFP: "31", countyFP: "007"},
    blainecounty: {stateFP: "31", countyFP: "009"},
    boonecounty: {stateFP: "31", countyFP: "011"},
    boxbuttecounty: {stateFP: "31", countyFP: "013"},
    boydcounty: {stateFP: "31", countyFP: "015"},
    browncounty: {stateFP: "31", countyFP: "017"},
    buffalocounty: {stateFP: "31", countyFP: "019"},
    burtcounty: {stateFP: "31", countyFP: "021"},
    butlercounty: {stateFP: "31", countyFP: "023"},
    casscounty: {stateFP: "31", countyFP: "025"},
    cedarcounty: {stateFP: "31", countyFP: "027"},
    chasecounty: {stateFP: "31", countyFP: "029"},
    cherrycounty: {stateFP: "31", countyFP: "031"},
    cheyennecounty: {stateFP: "31", countyFP: "033"},
    claycounty: {stateFP: "31", countyFP: "035"},
    colfaxcounty: {stateFP: "31", countyFP: "037"},
    cumingcounty: {stateFP: "31", countyFP: "039"},
    custercounty: {stateFP: "31", countyFP: "041"},
    dakotacounty: {stateFP: "31", countyFP: "043"},
    dawescounty: {stateFP: "31", countyFP: "045"},
    dawsoncounty: {stateFP: "31", countyFP: "047"},
    deuelcounty: {stateFP: "31", countyFP: "049"},
    dixoncounty: {stateFP: "31", countyFP: "051"},
    dodgecounty: {stateFP: "31", countyFP: "053"},
    douglascounty: {stateFP: "31", countyFP: "055"},
    dundycounty: {stateFP: "31", countyFP: "057"},
    fillmorecounty: {stateFP: "31", countyFP: "059"},
    franklincounty: {stateFP: "31", countyFP: "061"},
    frontiercounty: {stateFP: "31", countyFP: "063"},
    furnascounty: {stateFP: "31", countyFP: "065"},
    gagecounty: {stateFP: "31", countyFP: "067"},
    gardencounty: {stateFP: "31", countyFP: "069"},
    garfieldcounty: {stateFP: "31", countyFP: "071"},
    gospercounty: {stateFP: "31", countyFP: "073"},
    grantcounty: {stateFP: "31", countyFP: "075"},
    greeleycounty: {stateFP: "31", countyFP: "077"},
    hallcounty: {stateFP: "31", countyFP: "079"},
    hamiltoncounty: {stateFP: "31", countyFP: "081"},
    harlancounty: {stateFP: "31", countyFP: "083"},
    hayescounty: {stateFP: "31", countyFP: "085"},
    hitchcockcounty: {stateFP: "31", countyFP: "087"},
    holtcounty: {stateFP: "31", countyFP: "089"},
    hookercounty: {stateFP: "31", countyFP: "091"},
    howardcounty: {stateFP: "31", countyFP: "093"},
    jeffersoncounty: {stateFP: "31", countyFP: "095"},
    johnsoncounty: {stateFP: "31", countyFP: "097"},
    kearneycounty: {stateFP: "31", countyFP: "099"},
    keithcounty: {stateFP: "31", countyFP: "101"},
    keyapahacounty: {stateFP: "31", countyFP: "103"},
    kimballcounty: {stateFP: "31", countyFP: "105"},
    knoxcounty: {stateFP: "31", countyFP: "107"},
    lancastercounty: {stateFP: "31", countyFP: "109"},
    lincolncounty: {stateFP: "31", countyFP: "111"},
    logancounty: {stateFP: "31", countyFP: "113"},
    loupcounty: {stateFP: "31", countyFP: "115"},
    mcphersoncounty: {stateFP: "31", countyFP: "117"},
    madisoncounty: {stateFP: "31", countyFP: "119"},
    merrickcounty: {stateFP: "31", countyFP: "121"},
    morrillcounty: {stateFP: "31", countyFP: "123"},
    nancecounty: {stateFP: "31", countyFP: "125"},
    nemahacounty: {stateFP: "31", countyFP: "127"},
    nuckollscounty: {stateFP: "31", countyFP: "129"},
    otoecounty: {stateFP: "31", countyFP: "131"},
    pawneecounty: {stateFP: "31", countyFP: "133"},
    perkinscounty: {stateFP: "31", countyFP: "135"},
    phelpscounty: {stateFP: "31", countyFP: "137"},
    piercecounty: {stateFP: "31", countyFP: "139"},
    plattecounty: {stateFP: "31", countyFP: "141"},
    polkcounty: {stateFP: "31", countyFP: "143"},
    redwillowcounty: {stateFP: "31", countyFP: "145"},
    richardsoncounty: {stateFP: "31", countyFP: "147"},
    rockcounty: {stateFP: "31", countyFP: "149"},
    salinecounty: {stateFP: "31", countyFP: "151"},
    sarpycounty: {stateFP: "31", countyFP: "153"},
    saunderscounty: {stateFP: "31", countyFP: "155"},
    scottsbluffcounty: {stateFP: "31", countyFP: "157"},
    sewardcounty: {stateFP: "31", countyFP: "159"},
    sheridancounty: {stateFP: "31", countyFP: "161"},
    shermancounty: {stateFP: "31", countyFP: "163"},
    siouxcounty: {stateFP: "31", countyFP: "165"},
    stantoncounty: {stateFP: "31", countyFP: "167"},
    thayercounty: {stateFP: "31", countyFP: "169"},
    thomascounty: {stateFP: "31", countyFP: "171"},
    thurstoncounty: {stateFP: "31", countyFP: "173"},
    valleycounty: {stateFP: "31", countyFP: "175"},
    washingtoncounty: {stateFP: "31", countyFP: "177"},
    waynecounty: {stateFP: "31", countyFP: "179"},
    webstercounty: {stateFP: "31", countyFP: "181"},
    wheelercounty: {stateFP: "31", countyFP: "183"},
    yorkcounty: {stateFP: "31", countyFP: "185"},
  },
  nevada: {
    churchillcounty: {stateFP: "32", countyFP: "001"},
    clarkcounty: {stateFP: "32", countyFP: "003"},
    douglascounty: {stateFP: "32", countyFP: "005"},
    elkocounty: {stateFP: "32", countyFP: "007"},
    esmeraldacounty: {stateFP: "32", countyFP: "009"},
    eurekacounty: {stateFP: "32", countyFP: "011"},
    humboldtcounty: {stateFP: "32", countyFP: "013"},
    landercounty: {stateFP: "32", countyFP: "015"},
    lincolncounty: {stateFP: "32", countyFP: "017"},
    lyoncounty: {stateFP: "32", countyFP: "019"},
    mineralcounty: {stateFP: "32", countyFP: "021"},
    nyecounty: {stateFP: "32", countyFP: "023"},
    pershingcounty: {stateFP: "32", countyFP: "027"},
    storeycounty: {stateFP: "32", countyFP: "029"},
    washoecounty: {stateFP: "32", countyFP: "031"},
    whitepinecounty: {stateFP: "32", countyFP: "033"},
    carsoncity: {stateFP: "32", countyFP: "510"},
  },
  newhampshire: {
    belknapcounty: {stateFP: "33", countyFP: "001"},
    carrollcounty: {stateFP: "33", countyFP: "003"},
    cheshirecounty: {stateFP: "33", countyFP: "005"},
    cooscounty: {stateFP: "33", countyFP: "007"},
    graftoncounty: {stateFP: "33", countyFP: "009"},
    hillsboroughcounty: {stateFP: "33", countyFP: "011"},
    merrimackcounty: {stateFP: "33", countyFP: "013"},
    rockinghamcounty: {stateFP: "33", countyFP: "015"},
    straffordcounty: {stateFP: "33", countyFP: "017"},
    sullivancounty: {stateFP: "33", countyFP: "019"},
  },
  newjersey: {
    atlanticcounty: {stateFP: "34", countyFP: "001"},
    bergencounty: {stateFP: "34", countyFP: "003"},
    burlingtoncounty: {stateFP: "34", countyFP: "005"},
    camdencounty: {stateFP: "34", countyFP: "007"},
    capemaycounty: {stateFP: "34", countyFP: "009"},
    cumberlandcounty: {stateFP: "34", countyFP: "011"},
    essexcounty: {stateFP: "34", countyFP: "013"},
    gloucestercounty: {stateFP: "34", countyFP: "015"},
    hudsoncounty: {stateFP: "34", countyFP: "017"},
    hunterdoncounty: {stateFP: "34", countyFP: "019"},
    mercercounty: {stateFP: "34", countyFP: "021"},
    middlesexcounty: {stateFP: "34", countyFP: "023"},
    monmouthcounty: {stateFP: "34", countyFP: "025"},
    morriscounty: {stateFP: "34", countyFP: "027"},
    oceancounty: {stateFP: "34", countyFP: "029"},
    passaiccounty: {stateFP: "34", countyFP: "031"},
    salemcounty: {stateFP: "34", countyFP: "033"},
    somersetcounty: {stateFP: "34", countyFP: "035"},
    sussexcounty: {stateFP: "34", countyFP: "037"},
    unioncounty: {stateFP: "34", countyFP: "039"},
    warrencounty: {stateFP: "34", countyFP: "041"},
  },
  newmexico: {
    bernalillocounty: {stateFP: "35", countyFP: "001"},
    catroncounty: {stateFP: "35", countyFP: "003"},
    chavescounty: {stateFP: "35", countyFP: "005"},
    cibolacounty: {stateFP: "35", countyFP: "006"},
    colfaxcounty: {stateFP: "35", countyFP: "007"},
    currycounty: {stateFP: "35", countyFP: "009"},
    debacacounty: {stateFP: "35", countyFP: "011"},
    // TODO: standardize this county name
    // doã±aanacounty: { stateFP: "35", countyFP: "013" },
    eddycounty: {stateFP: "35", countyFP: "015"},
    grantcounty: {stateFP: "35", countyFP: "017"},
    guadalupecounty: {stateFP: "35", countyFP: "019"},
    hardingcounty: {stateFP: "35", countyFP: "021"},
    hidalgocounty: {stateFP: "35", countyFP: "023"},
    leacounty: {stateFP: "35", countyFP: "025"},
    lincolncounty: {stateFP: "35", countyFP: "027"},
    losalamoscounty: {stateFP: "35", countyFP: "028"},
    lunacounty: {stateFP: "35", countyFP: "029"},
    mckinleycounty: {stateFP: "35", countyFP: "031"},
    moracounty: {stateFP: "35", countyFP: "033"},
    oterocounty: {stateFP: "35", countyFP: "035"},
    quaycounty: {stateFP: "35", countyFP: "037"},
    rioarribacounty: {stateFP: "35", countyFP: "039"},
    rooseveltcounty: {stateFP: "35", countyFP: "041"},
    sandovalcounty: {stateFP: "35", countyFP: "043"},
    sanjuancounty: {stateFP: "35", countyFP: "045"},
    sanmiguelcounty: {stateFP: "35", countyFP: "047"},
    santafecounty: {stateFP: "35", countyFP: "049"},
    sierracounty: {stateFP: "35", countyFP: "051"},
    socorrocounty: {stateFP: "35", countyFP: "053"},
    taoscounty: {stateFP: "35", countyFP: "055"},
    torrancecounty: {stateFP: "35", countyFP: "057"},
    unioncounty: {stateFP: "35", countyFP: "059"},
    valenciacounty: {stateFP: "35", countyFP: "061"},
  },
  newyork: {
    albanycounty: {stateFP: "36", countyFP: "001"},
    alleganycounty: {stateFP: "36", countyFP: "003"},
    bronxcounty: {stateFP: "36", countyFP: "005"},
    broomecounty: {stateFP: "36", countyFP: "007"},
    cattarauguscounty: {stateFP: "36", countyFP: "009"},
    cayugacounty: {stateFP: "36", countyFP: "011"},
    chautauquacounty: {stateFP: "36", countyFP: "013"},
    chemungcounty: {stateFP: "36", countyFP: "015"},
    chenangocounty: {stateFP: "36", countyFP: "017"},
    clintoncounty: {stateFP: "36", countyFP: "019"},
    columbiacounty: {stateFP: "36", countyFP: "021"},
    cortlandcounty: {stateFP: "36", countyFP: "023"},
    delawarecounty: {stateFP: "36", countyFP: "025"},
    dutchesscounty: {stateFP: "36", countyFP: "027"},
    eriecounty: {stateFP: "36", countyFP: "029"},
    essexcounty: {stateFP: "36", countyFP: "031"},
    franklincounty: {stateFP: "36", countyFP: "033"},
    fultoncounty: {stateFP: "36", countyFP: "035"},
    geneseecounty: {stateFP: "36", countyFP: "037"},
    greenecounty: {stateFP: "36", countyFP: "039"},
    hamiltoncounty: {stateFP: "36", countyFP: "041"},
    herkimercounty: {stateFP: "36", countyFP: "043"},
    jeffersoncounty: {stateFP: "36", countyFP: "045"},
    kingscounty: {stateFP: "36", countyFP: "047"},
    lewiscounty: {stateFP: "36", countyFP: "049"},
    livingstoncounty: {stateFP: "36", countyFP: "051"},
    madisoncounty: {stateFP: "36", countyFP: "053"},
    monroecounty: {stateFP: "36", countyFP: "055"},
    montgomerycounty: {stateFP: "36", countyFP: "057"},
    nassaucounty: {stateFP: "36", countyFP: "059"},
    newyorkcounty: {stateFP: "36", countyFP: "061"},
    niagaracounty: {stateFP: "36", countyFP: "063"},
    oneidacounty: {stateFP: "36", countyFP: "065"},
    onondagacounty: {stateFP: "36", countyFP: "067"},
    ontariocounty: {stateFP: "36", countyFP: "069"},
    orangecounty: {stateFP: "36", countyFP: "071"},
    orleanscounty: {stateFP: "36", countyFP: "073"},
    oswegocounty: {stateFP: "36", countyFP: "075"},
    otsegocounty: {stateFP: "36", countyFP: "077"},
    putnamcounty: {stateFP: "36", countyFP: "079"},
    queenscounty: {stateFP: "36", countyFP: "081"},
    rensselaercounty: {stateFP: "36", countyFP: "083"},
    richmondcounty: {stateFP: "36", countyFP: "085"},
    rocklandcounty: {stateFP: "36", countyFP: "087"},
    stlawrencecounty: {stateFP: "36", countyFP: "089"},
    saratogacounty: {stateFP: "36", countyFP: "091"},
    schenectadycounty: {stateFP: "36", countyFP: "093"},
    schohariecounty: {stateFP: "36", countyFP: "095"},
    schuylercounty: {stateFP: "36", countyFP: "097"},
    senecacounty: {stateFP: "36", countyFP: "099"},
    steubencounty: {stateFP: "36", countyFP: "101"},
    suffolkcounty: {stateFP: "36", countyFP: "103"},
    sullivancounty: {stateFP: "36", countyFP: "105"},
    tiogacounty: {stateFP: "36", countyFP: "107"},
    tompkinscounty: {stateFP: "36", countyFP: "109"},
    ulstercounty: {stateFP: "36", countyFP: "111"},
    warrencounty: {stateFP: "36", countyFP: "113"},
    washingtoncounty: {stateFP: "36", countyFP: "115"},
    waynecounty: {stateFP: "36", countyFP: "117"},
    westchestercounty: {stateFP: "36", countyFP: "119"},
    wyomingcounty: {stateFP: "36", countyFP: "121"},
    yatescounty: {stateFP: "36", countyFP: "123"},
  },
  northcarolina: {
    alamancecounty: {stateFP: "37", countyFP: "001"},
    alexandercounty: {stateFP: "37", countyFP: "003"},
    alleghanycounty: {stateFP: "37", countyFP: "005"},
    ansoncounty: {stateFP: "37", countyFP: "007"},
    ashecounty: {stateFP: "37", countyFP: "009"},
    averycounty: {stateFP: "37", countyFP: "011"},
    beaufortcounty: {stateFP: "37", countyFP: "013"},
    bertiecounty: {stateFP: "37", countyFP: "015"},
    bladencounty: {stateFP: "37", countyFP: "017"},
    brunswickcounty: {stateFP: "37", countyFP: "019"},
    buncombecounty: {stateFP: "37", countyFP: "021"},
    burkecounty: {stateFP: "37", countyFP: "023"},
    cabarruscounty: {stateFP: "37", countyFP: "025"},
    caldwellcounty: {stateFP: "37", countyFP: "027"},
    camdencounty: {stateFP: "37", countyFP: "029"},
    carteretcounty: {stateFP: "37", countyFP: "031"},
    caswellcounty: {stateFP: "37", countyFP: "033"},
    catawbacounty: {stateFP: "37", countyFP: "035"},
    chathamcounty: {stateFP: "37", countyFP: "037"},
    cherokeecounty: {stateFP: "37", countyFP: "039"},
    chowancounty: {stateFP: "37", countyFP: "041"},
    claycounty: {stateFP: "37", countyFP: "043"},
    clevelandcounty: {stateFP: "37", countyFP: "045"},
    columbuscounty: {stateFP: "37", countyFP: "047"},
    cravencounty: {stateFP: "37", countyFP: "049"},
    cumberlandcounty: {stateFP: "37", countyFP: "051"},
    currituckcounty: {stateFP: "37", countyFP: "053"},
    darecounty: {stateFP: "37", countyFP: "055"},
    davidsoncounty: {stateFP: "37", countyFP: "057"},
    daviecounty: {stateFP: "37", countyFP: "059"},
    duplincounty: {stateFP: "37", countyFP: "061"},
    durhamcounty: {stateFP: "37", countyFP: "063"},
    edgecombecounty: {stateFP: "37", countyFP: "065"},
    forsythcounty: {stateFP: "37", countyFP: "067"},
    franklincounty: {stateFP: "37", countyFP: "069"},
    gastoncounty: {stateFP: "37", countyFP: "071"},
    gatescounty: {stateFP: "37", countyFP: "073"},
    grahamcounty: {stateFP: "37", countyFP: "075"},
    granvillecounty: {stateFP: "37", countyFP: "077"},
    greenecounty: {stateFP: "37", countyFP: "079"},
    guilfordcounty: {stateFP: "37", countyFP: "081"},
    halifaxcounty: {stateFP: "37", countyFP: "083"},
    harnettcounty: {stateFP: "37", countyFP: "085"},
    haywoodcounty: {stateFP: "37", countyFP: "087"},
    hendersoncounty: {stateFP: "37", countyFP: "089"},
    hertfordcounty: {stateFP: "37", countyFP: "091"},
    hokecounty: {stateFP: "37", countyFP: "093"},
    hydecounty: {stateFP: "37", countyFP: "095"},
    iredellcounty: {stateFP: "37", countyFP: "097"},
    jacksoncounty: {stateFP: "37", countyFP: "099"},
    johnstoncounty: {stateFP: "37", countyFP: "101"},
    jonescounty: {stateFP: "37", countyFP: "103"},
    leecounty: {stateFP: "37", countyFP: "105"},
    lenoircounty: {stateFP: "37", countyFP: "107"},
    lincolncounty: {stateFP: "37", countyFP: "109"},
    mcdowellcounty: {stateFP: "37", countyFP: "111"},
    maconcounty: {stateFP: "37", countyFP: "113"},
    madisoncounty: {stateFP: "37", countyFP: "115"},
    martincounty: {stateFP: "37", countyFP: "117"},
    mecklenburgcounty: {stateFP: "37", countyFP: "119"},
    mitchellcounty: {stateFP: "37", countyFP: "121"},
    montgomerycounty: {stateFP: "37", countyFP: "123"},
    moorecounty: {stateFP: "37", countyFP: "125"},
    nashcounty: {stateFP: "37", countyFP: "127"},
    newhanovercounty: {stateFP: "37", countyFP: "129"},
    northamptoncounty: {stateFP: "37", countyFP: "131"},
    onslowcounty: {stateFP: "37", countyFP: "133"},
    orangecounty: {stateFP: "37", countyFP: "135"},
    pamlicocounty: {stateFP: "37", countyFP: "137"},
    pasquotankcounty: {stateFP: "37", countyFP: "139"},
    pendercounty: {stateFP: "37", countyFP: "141"},
    perquimanscounty: {stateFP: "37", countyFP: "143"},
    personcounty: {stateFP: "37", countyFP: "145"},
    pittcounty: {stateFP: "37", countyFP: "147"},
    polkcounty: {stateFP: "37", countyFP: "149"},
    randolphcounty: {stateFP: "37", countyFP: "151"},
    richmondcounty: {stateFP: "37", countyFP: "153"},
    robesoncounty: {stateFP: "37", countyFP: "155"},
    rockinghamcounty: {stateFP: "37", countyFP: "157"},
    rowancounty: {stateFP: "37", countyFP: "159"},
    rutherfordcounty: {stateFP: "37", countyFP: "161"},
    sampsoncounty: {stateFP: "37", countyFP: "163"},
    scotlandcounty: {stateFP: "37", countyFP: "165"},
    stanlycounty: {stateFP: "37", countyFP: "167"},
    stokescounty: {stateFP: "37", countyFP: "169"},
    surrycounty: {stateFP: "37", countyFP: "171"},
    swaincounty: {stateFP: "37", countyFP: "173"},
    transylvaniacounty: {stateFP: "37", countyFP: "175"},
    tyrrellcounty: {stateFP: "37", countyFP: "177"},
    unioncounty: {stateFP: "37", countyFP: "179"},
    vancecounty: {stateFP: "37", countyFP: "181"},
    wakecounty: {stateFP: "37", countyFP: "183"},
    warrencounty: {stateFP: "37", countyFP: "185"},
    washingtoncounty: {stateFP: "37", countyFP: "187"},
    wataugacounty: {stateFP: "37", countyFP: "189"},
    waynecounty: {stateFP: "37", countyFP: "191"},
    wilkescounty: {stateFP: "37", countyFP: "193"},
    wilsoncounty: {stateFP: "37", countyFP: "195"},
    yadkincounty: {stateFP: "37", countyFP: "197"},
    yanceycounty: {stateFP: "37", countyFP: "199"},
  },
  northdakota: {
    adamscounty: {stateFP: "38", countyFP: "001"},
    barnescounty: {stateFP: "38", countyFP: "003"},
    bensoncounty: {stateFP: "38", countyFP: "005"},
    billingscounty: {stateFP: "38", countyFP: "007"},
    bottineaucounty: {stateFP: "38", countyFP: "009"},
    bowmancounty: {stateFP: "38", countyFP: "011"},
    burkecounty: {stateFP: "38", countyFP: "013"},
    burleighcounty: {stateFP: "38", countyFP: "015"},
    casscounty: {stateFP: "38", countyFP: "017"},
    cavaliercounty: {stateFP: "38", countyFP: "019"},
    dickeycounty: {stateFP: "38", countyFP: "021"},
    dividecounty: {stateFP: "38", countyFP: "023"},
    dunncounty: {stateFP: "38", countyFP: "025"},
    eddycounty: {stateFP: "38", countyFP: "027"},
    emmonscounty: {stateFP: "38", countyFP: "029"},
    fostercounty: {stateFP: "38", countyFP: "031"},
    goldenvalleycounty: {stateFP: "38", countyFP: "033"},
    grandforkscounty: {stateFP: "38", countyFP: "035"},
    grantcounty: {stateFP: "38", countyFP: "037"},
    griggscounty: {stateFP: "38", countyFP: "039"},
    hettingercounty: {stateFP: "38", countyFP: "041"},
    kiddercounty: {stateFP: "38", countyFP: "043"},
    lamourecounty: {stateFP: "38", countyFP: "045"},
    logancounty: {stateFP: "38", countyFP: "047"},
    mchenrycounty: {stateFP: "38", countyFP: "049"},
    mcintoshcounty: {stateFP: "38", countyFP: "051"},
    mckenziecounty: {stateFP: "38", countyFP: "053"},
    mcleancounty: {stateFP: "38", countyFP: "055"},
    mercercounty: {stateFP: "38", countyFP: "057"},
    mortoncounty: {stateFP: "38", countyFP: "059"},
    mountrailcounty: {stateFP: "38", countyFP: "061"},
    nelsoncounty: {stateFP: "38", countyFP: "063"},
    olivercounty: {stateFP: "38", countyFP: "065"},
    pembinacounty: {stateFP: "38", countyFP: "067"},
    piercecounty: {stateFP: "38", countyFP: "069"},
    ramseycounty: {stateFP: "38", countyFP: "071"},
    ransomcounty: {stateFP: "38", countyFP: "073"},
    renvillecounty: {stateFP: "38", countyFP: "075"},
    richlandcounty: {stateFP: "38", countyFP: "077"},
    rolettecounty: {stateFP: "38", countyFP: "079"},
    sargentcounty: {stateFP: "38", countyFP: "081"},
    sheridancounty: {stateFP: "38", countyFP: "083"},
    siouxcounty: {stateFP: "38", countyFP: "085"},
    slopecounty: {stateFP: "38", countyFP: "087"},
    starkcounty: {stateFP: "38", countyFP: "089"},
    steelecounty: {stateFP: "38", countyFP: "091"},
    stutsmancounty: {stateFP: "38", countyFP: "093"},
    townercounty: {stateFP: "38", countyFP: "095"},
    traillcounty: {stateFP: "38", countyFP: "097"},
    walshcounty: {stateFP: "38", countyFP: "099"},
    wardcounty: {stateFP: "38", countyFP: "101"},
    wellscounty: {stateFP: "38", countyFP: "103"},
    williamscounty: {stateFP: "38", countyFP: "105"},
  },
  ohio: {
    adamscounty: {stateFP: "39", countyFP: "001"},
    allencounty: {stateFP: "39", countyFP: "003"},
    ashlandcounty: {stateFP: "39", countyFP: "005"},
    ashtabulacounty: {stateFP: "39", countyFP: "007"},
    athenscounty: {stateFP: "39", countyFP: "009"},
    auglaizecounty: {stateFP: "39", countyFP: "011"},
    belmontcounty: {stateFP: "39", countyFP: "013"},
    browncounty: {stateFP: "39", countyFP: "015"},
    butlercounty: {stateFP: "39", countyFP: "017"},
    carrollcounty: {stateFP: "39", countyFP: "019"},
    champaigncounty: {stateFP: "39", countyFP: "021"},
    clarkcounty: {stateFP: "39", countyFP: "023"},
    clermontcounty: {stateFP: "39", countyFP: "025"},
    clintoncounty: {stateFP: "39", countyFP: "027"},
    columbianacounty: {stateFP: "39", countyFP: "029"},
    coshoctoncounty: {stateFP: "39", countyFP: "031"},
    crawfordcounty: {stateFP: "39", countyFP: "033"},
    cuyahogacounty: {stateFP: "39", countyFP: "035"},
    darkecounty: {stateFP: "39", countyFP: "037"},
    defiancecounty: {stateFP: "39", countyFP: "039"},
    delawarecounty: {stateFP: "39", countyFP: "041"},
    eriecounty: {stateFP: "39", countyFP: "043"},
    fairfieldcounty: {stateFP: "39", countyFP: "045"},
    fayettecounty: {stateFP: "39", countyFP: "047"},
    franklincounty: {stateFP: "39", countyFP: "049"},
    fultoncounty: {stateFP: "39", countyFP: "051"},
    galliacounty: {stateFP: "39", countyFP: "053"},
    geaugacounty: {stateFP: "39", countyFP: "055"},
    greenecounty: {stateFP: "39", countyFP: "057"},
    guernseycounty: {stateFP: "39", countyFP: "059"},
    hamiltoncounty: {stateFP: "39", countyFP: "061"},
    hancockcounty: {stateFP: "39", countyFP: "063"},
    hardincounty: {stateFP: "39", countyFP: "065"},
    harrisoncounty: {stateFP: "39", countyFP: "067"},
    henrycounty: {stateFP: "39", countyFP: "069"},
    highlandcounty: {stateFP: "39", countyFP: "071"},
    hockingcounty: {stateFP: "39", countyFP: "073"},
    holmescounty: {stateFP: "39", countyFP: "075"},
    huroncounty: {stateFP: "39", countyFP: "077"},
    jacksoncounty: {stateFP: "39", countyFP: "079"},
    jeffersoncounty: {stateFP: "39", countyFP: "081"},
    knoxcounty: {stateFP: "39", countyFP: "083"},
    lakecounty: {stateFP: "39", countyFP: "085"},
    lawrencecounty: {stateFP: "39", countyFP: "087"},
    lickingcounty: {stateFP: "39", countyFP: "089"},
    logancounty: {stateFP: "39", countyFP: "091"},
    loraincounty: {stateFP: "39", countyFP: "093"},
    lucascounty: {stateFP: "39", countyFP: "095"},
    madisoncounty: {stateFP: "39", countyFP: "097"},
    mahoningcounty: {stateFP: "39", countyFP: "099"},
    marioncounty: {stateFP: "39", countyFP: "101"},
    medinacounty: {stateFP: "39", countyFP: "103"},
    meigscounty: {stateFP: "39", countyFP: "105"},
    mercercounty: {stateFP: "39", countyFP: "107"},
    miamicounty: {stateFP: "39", countyFP: "109"},
    monroecounty: {stateFP: "39", countyFP: "111"},
    montgomerycounty: {stateFP: "39", countyFP: "113"},
    morgancounty: {stateFP: "39", countyFP: "115"},
    morrowcounty: {stateFP: "39", countyFP: "117"},
    muskingumcounty: {stateFP: "39", countyFP: "119"},
    noblecounty: {stateFP: "39", countyFP: "121"},
    ottawacounty: {stateFP: "39", countyFP: "123"},
    pauldingcounty: {stateFP: "39", countyFP: "125"},
    perrycounty: {stateFP: "39", countyFP: "127"},
    pickawaycounty: {stateFP: "39", countyFP: "129"},
    pikecounty: {stateFP: "39", countyFP: "131"},
    portagecounty: {stateFP: "39", countyFP: "133"},
    preblecounty: {stateFP: "39", countyFP: "135"},
    putnamcounty: {stateFP: "39", countyFP: "137"},
    richlandcounty: {stateFP: "39", countyFP: "139"},
    rosscounty: {stateFP: "39", countyFP: "141"},
    sanduskycounty: {stateFP: "39", countyFP: "143"},
    sciotocounty: {stateFP: "39", countyFP: "145"},
    senecacounty: {stateFP: "39", countyFP: "147"},
    shelbycounty: {stateFP: "39", countyFP: "149"},
    starkcounty: {stateFP: "39", countyFP: "151"},
    summitcounty: {stateFP: "39", countyFP: "153"},
    trumbullcounty: {stateFP: "39", countyFP: "155"},
    tuscarawascounty: {stateFP: "39", countyFP: "157"},
    unioncounty: {stateFP: "39", countyFP: "159"},
    vanwertcounty: {stateFP: "39", countyFP: "161"},
    vintoncounty: {stateFP: "39", countyFP: "163"},
    warrencounty: {stateFP: "39", countyFP: "165"},
    washingtoncounty: {stateFP: "39", countyFP: "167"},
    waynecounty: {stateFP: "39", countyFP: "169"},
    williamscounty: {stateFP: "39", countyFP: "171"},
    woodcounty: {stateFP: "39", countyFP: "173"},
    wyandotcounty: {stateFP: "39", countyFP: "175"},
  },
  oklahoma: {
    adaircounty: {stateFP: "40", countyFP: "001"},
    alfalfacounty: {stateFP: "40", countyFP: "003"},
    atokacounty: {stateFP: "40", countyFP: "005"},
    beavercounty: {stateFP: "40", countyFP: "007"},
    beckhamcounty: {stateFP: "40", countyFP: "009"},
    blainecounty: {stateFP: "40", countyFP: "011"},
    bryancounty: {stateFP: "40", countyFP: "013"},
    caddocounty: {stateFP: "40", countyFP: "015"},
    canadiancounty: {stateFP: "40", countyFP: "017"},
    cartercounty: {stateFP: "40", countyFP: "019"},
    cherokeecounty: {stateFP: "40", countyFP: "021"},
    choctawcounty: {stateFP: "40", countyFP: "023"},
    cimarroncounty: {stateFP: "40", countyFP: "025"},
    clevelandcounty: {stateFP: "40", countyFP: "027"},
    coalcounty: {stateFP: "40", countyFP: "029"},
    comanchecounty: {stateFP: "40", countyFP: "031"},
    cottoncounty: {stateFP: "40", countyFP: "033"},
    craigcounty: {stateFP: "40", countyFP: "035"},
    creekcounty: {stateFP: "40", countyFP: "037"},
    custercounty: {stateFP: "40", countyFP: "039"},
    delawarecounty: {stateFP: "40", countyFP: "041"},
    deweycounty: {stateFP: "40", countyFP: "043"},
    elliscounty: {stateFP: "40", countyFP: "045"},
    garfieldcounty: {stateFP: "40", countyFP: "047"},
    garvincounty: {stateFP: "40", countyFP: "049"},
    gradycounty: {stateFP: "40", countyFP: "051"},
    grantcounty: {stateFP: "40", countyFP: "053"},
    greercounty: {stateFP: "40", countyFP: "055"},
    harmoncounty: {stateFP: "40", countyFP: "057"},
    harpercounty: {stateFP: "40", countyFP: "059"},
    haskellcounty: {stateFP: "40", countyFP: "061"},
    hughescounty: {stateFP: "40", countyFP: "063"},
    jacksoncounty: {stateFP: "40", countyFP: "065"},
    jeffersoncounty: {stateFP: "40", countyFP: "067"},
    johnstoncounty: {stateFP: "40", countyFP: "069"},
    kaycounty: {stateFP: "40", countyFP: "071"},
    kingfishercounty: {stateFP: "40", countyFP: "073"},
    kiowacounty: {stateFP: "40", countyFP: "075"},
    latimercounty: {stateFP: "40", countyFP: "077"},
    leflorecounty: {stateFP: "40", countyFP: "079"},
    lincolncounty: {stateFP: "40", countyFP: "081"},
    logancounty: {stateFP: "40", countyFP: "083"},
    lovecounty: {stateFP: "40", countyFP: "085"},
    mcclaincounty: {stateFP: "40", countyFP: "087"},
    mccurtaincounty: {stateFP: "40", countyFP: "089"},
    mcintoshcounty: {stateFP: "40", countyFP: "091"},
    majorcounty: {stateFP: "40", countyFP: "093"},
    marshallcounty: {stateFP: "40", countyFP: "095"},
    mayescounty: {stateFP: "40", countyFP: "097"},
    murraycounty: {stateFP: "40", countyFP: "099"},
    muskogeecounty: {stateFP: "40", countyFP: "101"},
    noblecounty: {stateFP: "40", countyFP: "103"},
    nowatacounty: {stateFP: "40", countyFP: "105"},
    okfuskeecounty: {stateFP: "40", countyFP: "107"},
    oklahomacounty: {stateFP: "40", countyFP: "109"},
    okmulgeecounty: {stateFP: "40", countyFP: "111"},
    osagecounty: {stateFP: "40", countyFP: "113"},
    ottawacounty: {stateFP: "40", countyFP: "115"},
    pawneecounty: {stateFP: "40", countyFP: "117"},
    paynecounty: {stateFP: "40", countyFP: "119"},
    pittsburgcounty: {stateFP: "40", countyFP: "121"},
    pontotoccounty: {stateFP: "40", countyFP: "123"},
    pottawatomiecounty: {stateFP: "40", countyFP: "125"},
    pushmatahacounty: {stateFP: "40", countyFP: "127"},
    rogermillscounty: {stateFP: "40", countyFP: "129"},
    rogerscounty: {stateFP: "40", countyFP: "131"},
    seminolecounty: {stateFP: "40", countyFP: "133"},
    sequoyahcounty: {stateFP: "40", countyFP: "135"},
    stephenscounty: {stateFP: "40", countyFP: "137"},
    texascounty: {stateFP: "40", countyFP: "139"},
    tillmancounty: {stateFP: "40", countyFP: "141"},
    tulsacounty: {stateFP: "40", countyFP: "143"},
    wagonercounty: {stateFP: "40", countyFP: "145"},
    washingtoncounty: {stateFP: "40", countyFP: "147"},
    washitacounty: {stateFP: "40", countyFP: "149"},
    woodscounty: {stateFP: "40", countyFP: "151"},
    woodwardcounty: {stateFP: "40", countyFP: "153"},
  },
  oregon: {
    bakercounty: {stateFP: "41", countyFP: "001"},
    bentoncounty: {stateFP: "41", countyFP: "003"},
    clackamascounty: {stateFP: "41", countyFP: "005"},
    clatsopcounty: {stateFP: "41", countyFP: "007"},
    columbiacounty: {stateFP: "41", countyFP: "009"},
    cooscounty: {stateFP: "41", countyFP: "011"},
    crookcounty: {stateFP: "41", countyFP: "013"},
    currycounty: {stateFP: "41", countyFP: "015"},
    deschutescounty: {stateFP: "41", countyFP: "017"},
    douglascounty: {stateFP: "41", countyFP: "019"},
    gilliamcounty: {stateFP: "41", countyFP: "021"},
    grantcounty: {stateFP: "41", countyFP: "023"},
    harneycounty: {stateFP: "41", countyFP: "025"},
    hoodrivercounty: {stateFP: "41", countyFP: "027"},
    jacksoncounty: {stateFP: "41", countyFP: "029"},
    jeffersoncounty: {stateFP: "41", countyFP: "031"},
    josephinecounty: {stateFP: "41", countyFP: "033"},
    klamathcounty: {stateFP: "41", countyFP: "035"},
    lakecounty: {stateFP: "41", countyFP: "037"},
    lanecounty: {stateFP: "41", countyFP: "039"},
    lincolncounty: {stateFP: "41", countyFP: "041"},
    linncounty: {stateFP: "41", countyFP: "043"},
    malheurcounty: {stateFP: "41", countyFP: "045"},
    marioncounty: {stateFP: "41", countyFP: "047"},
    morrowcounty: {stateFP: "41", countyFP: "049"},
    multnomahcounty: {stateFP: "41", countyFP: "051"},
    polkcounty: {stateFP: "41", countyFP: "053"},
    shermancounty: {stateFP: "41", countyFP: "055"},
    tillamookcounty: {stateFP: "41", countyFP: "057"},
    umatillacounty: {stateFP: "41", countyFP: "059"},
    unioncounty: {stateFP: "41", countyFP: "061"},
    wallowacounty: {stateFP: "41", countyFP: "063"},
    wascocounty: {stateFP: "41", countyFP: "065"},
    washingtoncounty: {stateFP: "41", countyFP: "067"},
    wheelercounty: {stateFP: "41", countyFP: "069"},
    yamhillcounty: {stateFP: "41", countyFP: "071"},
  },
  pennsylvania: {
    adamscounty: {stateFP: "42", countyFP: "001"},
    alleghenycounty: {stateFP: "42", countyFP: "003"},
    armstrongcounty: {stateFP: "42", countyFP: "005"},
    beavercounty: {stateFP: "42", countyFP: "007"},
    bedfordcounty: {stateFP: "42", countyFP: "009"},
    berkscounty: {stateFP: "42", countyFP: "011"},
    blaircounty: {stateFP: "42", countyFP: "013"},
    bradfordcounty: {stateFP: "42", countyFP: "015"},
    buckscounty: {stateFP: "42", countyFP: "017"},
    butlercounty: {stateFP: "42", countyFP: "019"},
    cambriacounty: {stateFP: "42", countyFP: "021"},
    cameroncounty: {stateFP: "42", countyFP: "023"},
    carboncounty: {stateFP: "42", countyFP: "025"},
    centrecounty: {stateFP: "42", countyFP: "027"},
    chestercounty: {stateFP: "42", countyFP: "029"},
    clarioncounty: {stateFP: "42", countyFP: "031"},
    clearfieldcounty: {stateFP: "42", countyFP: "033"},
    clintoncounty: {stateFP: "42", countyFP: "035"},
    columbiacounty: {stateFP: "42", countyFP: "037"},
    crawfordcounty: {stateFP: "42", countyFP: "039"},
    cumberlandcounty: {stateFP: "42", countyFP: "041"},
    dauphincounty: {stateFP: "42", countyFP: "043"},
    delawarecounty: {stateFP: "42", countyFP: "045"},
    elkcounty: {stateFP: "42", countyFP: "047"},
    eriecounty: {stateFP: "42", countyFP: "049"},
    fayettecounty: {stateFP: "42", countyFP: "051"},
    forestcounty: {stateFP: "42", countyFP: "053"},
    franklincounty: {stateFP: "42", countyFP: "055"},
    fultoncounty: {stateFP: "42", countyFP: "057"},
    greenecounty: {stateFP: "42", countyFP: "059"},
    huntingdoncounty: {stateFP: "42", countyFP: "061"},
    indianacounty: {stateFP: "42", countyFP: "063"},
    jeffersoncounty: {stateFP: "42", countyFP: "065"},
    juniatacounty: {stateFP: "42", countyFP: "067"},
    lackawannacounty: {stateFP: "42", countyFP: "069"},
    lancastercounty: {stateFP: "42", countyFP: "071"},
    lawrencecounty: {stateFP: "42", countyFP: "073"},
    lebanoncounty: {stateFP: "42", countyFP: "075"},
    lehighcounty: {stateFP: "42", countyFP: "077"},
    luzernecounty: {stateFP: "42", countyFP: "079"},
    lycomingcounty: {stateFP: "42", countyFP: "081"},
    mckeancounty: {stateFP: "42", countyFP: "083"},
    mercercounty: {stateFP: "42", countyFP: "085"},
    mifflincounty: {stateFP: "42", countyFP: "087"},
    monroecounty: {stateFP: "42", countyFP: "089"},
    montgomerycounty: {stateFP: "42", countyFP: "091"},
    montourcounty: {stateFP: "42", countyFP: "093"},
    northamptoncounty: {stateFP: "42", countyFP: "095"},
    northumberlandcounty: {stateFP: "42", countyFP: "097"},
    perrycounty: {stateFP: "42", countyFP: "099"},
    philadelphiacounty: {stateFP: "42", countyFP: "101"},
    pikecounty: {stateFP: "42", countyFP: "103"},
    pottercounty: {stateFP: "42", countyFP: "105"},
    schuylkillcounty: {stateFP: "42", countyFP: "107"},
    snydercounty: {stateFP: "42", countyFP: "109"},
    somersetcounty: {stateFP: "42", countyFP: "111"},
    sullivancounty: {stateFP: "42", countyFP: "113"},
    susquehannacounty: {stateFP: "42", countyFP: "115"},
    tiogacounty: {stateFP: "42", countyFP: "117"},
    unioncounty: {stateFP: "42", countyFP: "119"},
    venangocounty: {stateFP: "42", countyFP: "121"},
    warrencounty: {stateFP: "42", countyFP: "123"},
    washingtoncounty: {stateFP: "42", countyFP: "125"},
    waynecounty: {stateFP: "42", countyFP: "127"},
    westmorelandcounty: {stateFP: "42", countyFP: "129"},
    wyomingcounty: {stateFP: "42", countyFP: "131"},
    yorkcounty: {stateFP: "42", countyFP: "133"},
  },
  rhodeisland: {
    bristolcounty: {stateFP: "44", countyFP: "001"},
    kentcounty: {stateFP: "44", countyFP: "003"},
    newportcounty: {stateFP: "44", countyFP: "005"},
    providencecounty: {stateFP: "44", countyFP: "007"},
    washingtoncounty: {stateFP: "44", countyFP: "009"},
  },
  southcarolina: {
    abbevillecounty: {stateFP: "45", countyFP: "001"},
    aikencounty: {stateFP: "45", countyFP: "003"},
    allendalecounty: {stateFP: "45", countyFP: "005"},
    andersoncounty: {stateFP: "45", countyFP: "007"},
    bambergcounty: {stateFP: "45", countyFP: "009"},
    barnwellcounty: {stateFP: "45", countyFP: "011"},
    beaufortcounty: {stateFP: "45", countyFP: "013"},
    berkeleycounty: {stateFP: "45", countyFP: "015"},
    calhouncounty: {stateFP: "45", countyFP: "017"},
    charlestoncounty: {stateFP: "45", countyFP: "019"},
    cherokeecounty: {stateFP: "45", countyFP: "021"},
    chestercounty: {stateFP: "45", countyFP: "023"},
    chesterfieldcounty: {stateFP: "45", countyFP: "025"},
    clarendoncounty: {stateFP: "45", countyFP: "027"},
    colletoncounty: {stateFP: "45", countyFP: "029"},
    darlingtoncounty: {stateFP: "45", countyFP: "031"},
    dilloncounty: {stateFP: "45", countyFP: "033"},
    dorchestercounty: {stateFP: "45", countyFP: "035"},
    edgefieldcounty: {stateFP: "45", countyFP: "037"},
    fairfieldcounty: {stateFP: "45", countyFP: "039"},
    florencecounty: {stateFP: "45", countyFP: "041"},
    georgetowncounty: {stateFP: "45", countyFP: "043"},
    greenvillecounty: {stateFP: "45", countyFP: "045"},
    greenwoodcounty: {stateFP: "45", countyFP: "047"},
    hamptoncounty: {stateFP: "45", countyFP: "049"},
    horrycounty: {stateFP: "45", countyFP: "051"},
    jaspercounty: {stateFP: "45", countyFP: "053"},
    kershawcounty: {stateFP: "45", countyFP: "055"},
    lancastercounty: {stateFP: "45", countyFP: "057"},
    laurenscounty: {stateFP: "45", countyFP: "059"},
    leecounty: {stateFP: "45", countyFP: "061"},
    lexingtoncounty: {stateFP: "45", countyFP: "063"},
    mccormickcounty: {stateFP: "45", countyFP: "065"},
    marioncounty: {stateFP: "45", countyFP: "067"},
    marlborocounty: {stateFP: "45", countyFP: "069"},
    newberrycounty: {stateFP: "45", countyFP: "071"},
    oconeecounty: {stateFP: "45", countyFP: "073"},
    orangeburgcounty: {stateFP: "45", countyFP: "075"},
    pickenscounty: {stateFP: "45", countyFP: "077"},
    richlandcounty: {stateFP: "45", countyFP: "079"},
    saludacounty: {stateFP: "45", countyFP: "081"},
    spartanburgcounty: {stateFP: "45", countyFP: "083"},
    sumtercounty: {stateFP: "45", countyFP: "085"},
    unioncounty: {stateFP: "45", countyFP: "087"},
    williamsburgcounty: {stateFP: "45", countyFP: "089"},
    yorkcounty: {stateFP: "45", countyFP: "091"},
  },
  southdakota: {
    auroracounty: {stateFP: "46", countyFP: "003"},
    beadlecounty: {stateFP: "46", countyFP: "005"},
    bennettcounty: {stateFP: "46", countyFP: "007"},
    bonhommecounty: {stateFP: "46", countyFP: "009"},
    brookingscounty: {stateFP: "46", countyFP: "011"},
    browncounty: {stateFP: "46", countyFP: "013"},
    brulecounty: {stateFP: "46", countyFP: "015"},
    buffalocounty: {stateFP: "46", countyFP: "017"},
    buttecounty: {stateFP: "46", countyFP: "019"},
    campbellcounty: {stateFP: "46", countyFP: "021"},
    charlesmixcounty: {stateFP: "46", countyFP: "023"},
    clarkcounty: {stateFP: "46", countyFP: "025"},
    claycounty: {stateFP: "46", countyFP: "027"},
    codingtoncounty: {stateFP: "46", countyFP: "029"},
    corsoncounty: {stateFP: "46", countyFP: "031"},
    custercounty: {stateFP: "46", countyFP: "033"},
    davisoncounty: {stateFP: "46", countyFP: "035"},
    daycounty: {stateFP: "46", countyFP: "037"},
    deuelcounty: {stateFP: "46", countyFP: "039"},
    deweycounty: {stateFP: "46", countyFP: "041"},
    douglascounty: {stateFP: "46", countyFP: "043"},
    edmundscounty: {stateFP: "46", countyFP: "045"},
    fallrivercounty: {stateFP: "46", countyFP: "047"},
    faulkcounty: {stateFP: "46", countyFP: "049"},
    grantcounty: {stateFP: "46", countyFP: "051"},
    gregorycounty: {stateFP: "46", countyFP: "053"},
    haakoncounty: {stateFP: "46", countyFP: "055"},
    hamlincounty: {stateFP: "46", countyFP: "057"},
    handcounty: {stateFP: "46", countyFP: "059"},
    hansoncounty: {stateFP: "46", countyFP: "061"},
    hardingcounty: {stateFP: "46", countyFP: "063"},
    hughescounty: {stateFP: "46", countyFP: "065"},
    hutchinsoncounty: {stateFP: "46", countyFP: "067"},
    hydecounty: {stateFP: "46", countyFP: "069"},
    jacksoncounty: {stateFP: "46", countyFP: "071"},
    jerauldcounty: {stateFP: "46", countyFP: "073"},
    jonescounty: {stateFP: "46", countyFP: "075"},
    kingsburycounty: {stateFP: "46", countyFP: "077"},
    lakecounty: {stateFP: "46", countyFP: "079"},
    lawrencecounty: {stateFP: "46", countyFP: "081"},
    lincolncounty: {stateFP: "46", countyFP: "083"},
    lymancounty: {stateFP: "46", countyFP: "085"},
    mccookcounty: {stateFP: "46", countyFP: "087"},
    mcphersoncounty: {stateFP: "46", countyFP: "089"},
    marshallcounty: {stateFP: "46", countyFP: "091"},
    meadecounty: {stateFP: "46", countyFP: "093"},
    mellettecounty: {stateFP: "46", countyFP: "095"},
    minercounty: {stateFP: "46", countyFP: "097"},
    minnehahacounty: {stateFP: "46", countyFP: "099"},
    moodycounty: {stateFP: "46", countyFP: "101"},
    oglalalakotacounty: {stateFP: "46", countyFP: "102"},
    penningtoncounty: {stateFP: "46", countyFP: "103"},
    perkinscounty: {stateFP: "46", countyFP: "105"},
    pottercounty: {stateFP: "46", countyFP: "107"},
    robertscounty: {stateFP: "46", countyFP: "109"},
    sanborncounty: {stateFP: "46", countyFP: "111"},
    spinkcounty: {stateFP: "46", countyFP: "115"},
    stanleycounty: {stateFP: "46", countyFP: "117"},
    sullycounty: {stateFP: "46", countyFP: "119"},
    toddcounty: {stateFP: "46", countyFP: "121"},
    trippcounty: {stateFP: "46", countyFP: "123"},
    turnercounty: {stateFP: "46", countyFP: "125"},
    unioncounty: {stateFP: "46", countyFP: "127"},
    walworthcounty: {stateFP: "46", countyFP: "129"},
    yanktoncounty: {stateFP: "46", countyFP: "135"},
    ziebachcounty: {stateFP: "46", countyFP: "137"},
  },
  tennessee: {
    andersoncounty: {stateFP: "47", countyFP: "001"},
    bedfordcounty: {stateFP: "47", countyFP: "003"},
    bentoncounty: {stateFP: "47", countyFP: "005"},
    bledsoecounty: {stateFP: "47", countyFP: "007"},
    blountcounty: {stateFP: "47", countyFP: "009"},
    bradleycounty: {stateFP: "47", countyFP: "011"},
    campbellcounty: {stateFP: "47", countyFP: "013"},
    cannoncounty: {stateFP: "47", countyFP: "015"},
    carrollcounty: {stateFP: "47", countyFP: "017"},
    cartercounty: {stateFP: "47", countyFP: "019"},
    cheathamcounty: {stateFP: "47", countyFP: "021"},
    chestercounty: {stateFP: "47", countyFP: "023"},
    claibornecounty: {stateFP: "47", countyFP: "025"},
    claycounty: {stateFP: "47", countyFP: "027"},
    cockecounty: {stateFP: "47", countyFP: "029"},
    coffeecounty: {stateFP: "47", countyFP: "031"},
    crockettcounty: {stateFP: "47", countyFP: "033"},
    cumberlandcounty: {stateFP: "47", countyFP: "035"},
    davidsoncounty: {stateFP: "47", countyFP: "037"},
    decaturcounty: {stateFP: "47", countyFP: "039"},
    dekalbcounty: {stateFP: "47", countyFP: "041"},
    dicksoncounty: {stateFP: "47", countyFP: "043"},
    dyercounty: {stateFP: "47", countyFP: "045"},
    fayettecounty: {stateFP: "47", countyFP: "047"},
    fentresscounty: {stateFP: "47", countyFP: "049"},
    franklincounty: {stateFP: "47", countyFP: "051"},
    gibsoncounty: {stateFP: "47", countyFP: "053"},
    gilescounty: {stateFP: "47", countyFP: "055"},
    graingercounty: {stateFP: "47", countyFP: "057"},
    greenecounty: {stateFP: "47", countyFP: "059"},
    grundycounty: {stateFP: "47", countyFP: "061"},
    hamblencounty: {stateFP: "47", countyFP: "063"},
    hamiltoncounty: {stateFP: "47", countyFP: "065"},
    hancockcounty: {stateFP: "47", countyFP: "067"},
    hardemancounty: {stateFP: "47", countyFP: "069"},
    hardincounty: {stateFP: "47", countyFP: "071"},
    hawkinscounty: {stateFP: "47", countyFP: "073"},
    haywoodcounty: {stateFP: "47", countyFP: "075"},
    hendersoncounty: {stateFP: "47", countyFP: "077"},
    henrycounty: {stateFP: "47", countyFP: "079"},
    hickmancounty: {stateFP: "47", countyFP: "081"},
    houstoncounty: {stateFP: "47", countyFP: "083"},
    humphreyscounty: {stateFP: "47", countyFP: "085"},
    jacksoncounty: {stateFP: "47", countyFP: "087"},
    jeffersoncounty: {stateFP: "47", countyFP: "089"},
    johnsoncounty: {stateFP: "47", countyFP: "091"},
    knoxcounty: {stateFP: "47", countyFP: "093"},
    lakecounty: {stateFP: "47", countyFP: "095"},
    lauderdalecounty: {stateFP: "47", countyFP: "097"},
    lawrencecounty: {stateFP: "47", countyFP: "099"},
    lewiscounty: {stateFP: "47", countyFP: "101"},
    lincolncounty: {stateFP: "47", countyFP: "103"},
    loudoncounty: {stateFP: "47", countyFP: "105"},
    mcminncounty: {stateFP: "47", countyFP: "107"},
    mcnairycounty: {stateFP: "47", countyFP: "109"},
    maconcounty: {stateFP: "47", countyFP: "111"},
    madisoncounty: {stateFP: "47", countyFP: "113"},
    marioncounty: {stateFP: "47", countyFP: "115"},
    marshallcounty: {stateFP: "47", countyFP: "117"},
    maurycounty: {stateFP: "47", countyFP: "119"},
    meigscounty: {stateFP: "47", countyFP: "121"},
    monroecounty: {stateFP: "47", countyFP: "123"},
    montgomerycounty: {stateFP: "47", countyFP: "125"},
    moorecounty: {stateFP: "47", countyFP: "127"},
    morgancounty: {stateFP: "47", countyFP: "129"},
    obioncounty: {stateFP: "47", countyFP: "131"},
    overtoncounty: {stateFP: "47", countyFP: "133"},
    perrycounty: {stateFP: "47", countyFP: "135"},
    pickettcounty: {stateFP: "47", countyFP: "137"},
    polkcounty: {stateFP: "47", countyFP: "139"},
    putnamcounty: {stateFP: "47", countyFP: "141"},
    rheacounty: {stateFP: "47", countyFP: "143"},
    roanecounty: {stateFP: "47", countyFP: "145"},
    robertsoncounty: {stateFP: "47", countyFP: "147"},
    rutherfordcounty: {stateFP: "47", countyFP: "149"},
    scottcounty: {stateFP: "47", countyFP: "151"},
    sequatchiecounty: {stateFP: "47", countyFP: "153"},
    seviercounty: {stateFP: "47", countyFP: "155"},
    shelbycounty: {stateFP: "47", countyFP: "157"},
    smithcounty: {stateFP: "47", countyFP: "159"},
    stewartcounty: {stateFP: "47", countyFP: "161"},
    sullivancounty: {stateFP: "47", countyFP: "163"},
    sumnercounty: {stateFP: "47", countyFP: "165"},
    tiptoncounty: {stateFP: "47", countyFP: "167"},
    trousdalecounty: {stateFP: "47", countyFP: "169"},
    unicoicounty: {stateFP: "47", countyFP: "171"},
    unioncounty: {stateFP: "47", countyFP: "173"},
    vanburencounty: {stateFP: "47", countyFP: "175"},
    warrencounty: {stateFP: "47", countyFP: "177"},
    washingtoncounty: {stateFP: "47", countyFP: "179"},
    waynecounty: {stateFP: "47", countyFP: "181"},
    weakleycounty: {stateFP: "47", countyFP: "183"},
    whitecounty: {stateFP: "47", countyFP: "185"},
    williamsoncounty: {stateFP: "47", countyFP: "187"},
    wilsoncounty: {stateFP: "47", countyFP: "189"},
  },
  texas: {
    andersoncounty: {stateFP: "48", countyFP: "001"},
    andrewscounty: {stateFP: "48", countyFP: "003"},
    angelinacounty: {stateFP: "48", countyFP: "005"},
    aransascounty: {stateFP: "48", countyFP: "007"},
    archercounty: {stateFP: "48", countyFP: "009"},
    armstrongcounty: {stateFP: "48", countyFP: "011"},
    atascosacounty: {stateFP: "48", countyFP: "013"},
    austincounty: {stateFP: "48", countyFP: "015"},
    baileycounty: {stateFP: "48", countyFP: "017"},
    banderacounty: {stateFP: "48", countyFP: "019"},
    bastropcounty: {stateFP: "48", countyFP: "021"},
    baylorcounty: {stateFP: "48", countyFP: "023"},
    beecounty: {stateFP: "48", countyFP: "025"},
    bellcounty: {stateFP: "48", countyFP: "027"},
    bexarcounty: {stateFP: "48", countyFP: "029"},
    blancocounty: {stateFP: "48", countyFP: "031"},
    bordencounty: {stateFP: "48", countyFP: "033"},
    bosquecounty: {stateFP: "48", countyFP: "035"},
    bowiecounty: {stateFP: "48", countyFP: "037"},
    brazoriacounty: {stateFP: "48", countyFP: "039"},
    brazoscounty: {stateFP: "48", countyFP: "041"},
    brewstercounty: {stateFP: "48", countyFP: "043"},
    briscoecounty: {stateFP: "48", countyFP: "045"},
    brookscounty: {stateFP: "48", countyFP: "047"},
    browncounty: {stateFP: "48", countyFP: "049"},
    burlesoncounty: {stateFP: "48", countyFP: "051"},
    burnetcounty: {stateFP: "48", countyFP: "053"},
    caldwellcounty: {stateFP: "48", countyFP: "055"},
    calhouncounty: {stateFP: "48", countyFP: "057"},
    callahancounty: {stateFP: "48", countyFP: "059"},
    cameroncounty: {stateFP: "48", countyFP: "061"},
    campcounty: {stateFP: "48", countyFP: "063"},
    carsoncounty: {stateFP: "48", countyFP: "065"},
    casscounty: {stateFP: "48", countyFP: "067"},
    castrocounty: {stateFP: "48", countyFP: "069"},
    chamberscounty: {stateFP: "48", countyFP: "071"},
    cherokeecounty: {stateFP: "48", countyFP: "073"},
    childresscounty: {stateFP: "48", countyFP: "075"},
    claycounty: {stateFP: "48", countyFP: "077"},
    cochrancounty: {stateFP: "48", countyFP: "079"},
    cokecounty: {stateFP: "48", countyFP: "081"},
    colemancounty: {stateFP: "48", countyFP: "083"},
    collincounty: {stateFP: "48", countyFP: "085"},
    collingsworthcounty: {stateFP: "48", countyFP: "087"},
    coloradocounty: {stateFP: "48", countyFP: "089"},
    comalcounty: {stateFP: "48", countyFP: "091"},
    comanchecounty: {stateFP: "48", countyFP: "093"},
    conchocounty: {stateFP: "48", countyFP: "095"},
    cookecounty: {stateFP: "48", countyFP: "097"},
    coryellcounty: {stateFP: "48", countyFP: "099"},
    cottlecounty: {stateFP: "48", countyFP: "101"},
    cranecounty: {stateFP: "48", countyFP: "103"},
    crockettcounty: {stateFP: "48", countyFP: "105"},
    crosbycounty: {stateFP: "48", countyFP: "107"},
    culbersoncounty: {stateFP: "48", countyFP: "109"},
    dallamcounty: {stateFP: "48", countyFP: "111"},
    dallascounty: {stateFP: "48", countyFP: "113"},
    dawsoncounty: {stateFP: "48", countyFP: "115"},
    deafsmithcounty: {stateFP: "48", countyFP: "117"},
    deltacounty: {stateFP: "48", countyFP: "119"},
    dentoncounty: {stateFP: "48", countyFP: "121"},
    dewittcounty: {stateFP: "48", countyFP: "123"},
    dickenscounty: {stateFP: "48", countyFP: "125"},
    dimmitcounty: {stateFP: "48", countyFP: "127"},
    donleycounty: {stateFP: "48", countyFP: "129"},
    duvalcounty: {stateFP: "48", countyFP: "131"},
    eastlandcounty: {stateFP: "48", countyFP: "133"},
    ectorcounty: {stateFP: "48", countyFP: "135"},
    edwardscounty: {stateFP: "48", countyFP: "137"},
    elliscounty: {stateFP: "48", countyFP: "139"},
    elpasocounty: {stateFP: "48", countyFP: "141"},
    erathcounty: {stateFP: "48", countyFP: "143"},
    fallscounty: {stateFP: "48", countyFP: "145"},
    fannincounty: {stateFP: "48", countyFP: "147"},
    fayettecounty: {stateFP: "48", countyFP: "149"},
    fishercounty: {stateFP: "48", countyFP: "151"},
    floydcounty: {stateFP: "48", countyFP: "153"},
    foardcounty: {stateFP: "48", countyFP: "155"},
    fortbendcounty: {stateFP: "48", countyFP: "157"},
    franklincounty: {stateFP: "48", countyFP: "159"},
    freestonecounty: {stateFP: "48", countyFP: "161"},
    friocounty: {stateFP: "48", countyFP: "163"},
    gainescounty: {stateFP: "48", countyFP: "165"},
    galvestoncounty: {stateFP: "48", countyFP: "167"},
    garzacounty: {stateFP: "48", countyFP: "169"},
    gillespiecounty: {stateFP: "48", countyFP: "171"},
    glasscockcounty: {stateFP: "48", countyFP: "173"},
    goliadcounty: {stateFP: "48", countyFP: "175"},
    gonzalescounty: {stateFP: "48", countyFP: "177"},
    graycounty: {stateFP: "48", countyFP: "179"},
    graysoncounty: {stateFP: "48", countyFP: "181"},
    greggcounty: {stateFP: "48", countyFP: "183"},
    grimescounty: {stateFP: "48", countyFP: "185"},
    guadalupecounty: {stateFP: "48", countyFP: "187"},
    halecounty: {stateFP: "48", countyFP: "189"},
    hallcounty: {stateFP: "48", countyFP: "191"},
    hamiltoncounty: {stateFP: "48", countyFP: "193"},
    hansfordcounty: {stateFP: "48", countyFP: "195"},
    hardemancounty: {stateFP: "48", countyFP: "197"},
    hardincounty: {stateFP: "48", countyFP: "199"},
    harriscounty: {stateFP: "48", countyFP: "201"},
    harrisoncounty: {stateFP: "48", countyFP: "203"},
    hartleycounty: {stateFP: "48", countyFP: "205"},
    haskellcounty: {stateFP: "48", countyFP: "207"},
    hayscounty: {stateFP: "48", countyFP: "209"},
    hemphillcounty: {stateFP: "48", countyFP: "211"},
    hendersoncounty: {stateFP: "48", countyFP: "213"},
    hidalgocounty: {stateFP: "48", countyFP: "215"},
    hillcounty: {stateFP: "48", countyFP: "217"},
    hockleycounty: {stateFP: "48", countyFP: "219"},
    hoodcounty: {stateFP: "48", countyFP: "221"},
    hopkinscounty: {stateFP: "48", countyFP: "223"},
    houstoncounty: {stateFP: "48", countyFP: "225"},
    howardcounty: {stateFP: "48", countyFP: "227"},
    hudspethcounty: {stateFP: "48", countyFP: "229"},
    huntcounty: {stateFP: "48", countyFP: "231"},
    hutchinsoncounty: {stateFP: "48", countyFP: "233"},
    irioncounty: {stateFP: "48", countyFP: "235"},
    jackcounty: {stateFP: "48", countyFP: "237"},
    jacksoncounty: {stateFP: "48", countyFP: "239"},
    jaspercounty: {stateFP: "48", countyFP: "241"},
    jeffdaviscounty: {stateFP: "48", countyFP: "243"},
    jeffersoncounty: {stateFP: "48", countyFP: "245"},
    jimhoggcounty: {stateFP: "48", countyFP: "247"},
    jimwellscounty: {stateFP: "48", countyFP: "249"},
    johnsoncounty: {stateFP: "48", countyFP: "251"},
    jonescounty: {stateFP: "48", countyFP: "253"},
    karnescounty: {stateFP: "48", countyFP: "255"},
    kaufmancounty: {stateFP: "48", countyFP: "257"},
    kendallcounty: {stateFP: "48", countyFP: "259"},
    kenedycounty: {stateFP: "48", countyFP: "261"},
    kentcounty: {stateFP: "48", countyFP: "263"},
    kerrcounty: {stateFP: "48", countyFP: "265"},
    kimblecounty: {stateFP: "48", countyFP: "267"},
    kingcounty: {stateFP: "48", countyFP: "269"},
    kinneycounty: {stateFP: "48", countyFP: "271"},
    klebergcounty: {stateFP: "48", countyFP: "273"},
    knoxcounty: {stateFP: "48", countyFP: "275"},
    lamarcounty: {stateFP: "48", countyFP: "277"},
    lambcounty: {stateFP: "48", countyFP: "279"},
    lampasascounty: {stateFP: "48", countyFP: "281"},
    lasallecounty: {stateFP: "48", countyFP: "283"},
    lavacacounty: {stateFP: "48", countyFP: "285"},
    leecounty: {stateFP: "48", countyFP: "287"},
    leoncounty: {stateFP: "48", countyFP: "289"},
    libertycounty: {stateFP: "48", countyFP: "291"},
    limestonecounty: {stateFP: "48", countyFP: "293"},
    lipscombcounty: {stateFP: "48", countyFP: "295"},
    liveoakcounty: {stateFP: "48", countyFP: "297"},
    llanocounty: {stateFP: "48", countyFP: "299"},
    lovingcounty: {stateFP: "48", countyFP: "301"},
    lubbockcounty: {stateFP: "48", countyFP: "303"},
    lynncounty: {stateFP: "48", countyFP: "305"},
    mccullochcounty: {stateFP: "48", countyFP: "307"},
    mclennancounty: {stateFP: "48", countyFP: "309"},
    mcmullencounty: {stateFP: "48", countyFP: "311"},
    madisoncounty: {stateFP: "48", countyFP: "313"},
    marioncounty: {stateFP: "48", countyFP: "315"},
    martincounty: {stateFP: "48", countyFP: "317"},
    masoncounty: {stateFP: "48", countyFP: "319"},
    matagordacounty: {stateFP: "48", countyFP: "321"},
    maverickcounty: {stateFP: "48", countyFP: "323"},
    medinacounty: {stateFP: "48", countyFP: "325"},
    menardcounty: {stateFP: "48", countyFP: "327"},
    midlandcounty: {stateFP: "48", countyFP: "329"},
    milamcounty: {stateFP: "48", countyFP: "331"},
    millscounty: {stateFP: "48", countyFP: "333"},
    mitchellcounty: {stateFP: "48", countyFP: "335"},
    montaguecounty: {stateFP: "48", countyFP: "337"},
    montgomerycounty: {stateFP: "48", countyFP: "339"},
    moorecounty: {stateFP: "48", countyFP: "341"},
    morriscounty: {stateFP: "48", countyFP: "343"},
    motleycounty: {stateFP: "48", countyFP: "345"},
    nacogdochescounty: {stateFP: "48", countyFP: "347"},
    navarrocounty: {stateFP: "48", countyFP: "349"},
    newtoncounty: {stateFP: "48", countyFP: "351"},
    nolancounty: {stateFP: "48", countyFP: "353"},
    nuecescounty: {stateFP: "48", countyFP: "355"},
    ochiltreecounty: {stateFP: "48", countyFP: "357"},
    oldhamcounty: {stateFP: "48", countyFP: "359"},
    orangecounty: {stateFP: "48", countyFP: "361"},
    palopintocounty: {stateFP: "48", countyFP: "363"},
    panolacounty: {stateFP: "48", countyFP: "365"},
    parkercounty: {stateFP: "48", countyFP: "367"},
    parmercounty: {stateFP: "48", countyFP: "369"},
    pecoscounty: {stateFP: "48", countyFP: "371"},
    polkcounty: {stateFP: "48", countyFP: "373"},
    pottercounty: {stateFP: "48", countyFP: "375"},
    presidiocounty: {stateFP: "48", countyFP: "377"},
    rainscounty: {stateFP: "48", countyFP: "379"},
    randallcounty: {stateFP: "48", countyFP: "381"},
    reagancounty: {stateFP: "48", countyFP: "383"},
    realcounty: {stateFP: "48", countyFP: "385"},
    redrivercounty: {stateFP: "48", countyFP: "387"},
    reevescounty: {stateFP: "48", countyFP: "389"},
    refugiocounty: {stateFP: "48", countyFP: "391"},
    robertscounty: {stateFP: "48", countyFP: "393"},
    robertsoncounty: {stateFP: "48", countyFP: "395"},
    rockwallcounty: {stateFP: "48", countyFP: "397"},
    runnelscounty: {stateFP: "48", countyFP: "399"},
    ruskcounty: {stateFP: "48", countyFP: "401"},
    sabinecounty: {stateFP: "48", countyFP: "403"},
    sanaugustinecounty: {stateFP: "48", countyFP: "405"},
    sanjacintocounty: {stateFP: "48", countyFP: "407"},
    sanpatriciocounty: {stateFP: "48", countyFP: "409"},
    sansabacounty: {stateFP: "48", countyFP: "411"},
    schleichercounty: {stateFP: "48", countyFP: "413"},
    scurrycounty: {stateFP: "48", countyFP: "415"},
    shackelfordcounty: {stateFP: "48", countyFP: "417"},
    shelbycounty: {stateFP: "48", countyFP: "419"},
    shermancounty: {stateFP: "48", countyFP: "421"},
    smithcounty: {stateFP: "48", countyFP: "423"},
    somervellcounty: {stateFP: "48", countyFP: "425"},
    starrcounty: {stateFP: "48", countyFP: "427"},
    stephenscounty: {stateFP: "48", countyFP: "429"},
    sterlingcounty: {stateFP: "48", countyFP: "431"},
    stonewallcounty: {stateFP: "48", countyFP: "433"},
    suttoncounty: {stateFP: "48", countyFP: "435"},
    swishercounty: {stateFP: "48", countyFP: "437"},
    tarrantcounty: {stateFP: "48", countyFP: "439"},
    taylorcounty: {stateFP: "48", countyFP: "441"},
    terrellcounty: {stateFP: "48", countyFP: "443"},
    terrycounty: {stateFP: "48", countyFP: "445"},
    throckmortoncounty: {stateFP: "48", countyFP: "447"},
    tituscounty: {stateFP: "48", countyFP: "449"},
    tomgreencounty: {stateFP: "48", countyFP: "451"},
    traviscounty: {stateFP: "48", countyFP: "453"},
    trinitycounty: {stateFP: "48", countyFP: "455"},
    tylercounty: {stateFP: "48", countyFP: "457"},
    upshurcounty: {stateFP: "48", countyFP: "459"},
    uptoncounty: {stateFP: "48", countyFP: "461"},
    uvaldecounty: {stateFP: "48", countyFP: "463"},
    valverdecounty: {stateFP: "48", countyFP: "465"},
    vanzandtcounty: {stateFP: "48", countyFP: "467"},
    victoriacounty: {stateFP: "48", countyFP: "469"},
    walkercounty: {stateFP: "48", countyFP: "471"},
    wallercounty: {stateFP: "48", countyFP: "473"},
    wardcounty: {stateFP: "48", countyFP: "475"},
    washingtoncounty: {stateFP: "48", countyFP: "477"},
    webbcounty: {stateFP: "48", countyFP: "479"},
    whartoncounty: {stateFP: "48", countyFP: "481"},
    wheelercounty: {stateFP: "48", countyFP: "483"},
    wichitacounty: {stateFP: "48", countyFP: "485"},
    wilbargercounty: {stateFP: "48", countyFP: "487"},
    willacycounty: {stateFP: "48", countyFP: "489"},
    williamsoncounty: {stateFP: "48", countyFP: "491"},
    wilsoncounty: {stateFP: "48", countyFP: "493"},
    winklercounty: {stateFP: "48", countyFP: "495"},
    wisecounty: {stateFP: "48", countyFP: "497"},
    woodcounty: {stateFP: "48", countyFP: "499"},
    yoakumcounty: {stateFP: "48", countyFP: "501"},
    youngcounty: {stateFP: "48", countyFP: "503"},
    zapatacounty: {stateFP: "48", countyFP: "505"},
    zavalacounty: {stateFP: "48", countyFP: "507"},
  },
  utah: {
    beavercounty: {stateFP: "49", countyFP: "001"},
    boxeldercounty: {stateFP: "49", countyFP: "003"},
    cachecounty: {stateFP: "49", countyFP: "005"},
    carboncounty: {stateFP: "49", countyFP: "007"},
    daggettcounty: {stateFP: "49", countyFP: "009"},
    daviscounty: {stateFP: "49", countyFP: "011"},
    duchesnecounty: {stateFP: "49", countyFP: "013"},
    emerycounty: {stateFP: "49", countyFP: "015"},
    garfieldcounty: {stateFP: "49", countyFP: "017"},
    grandcounty: {stateFP: "49", countyFP: "019"},
    ironcounty: {stateFP: "49", countyFP: "021"},
    juabcounty: {stateFP: "49", countyFP: "023"},
    kanecounty: {stateFP: "49", countyFP: "025"},
    millardcounty: {stateFP: "49", countyFP: "027"},
    morgancounty: {stateFP: "49", countyFP: "029"},
    piutecounty: {stateFP: "49", countyFP: "031"},
    richcounty: {stateFP: "49", countyFP: "033"},
    saltlakecounty: {stateFP: "49", countyFP: "035"},
    sanjuancounty: {stateFP: "49", countyFP: "037"},
    sanpetecounty: {stateFP: "49", countyFP: "039"},
    seviercounty: {stateFP: "49", countyFP: "041"},
    summitcounty: {stateFP: "49", countyFP: "043"},
    tooelecounty: {stateFP: "49", countyFP: "045"},
    uintahcounty: {stateFP: "49", countyFP: "047"},
    utahcounty: {stateFP: "49", countyFP: "049"},
    wasatchcounty: {stateFP: "49", countyFP: "051"},
    washingtoncounty: {stateFP: "49", countyFP: "053"},
    waynecounty: {stateFP: "49", countyFP: "055"},
    webercounty: {stateFP: "49", countyFP: "057"},
  },
  vermont: {
    addisoncounty: {stateFP: "50", countyFP: "001"},
    benningtoncounty: {stateFP: "50", countyFP: "003"},
    caledoniacounty: {stateFP: "50", countyFP: "005"},
    chittendencounty: {stateFP: "50", countyFP: "007"},
    essexcounty: {stateFP: "50", countyFP: "009"},
    franklincounty: {stateFP: "50", countyFP: "011"},
    grandislecounty: {stateFP: "50", countyFP: "013"},
    lamoillecounty: {stateFP: "50", countyFP: "015"},
    orangecounty: {stateFP: "50", countyFP: "017"},
    orleanscounty: {stateFP: "50", countyFP: "019"},
    rutlandcounty: {stateFP: "50", countyFP: "021"},
    washingtoncounty: {stateFP: "50", countyFP: "023"},
    windhamcounty: {stateFP: "50", countyFP: "025"},
    windsorcounty: {stateFP: "50", countyFP: "027"},
  },
  virginia: {
    accomackcounty: {stateFP: "51", countyFP: "001"},
    albemarlecounty: {stateFP: "51", countyFP: "003"},
    alleghanycounty: {stateFP: "51", countyFP: "005"},
    ameliacounty: {stateFP: "51", countyFP: "007"},
    amherstcounty: {stateFP: "51", countyFP: "009"},
    appomattoxcounty: {stateFP: "51", countyFP: "011"},
    arlingtoncounty: {stateFP: "51", countyFP: "013"},
    augustacounty: {stateFP: "51", countyFP: "015"},
    bathcounty: {stateFP: "51", countyFP: "017"},
    bedfordcounty: {stateFP: "51", countyFP: "019"},
    blandcounty: {stateFP: "51", countyFP: "021"},
    botetourtcounty: {stateFP: "51", countyFP: "023"},
    brunswickcounty: {stateFP: "51", countyFP: "025"},
    buchanancounty: {stateFP: "51", countyFP: "027"},
    buckinghamcounty: {stateFP: "51", countyFP: "029"},
    campbellcounty: {stateFP: "51", countyFP: "031"},
    carolinecounty: {stateFP: "51", countyFP: "033"},
    carrollcounty: {stateFP: "51", countyFP: "035"},
    charlescitycounty: {stateFP: "51", countyFP: "036"},
    charlottecounty: {stateFP: "51", countyFP: "037"},
    chesterfieldcounty: {stateFP: "51", countyFP: "041"},
    clarkecounty: {stateFP: "51", countyFP: "043"},
    craigcounty: {stateFP: "51", countyFP: "045"},
    culpepercounty: {stateFP: "51", countyFP: "047"},
    cumberlandcounty: {stateFP: "51", countyFP: "049"},
    dickensoncounty: {stateFP: "51", countyFP: "051"},
    dinwiddiecounty: {stateFP: "51", countyFP: "053"},
    essexcounty: {stateFP: "51", countyFP: "057"},
    fairfaxcounty: {stateFP: "51", countyFP: "059"},
    fauquiercounty: {stateFP: "51", countyFP: "061"},
    floydcounty: {stateFP: "51", countyFP: "063"},
    fluvannacounty: {stateFP: "51", countyFP: "065"},
    franklincounty: {stateFP: "51", countyFP: "067"},
    frederickcounty: {stateFP: "51", countyFP: "069"},
    gilescounty: {stateFP: "51", countyFP: "071"},
    gloucestercounty: {stateFP: "51", countyFP: "073"},
    goochlandcounty: {stateFP: "51", countyFP: "075"},
    graysoncounty: {stateFP: "51", countyFP: "077"},
    greenecounty: {stateFP: "51", countyFP: "079"},
    greensvillecounty: {stateFP: "51", countyFP: "081"},
    halifaxcounty: {stateFP: "51", countyFP: "083"},
    hanovercounty: {stateFP: "51", countyFP: "085"},
    henricocounty: {stateFP: "51", countyFP: "087"},
    henrycounty: {stateFP: "51", countyFP: "089"},
    highlandcounty: {stateFP: "51", countyFP: "091"},
    isleofwightcounty: {stateFP: "51", countyFP: "093"},
    jamescitycounty: {stateFP: "51", countyFP: "095"},
    kingandqueencounty: {stateFP: "51", countyFP: "097"},
    kinggeorgecounty: {stateFP: "51", countyFP: "099"},
    kingwilliamcounty: {stateFP: "51", countyFP: "101"},
    lancastercounty: {stateFP: "51", countyFP: "103"},
    leecounty: {stateFP: "51", countyFP: "105"},
    loudouncounty: {stateFP: "51", countyFP: "107"},
    louisacounty: {stateFP: "51", countyFP: "109"},
    lunenburgcounty: {stateFP: "51", countyFP: "111"},
    madisoncounty: {stateFP: "51", countyFP: "113"},
    mathewscounty: {stateFP: "51", countyFP: "115"},
    mecklenburgcounty: {stateFP: "51", countyFP: "117"},
    middlesexcounty: {stateFP: "51", countyFP: "119"},
    montgomerycounty: {stateFP: "51", countyFP: "121"},
    nelsoncounty: {stateFP: "51", countyFP: "125"},
    newkentcounty: {stateFP: "51", countyFP: "127"},
    northamptoncounty: {stateFP: "51", countyFP: "131"},
    northumberlandcounty: {stateFP: "51", countyFP: "133"},
    nottowaycounty: {stateFP: "51", countyFP: "135"},
    orangecounty: {stateFP: "51", countyFP: "137"},
    pagecounty: {stateFP: "51", countyFP: "139"},
    patrickcounty: {stateFP: "51", countyFP: "141"},
    pittsylvaniacounty: {stateFP: "51", countyFP: "143"},
    powhatancounty: {stateFP: "51", countyFP: "145"},
    princeedwardcounty: {stateFP: "51", countyFP: "147"},
    princegeorgecounty: {stateFP: "51", countyFP: "149"},
    princewilliamcounty: {stateFP: "51", countyFP: "153"},
    pulaskicounty: {stateFP: "51", countyFP: "155"},
    rappahannockcounty: {stateFP: "51", countyFP: "157"},
    richmondcounty: {stateFP: "51", countyFP: "159"},
    roanokecounty: {stateFP: "51", countyFP: "161"},
    rockbridgecounty: {stateFP: "51", countyFP: "163"},
    rockinghamcounty: {stateFP: "51", countyFP: "165"},
    russellcounty: {stateFP: "51", countyFP: "167"},
    scottcounty: {stateFP: "51", countyFP: "169"},
    shenandoahcounty: {stateFP: "51", countyFP: "171"},
    smythcounty: {stateFP: "51", countyFP: "173"},
    southamptoncounty: {stateFP: "51", countyFP: "175"},
    spotsylvaniacounty: {stateFP: "51", countyFP: "177"},
    staffordcounty: {stateFP: "51", countyFP: "179"},
    surrycounty: {stateFP: "51", countyFP: "181"},
    sussexcounty: {stateFP: "51", countyFP: "183"},
    tazewellcounty: {stateFP: "51", countyFP: "185"},
    warrencounty: {stateFP: "51", countyFP: "187"},
    washingtoncounty: {stateFP: "51", countyFP: "191"},
    westmorelandcounty: {stateFP: "51", countyFP: "193"},
    wisecounty: {stateFP: "51", countyFP: "195"},
    wythecounty: {stateFP: "51", countyFP: "197"},
    yorkcounty: {stateFP: "51", countyFP: "199"},
    alexandriacity: {stateFP: "51", countyFP: "510"},
    bristolcity: {stateFP: "51", countyFP: "520"},
    buenavistacity: {stateFP: "51", countyFP: "530"},
    charlottesvillecity: {stateFP: "51", countyFP: "540"},
    chesapeakecity: {stateFP: "51", countyFP: "550"},
    colonialheightscity: {stateFP: "51", countyFP: "570"},
    covingtoncity: {stateFP: "51", countyFP: "580"},
    danvillecity: {stateFP: "51", countyFP: "590"},
    emporiacity: {stateFP: "51", countyFP: "595"},
    fairfaxcity: {stateFP: "51", countyFP: "600"},
    fallschurchcity: {stateFP: "51", countyFP: "610"},
    franklincity: {stateFP: "51", countyFP: "620"},
    fredericksburgcity: {stateFP: "51", countyFP: "630"},
    galaxcity: {stateFP: "51", countyFP: "640"},
    hamptoncity: {stateFP: "51", countyFP: "650"},
    harrisonburgcity: {stateFP: "51", countyFP: "660"},
    hopewellcity: {stateFP: "51", countyFP: "670"},
    lexingtoncity: {stateFP: "51", countyFP: "678"},
    lynchburgcity: {stateFP: "51", countyFP: "680"},
    manassascity: {stateFP: "51", countyFP: "683"},
    manassasparkcity: {stateFP: "51", countyFP: "685"},
    martinsvillecity: {stateFP: "51", countyFP: "690"},
    newportnewscity: {stateFP: "51", countyFP: "700"},
    norfolkcity: {stateFP: "51", countyFP: "710"},
    nortoncity: {stateFP: "51", countyFP: "720"},
    petersburgcity: {stateFP: "51", countyFP: "730"},
    poquosoncity: {stateFP: "51", countyFP: "735"},
    portsmouthcity: {stateFP: "51", countyFP: "740"},
    radfordcity: {stateFP: "51", countyFP: "750"},
    richmondcity: {stateFP: "51", countyFP: "760"},
    roanokecity: {stateFP: "51", countyFP: "770"},
    salemcity: {stateFP: "51", countyFP: "775"},
    stauntoncity: {stateFP: "51", countyFP: "790"},
    suffolkcity: {stateFP: "51", countyFP: "800"},
    virginiabeachcity: {stateFP: "51", countyFP: "810"},
    waynesborocity: {stateFP: "51", countyFP: "820"},
    williamsburgcity: {stateFP: "51", countyFP: "830"},
    winchestercity: {stateFP: "51", countyFP: "840"},
  },
  washington: {
    adamscounty: {stateFP: "53", countyFP: "001"},
    asotincounty: {stateFP: "53", countyFP: "003"},
    bentoncounty: {stateFP: "53", countyFP: "005"},
    chelancounty: {stateFP: "53", countyFP: "007"},
    clallamcounty: {stateFP: "53", countyFP: "009"},
    clarkcounty: {stateFP: "53", countyFP: "011"},
    columbiacounty: {stateFP: "53", countyFP: "013"},
    cowlitzcounty: {stateFP: "53", countyFP: "015"},
    douglascounty: {stateFP: "53", countyFP: "017"},
    ferrycounty: {stateFP: "53", countyFP: "019"},
    franklincounty: {stateFP: "53", countyFP: "021"},
    garfieldcounty: {stateFP: "53", countyFP: "023"},
    grantcounty: {stateFP: "53", countyFP: "025"},
    graysharborcounty: {stateFP: "53", countyFP: "027"},
    islandcounty: {stateFP: "53", countyFP: "029"},
    jeffersoncounty: {stateFP: "53", countyFP: "031"},
    kingcounty: {stateFP: "53", countyFP: "033"},
    kitsapcounty: {stateFP: "53", countyFP: "035"},
    kittitascounty: {stateFP: "53", countyFP: "037"},
    klickitatcounty: {stateFP: "53", countyFP: "039"},
    lewiscounty: {stateFP: "53", countyFP: "041"},
    lincolncounty: {stateFP: "53", countyFP: "043"},
    masoncounty: {stateFP: "53", countyFP: "045"},
    okanogancounty: {stateFP: "53", countyFP: "047"},
    pacificcounty: {stateFP: "53", countyFP: "049"},
    pendoreillecounty: {stateFP: "53", countyFP: "051"},
    piercecounty: {stateFP: "53", countyFP: "053"},
    sanjuancounty: {stateFP: "53", countyFP: "055"},
    skagitcounty: {stateFP: "53", countyFP: "057"},
    skamaniacounty: {stateFP: "53", countyFP: "059"},
    snohomishcounty: {stateFP: "53", countyFP: "061"},
    spokanecounty: {stateFP: "53", countyFP: "063"},
    stevenscounty: {stateFP: "53", countyFP: "065"},
    thurstoncounty: {stateFP: "53", countyFP: "067"},
    wahkiakumcounty: {stateFP: "53", countyFP: "069"},
    wallawallacounty: {stateFP: "53", countyFP: "071"},
    whatcomcounty: {stateFP: "53", countyFP: "073"},
    whitmancounty: {stateFP: "53", countyFP: "075"},
    yakimacounty: {stateFP: "53", countyFP: "077"},
  },
  westvirginia: {
    barbourcounty: {stateFP: "54", countyFP: "001"},
    berkeleycounty: {stateFP: "54", countyFP: "003"},
    boonecounty: {stateFP: "54", countyFP: "005"},
    braxtoncounty: {stateFP: "54", countyFP: "007"},
    brookecounty: {stateFP: "54", countyFP: "009"},
    cabellcounty: {stateFP: "54", countyFP: "011"},
    calhouncounty: {stateFP: "54", countyFP: "013"},
    claycounty: {stateFP: "54", countyFP: "015"},
    doddridgecounty: {stateFP: "54", countyFP: "017"},
    fayettecounty: {stateFP: "54", countyFP: "019"},
    gilmercounty: {stateFP: "54", countyFP: "021"},
    grantcounty: {stateFP: "54", countyFP: "023"},
    greenbriercounty: {stateFP: "54", countyFP: "025"},
    hampshirecounty: {stateFP: "54", countyFP: "027"},
    hancockcounty: {stateFP: "54", countyFP: "029"},
    hardycounty: {stateFP: "54", countyFP: "031"},
    harrisoncounty: {stateFP: "54", countyFP: "033"},
    jacksoncounty: {stateFP: "54", countyFP: "035"},
    jeffersoncounty: {stateFP: "54", countyFP: "037"},
    kanawhacounty: {stateFP: "54", countyFP: "039"},
    lewiscounty: {stateFP: "54", countyFP: "041"},
    lincolncounty: {stateFP: "54", countyFP: "043"},
    logancounty: {stateFP: "54", countyFP: "045"},
    mcdowellcounty: {stateFP: "54", countyFP: "047"},
    marioncounty: {stateFP: "54", countyFP: "049"},
    marshallcounty: {stateFP: "54", countyFP: "051"},
    masoncounty: {stateFP: "54", countyFP: "053"},
    mercercounty: {stateFP: "54", countyFP: "055"},
    mineralcounty: {stateFP: "54", countyFP: "057"},
    mingocounty: {stateFP: "54", countyFP: "059"},
    monongaliacounty: {stateFP: "54", countyFP: "061"},
    monroecounty: {stateFP: "54", countyFP: "063"},
    morgancounty: {stateFP: "54", countyFP: "065"},
    nicholascounty: {stateFP: "54", countyFP: "067"},
    ohiocounty: {stateFP: "54", countyFP: "069"},
    pendletoncounty: {stateFP: "54", countyFP: "071"},
    pleasantscounty: {stateFP: "54", countyFP: "073"},
    pocahontascounty: {stateFP: "54", countyFP: "075"},
    prestoncounty: {stateFP: "54", countyFP: "077"},
    putnamcounty: {stateFP: "54", countyFP: "079"},
    raleighcounty: {stateFP: "54", countyFP: "081"},
    randolphcounty: {stateFP: "54", countyFP: "083"},
    ritchiecounty: {stateFP: "54", countyFP: "085"},
    roanecounty: {stateFP: "54", countyFP: "087"},
    summerscounty: {stateFP: "54", countyFP: "089"},
    taylorcounty: {stateFP: "54", countyFP: "091"},
    tuckercounty: {stateFP: "54", countyFP: "093"},
    tylercounty: {stateFP: "54", countyFP: "095"},
    upshurcounty: {stateFP: "54", countyFP: "097"},
    waynecounty: {stateFP: "54", countyFP: "099"},
    webstercounty: {stateFP: "54", countyFP: "101"},
    wetzelcounty: {stateFP: "54", countyFP: "103"},
    wirtcounty: {stateFP: "54", countyFP: "105"},
    woodcounty: {stateFP: "54", countyFP: "107"},
    wyomingcounty: {stateFP: "54", countyFP: "109"},
  },
  wisconsin: {
    adamscounty: {stateFP: "55", countyFP: "001"},
    ashlandcounty: {stateFP: "55", countyFP: "003"},
    barroncounty: {stateFP: "55", countyFP: "005"},
    bayfieldcounty: {stateFP: "55", countyFP: "007"},
    browncounty: {stateFP: "55", countyFP: "009"},
    buffalocounty: {stateFP: "55", countyFP: "011"},
    burnettcounty: {stateFP: "55", countyFP: "013"},
    calumetcounty: {stateFP: "55", countyFP: "015"},
    chippewacounty: {stateFP: "55", countyFP: "017"},
    clarkcounty: {stateFP: "55", countyFP: "019"},
    columbiacounty: {stateFP: "55", countyFP: "021"},
    crawfordcounty: {stateFP: "55", countyFP: "023"},
    danecounty: {stateFP: "55", countyFP: "025"},
    dodgecounty: {stateFP: "55", countyFP: "027"},
    doorcounty: {stateFP: "55", countyFP: "029"},
    douglascounty: {stateFP: "55", countyFP: "031"},
    dunncounty: {stateFP: "55", countyFP: "033"},
    eauclairecounty: {stateFP: "55", countyFP: "035"},
    florencecounty: {stateFP: "55", countyFP: "037"},
    fonddulaccounty: {stateFP: "55", countyFP: "039"},
    forestcounty: {stateFP: "55", countyFP: "041"},
    grantcounty: {stateFP: "55", countyFP: "043"},
    greencounty: {stateFP: "55", countyFP: "045"},
    greenlakecounty: {stateFP: "55", countyFP: "047"},
    iowacounty: {stateFP: "55", countyFP: "049"},
    ironcounty: {stateFP: "55", countyFP: "051"},
    jacksoncounty: {stateFP: "55", countyFP: "053"},
    jeffersoncounty: {stateFP: "55", countyFP: "055"},
    juneaucounty: {stateFP: "55", countyFP: "057"},
    kenoshacounty: {stateFP: "55", countyFP: "059"},
    kewauneecounty: {stateFP: "55", countyFP: "061"},
    lacrossecounty: {stateFP: "55", countyFP: "063"},
    lafayettecounty: {stateFP: "55", countyFP: "065"},
    langladecounty: {stateFP: "55", countyFP: "067"},
    lincolncounty: {stateFP: "55", countyFP: "069"},
    manitowoccounty: {stateFP: "55", countyFP: "071"},
    marathoncounty: {stateFP: "55", countyFP: "073"},
    marinettecounty: {stateFP: "55", countyFP: "075"},
    marquettecounty: {stateFP: "55", countyFP: "077"},
    menomineecounty: {stateFP: "55", countyFP: "078"},
    milwaukeecounty: {stateFP: "55", countyFP: "079"},
    monroecounty: {stateFP: "55", countyFP: "081"},
    ocontocounty: {stateFP: "55", countyFP: "083"},
    oneidacounty: {stateFP: "55", countyFP: "085"},
    outagamiecounty: {stateFP: "55", countyFP: "087"},
    ozaukeecounty: {stateFP: "55", countyFP: "089"},
    pepincounty: {stateFP: "55", countyFP: "091"},
    piercecounty: {stateFP: "55", countyFP: "093"},
    polkcounty: {stateFP: "55", countyFP: "095"},
    portagecounty: {stateFP: "55", countyFP: "097"},
    pricecounty: {stateFP: "55", countyFP: "099"},
    racinecounty: {stateFP: "55", countyFP: "101"},
    richlandcounty: {stateFP: "55", countyFP: "103"},
    rockcounty: {stateFP: "55", countyFP: "105"},
    ruskcounty: {stateFP: "55", countyFP: "107"},
    stcroixcounty: {stateFP: "55", countyFP: "109"},
    saukcounty: {stateFP: "55", countyFP: "111"},
    sawyercounty: {stateFP: "55", countyFP: "113"},
    shawanocounty: {stateFP: "55", countyFP: "115"},
    sheboygancounty: {stateFP: "55", countyFP: "117"},
    taylorcounty: {stateFP: "55", countyFP: "119"},
    trempealeaucounty: {stateFP: "55", countyFP: "121"},
    vernoncounty: {stateFP: "55", countyFP: "123"},
    vilascounty: {stateFP: "55", countyFP: "125"},
    walworthcounty: {stateFP: "55", countyFP: "127"},
    washburncounty: {stateFP: "55", countyFP: "129"},
    washingtoncounty: {stateFP: "55", countyFP: "131"},
    waukeshacounty: {stateFP: "55", countyFP: "133"},
    waupacacounty: {stateFP: "55", countyFP: "135"},
    wausharacounty: {stateFP: "55", countyFP: "137"},
    winnebagocounty: {stateFP: "55", countyFP: "139"},
    woodcounty: {stateFP: "55", countyFP: "141"},
  },
  wyoming: {
    albanycounty: {stateFP: "56", countyFP: "001"},
    bighorncounty: {stateFP: "56", countyFP: "003"},
    campbellcounty: {stateFP: "56", countyFP: "005"},
    carboncounty: {stateFP: "56", countyFP: "007"},
    conversecounty: {stateFP: "56", countyFP: "009"},
    crookcounty: {stateFP: "56", countyFP: "011"},
    fremontcounty: {stateFP: "56", countyFP: "013"},
    goshencounty: {stateFP: "56", countyFP: "015"},
    hotspringscounty: {stateFP: "56", countyFP: "017"},
    johnsoncounty: {stateFP: "56", countyFP: "019"},
    laramiecounty: {stateFP: "56", countyFP: "021"},
    lincolncounty: {stateFP: "56", countyFP: "023"},
    natronacounty: {stateFP: "56", countyFP: "025"},
    niobraracounty: {stateFP: "56", countyFP: "027"},
    parkcounty: {stateFP: "56", countyFP: "029"},
    plattecounty: {stateFP: "56", countyFP: "031"},
    sheridancounty: {stateFP: "56", countyFP: "033"},
    sublettecounty: {stateFP: "56", countyFP: "035"},
    sweetwatercounty: {stateFP: "56", countyFP: "037"},
    tetoncounty: {stateFP: "56", countyFP: "039"},
    uintacounty: {stateFP: "56", countyFP: "041"},
    washakiecounty: {stateFP: "56", countyFP: "043"},
    westoncounty: {stateFP: "56", countyFP: "045"},
  },
};

// More info on google place autocomplete options and restrictions https://developers.google.com/maps/documentation/javascript/place-autocomplete#set-options-at-construction
export const GOOGLE_PLACES_API_RESTRICTIONS = {
  components: {country: "us"},
  fields: {
    address_components: "address_components",
    formatted_address: "formatted_address",
  },
};

export const USSTATESLIST = [
  {label: "AL", value: "Alabama"},
  {label: "AK", value: "Alaska"},
  {label: "AZ", value: "Arizona"},
  {label: "AR", value: "Arkansas"},
  {label: "CA", value: "California"},
  {label: "CO", value: "Colorado"},
  {label: "CT", value: "Connecticut"},
  {label: "DE", value: "Delaware"},
  {label: "DC", value: "District Of Columbia"},
  {label: "FL", value: "Florida"},
  {label: "GA", value: "Georgia"},
  {label: "HI", value: "Hawaii"},
  {label: "ID", value: "Idaho"},
  {label: "IL", value: "Illinois"},
  {label: "IN", value: "Indiana"},
  {label: "IA", value: "Iowa"},
  {label: "KS", value: "Kansas"},
  {label: "KY", value: "Kentucky"},
  {label: "LA", value: "Louisiana"},
  {label: "ME", value: "Maine"},
  {label: "MD", value: "Maryland"},
  {label: "MA", value: "Massachusetts"},
  {label: "MI", value: "Michigan"},
  {label: "MN", value: "Minnesota"},
  {label: "MS", value: "Mississippi"},
  {label: "MO", value: "Missouri"},
  {label: "MT", value: "Montana"},
  {label: "NE", value: "Nebraska"},
  {label: "NV", value: "Nevada"},
  {label: "NH", value: "New Hampshire"},
  {label: "NJ", value: "New Jersey"},
  {label: "NM", value: "New Mexico"},
  {label: "NY", value: "New York"},
  {label: "NC", value: "North Carolina"},
  {label: "ND", value: "North Dakota"},
  {label: "OH", value: "Ohio"},
  {label: "OK", value: "Oklahoma"},
  {label: "OR", value: "Oregon"},
  {label: "PA", value: "Pennsylvania"},
  {label: "RI", value: "Rhode Island"},
  {label: "SC", value: "South Carolina"},
  {label: "SD", value: "South Dakota"},
  {label: "TN", value: "Tennessee"},
  {label: "TX", value: "Texas"},
  {label: "UT", value: "Utah"},
  {label: "VT", value: "Vermont"},
  {label: "VA", value: "Virginia"},
  {label: "WA", value: "Washington"},
  {label: "WV", value: "West Virginia"},
  {label: "WI", value: "Wisconsin"},
  {label: "WY", value: "Wyoming"},
];
